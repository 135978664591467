<template>
  <div class="chip" :variant="variant">
    <play-icon v-if="variant == 'ongoing'" />
    <clock-icon
      v-if="
        variant == 'active' || variant == 'unread' || variant == 'successful'
      "
    />
    <cancel-icon v-if="variant == 'deleted' || variant === 'cancelled'" />
    <draft-icon v-if="variant == 'draft'" />
    <check-round-icon
      v-if="variant == 'read' || variant == 'attended' || variant == 'ended'"
    />
    <ban-icon-purple class="notattended-ban" v-if="variant == 'notattended'" />
    <div v-if="variant == 'ongoing'">Éppen zajlik</div>
    <div v-if="variant == 'active' && type !== 'complimentary-ticket'">Meghirdetett</div>
    <div v-if="variant == 'successful' || (variant == 'active' && type == 'complimentary-ticket')">Aktív</div>
    <div v-if="variant == 'deleted'">Törölt</div>
    <div v-if="variant == 'cancelled'">Visszamondott</div>
    <div v-if="variant == 'draft'">Piszkozat</div>
    <div v-if="variant == 'unread'">Olvasatlan</div>
    <div v-if="variant == 'read'">Olvasott</div>
    <div v-if="variant == 'attended'">Érvényesített</div>
    <div v-if="variant == 'notattended'">Nem jelent meg</div>
    <div v-if="variant == 'ended'">Lezárult</div>
  </div>
</template>

<script>
import {
  PlayIcon,
  ClockIcon,
  CancelIcon,
  DraftIcon,
  CheckRoundIcon,
  BanIconPurple,
} from "../assets/icons";
export default {
  props: ["variant", "type"],
  components: {
    PlayIcon,
    ClockIcon,
    BanIconPurple,
    CheckRoundIcon,
    CancelIcon,
    DraftIcon,
  },
};
</script>

<style scoped>
.chip {
  display: inline-flex;
  align-items: center;
  background-color: var(--color-white);
  padding: 4px 12px 4px 8px;
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 150%;
  border-radius: 16px;
  text-transform: uppercase;
  color: var(--color-blue-01);
}
.chip[variant="deleted"] {
  color: var(--color-grey);
}
.chip[variant="draft"],
.chip[variant="notattended"] {
  border: 1px solid #7b6ccc;
}
.chip[variant="ended"] {
  border: 1px solid var(--color-success);
}
.notattended-ban path {
  fill: #7b6ccc !important;
}
.chip svg {
  margin-right: 6px;
}
</style>