<template>
  <ion-page>
    <ion-content>
      <SidebarNavigation active="EventsPage">
        <div v-if="filterInUse || events?.length">
          <div class="background">
            <div class="header-container">
              <div variant="page-title">Események</div>
              <button @click="handleCreateNewEvent" icon-right>
                Új esemény <add-icon />
              </button>
            </div>
            <EventsFilter :handleChange="handleFilterChange" />
            <EventsTable
              :events="events"
              @delete="getEvents(currentPage, filterInUse)"
              @copy="
                (event) =>
                  copyEvent(event, config, (data) => {
                    return $router.push({
                      name: 'EventPage',
                      params: {
                        ...$router.params,
                        eventId: data.id,
                      },
                    });
                  })
              "
            />
            <!-- <p>{{ events }}</p> -->
          </div>
          <PageSelector
            v-if="events?.length"
            :currentPage="currentPage"
            :max="pageCount"
            :onClick="handleSelectPage"
          />
        </div>
        <no-events-content
          v-if="!filterInUse && !events?.length"
          :handleClick="handleCreateNewEvent"
        />
      </SidebarNavigation>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import SidebarNavigation from "@/components/SidebarNavigation";
import { useRoute, useRouter } from "vue-router";
import useEvents from "../../hooks/useEvents";
import EventsFilter from "./EventsFilter.vue";
import EventsTable from "./EventsTable.vue";
import PageSelector from "@/components/PageSelector.vue";
import { AddIcon } from "../../assets/icons";
import { ref } from "@vue/runtime-core";
import NoEventsContent from "./NoEventsContent.vue";
import useConfig from "../../hooks/useConfig";
import dayjs from 'dayjs';
export default {
  name: "EventsPage",
  components: {
    AddIcon,
    IonPage,
    IonContent,
    SidebarNavigation,
    EventsFilter,
    EventsTable,
    PageSelector,
    NoEventsContent,
  },
  setup() {
    const router = useRouter();

    const pageSize = 8;
    const { params } = useRoute();
    const { config } = useConfig();
    const {
      events,
      loading,
      pageCount,
      total,
      getEvents,
      copyEvent,
      page: currentPage,
    } = useEvents(params.wineryId, pageSize);

    // events.length can be zero is filtering -> would display the no events card
    // to prevent that filterInUse is introduced
    const filterInUse = ref(null);

    //watchEffect(() => console.log("events", events.value));

    const handleSelectPage = async (pageIdx) => {
      await getEvents(pageIdx, { ...filterInUse.value });
      currentPage.value = pageIdx;
    };

    const handleCreateNewEvent = () => {
      router.push({ name: "EventPage", params: { ...params, eventId: "new" } });
    };

    const handleFilterChange = async (filterData) => {
      const _filterData = {
        dateFrom: filterData.dateFrom || undefined,
        dateTo: filterData.dateTo ? dayjs(filterData.dateTo).add(1,'day').format('YYYY-MM-DD') :undefined,
        status: filterData.status == "all" ? undefined : filterData.status,
        search: filterData.search || undefined,
      };
      filterInUse.value = _filterData;
      getEvents(1, { ..._filterData });
    };

    return {
      currentPage,
      handleCreateNewEvent,
      handleFilterChange,
      handleSelectPage,
      events,
      getEvents,
      copyEvent,
      loading,
      total,
      pageCount,
      filterInUse,
      config,
    };
  },
};
</script>

<style scoped>
.header-container {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.background {
  background-color: var(--color-white);
}
</style>