<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.81563 5.22406C6.81563 5.63031 6.81562 6.02406 6.94375 6.39281C5.59688 7.97406 2.04063 12.1522 1.70938 12.4709C1.64354 12.53 1.59087 12.6023 1.5548 12.683C1.51873 12.7638 1.50006 12.8512 1.5 12.9397C1.5 13.2053 1.6625 13.4616 1.8 13.6053C2.00625 13.8209 2.8875 14.6366 3.05 14.4803C3.53125 14.0116 3.62812 13.8866 3.825 13.6928C4.12188 13.4022 3.79375 12.8084 3.89687 12.5678C4 12.3272 4.10938 12.2803 4.2875 12.2428C4.46562 12.2053 4.78125 12.3334 5.02812 12.3366C5.2875 12.3397 5.42812 12.2303 5.62187 12.0491C5.77812 11.9053 5.89062 11.7709 5.89375 11.5616C5.9 11.2803 5.49375 10.9084 5.79688 10.6116C6.1 10.3147 6.5375 10.8053 6.85938 10.7678C7.18125 10.7303 7.57188 10.2834 7.6125 10.0928C7.65313 9.90219 7.24687 9.41156 7.30937 9.13344C7.33125 9.03969 7.52187 8.82094 7.66563 8.78969C7.80938 8.75844 8.44687 9.00531 8.59062 8.97406C8.76562 8.93656 8.96875 8.75219 9.13437 8.64906C9.61875 8.85844 10.0594 8.94281 10.625 8.94281C12.7656 8.94281 14.5 7.27406 14.5 5.21781C14.5 3.16156 12.7656 1.5 10.625 1.5C8.48438 1.5 6.81563 3.16781 6.81563 5.22406ZM12.5 4.5C12.5 4.69778 12.4414 4.89112 12.3315 5.05557C12.2216 5.22002 12.0654 5.34819 11.8827 5.42388C11.7 5.49957 11.4989 5.51937 11.3049 5.48079C11.1109 5.4422 10.9327 5.34696 10.7929 5.20711C10.653 5.06725 10.5578 4.88907 10.5192 4.69509C10.4806 4.50111 10.5004 4.30004 10.5761 4.11732C10.6518 3.93459 10.78 3.77841 10.9444 3.66853C11.1089 3.55865 11.3022 3.5 11.5 3.5C11.7652 3.5 12.0196 3.60536 12.2071 3.79289C12.3946 3.98043 12.5 4.23478 12.5 4.5Z"
      fill="#12101A"
    />
  </svg>
</template>