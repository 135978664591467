<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>Esemény neve</th>
          <th>Esemény dátuma</th>
          <th>Foglalás ID</th>
          <th>Foglaló neve</th>
          <th>Foglalás időpontja</th>
          <th>Foglalt helyek<br/>száma</th>
          <th>Nettó alapár/fő</th>
          <th>Foglalás nettó<br/>összege</th>
        </tr>
      </thead>
      <tbody v-if="report?.attributes?.Bookings?.data">
        <tr v-for="booking in report?.attributes.Bookings.data" :key="booking.id">
          <td>{{ booking.attributes.Event?.data?.attributes.Name }}</td>
          <td>
            {{ formatDateAndTime(booking.attributes.Event?.data?.attributes.StartAt) }}
          </td>
          <td>{{ booking.attributes.NID }}</td>
          <td>{{ booking.attributes.WineAdventurer?.data?.attributes?.LastName }} {{ booking.attributes.WineAdventurer?.data?.attributes?.FirstName }}</td>
          <td>{{ booking.attributes.BookingDate ? formatDateAndTime(booking.attributes.BookingDate) : 'Nincs adat'}}</td>
          <td>{{ booking.attributes.Participants }}</td>
          <td v-if="!booking.attributes.DetailedPricing">{{ booking.attributes.BasePrice }} Ft</td>
          <td v-else class="tooltip-container">{{ booking.attributes.BasePriceProgram + 
                        booking.attributes.BasePriceFood +
                        booking.attributes.BasePriceAccommodation}} Ft
                        <span>
                          <button
                            :data-tooltip="`
                              Program: ${booking.attributes.BasePriceProgram} Ft,
                              Étel: ${booking.attributes.BasePriceFood} Ft,
                              Szállás: ${booking.attributes.BasePriceAccommodation} Ft
                            `"
                            data-tooltip-position="left"
                            variant="transparent"
                            icon
                          >
                            <!-- <info-icon /> -->
                            <info-icon-dark />
                          </button>
                        </span>
          </td>
          <td v-if="!booking.attributes.DetailedPricing">{{ booking.attributes.BaseTotal }} Ft</td>
          <td v-else>{{ booking.attributes.BasePriceProgramTotal + 
                        booking.attributes.BasePriceFoodTotal +
                        booking.attributes.BasePriceAccommodationTotal}} Ft
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import InfoIconDark from '../../assets/icons/InfoIconDark.vue';
import useFormatDate from "../../hooks/useFormatDate";
export default {
  components: { InfoIconDark },
  props: ["report"],
  setup() {
    const { formatDateAndTime } = useFormatDate();

    return {
      formatDateAndTime,
    };
  },
};
</script>

<style>
  .tooltip-container {
    display: flex;
    align-items: center;
  }
</style>