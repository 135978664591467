import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import { IonicVue } from "@ionic/vue";

// TOAST
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
// import "@ionic/vue/css/padding.css";
// import "@ionic/vue/css/float-elements.css";
// import "@ionic/vue/css/text-alignment.css";
// import "@ionic/vue/css/text-transformation.css";
// import "@ionic/vue/css/flex-utils.css";
// import "@ionic/vue/css/display.css";

/* Theme variables */
// import './theme/variables.css';
import "./theme/global.css";
import "./theme/fonts.css";
import "./theme/colors.css";
import "./theme/typography.css";
import "./theme/buttons.css";
import "./theme/inputs.css";
import "./theme/checkbox.css";
import "./theme/forms.css";
import "./theme/tables.css";
// TOOLTIP
import "./theme/tooltip.css";
import store from './store';

const app = createApp(App).use(IonicVue).use(router).use(Toast).use(store);

router.isReady().then(() => {
  app.mount("#app");
});
