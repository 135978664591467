import { onIonViewDidEnter } from "@ionic/vue";
import { ref } from "vue";
import complimentaryTicketApi from "../api/complimentary-ticket";

function useComplimentaryTickets(eventId, pageSize) {
  const loading = ref(true);
  const complimentaryTickets = ref(null);
  const pageCount = ref(null);
  const total = ref(null);
  const page = ref(null);

  const getComplimentaryTickets = async (pageIdx) => {
    console.log("get complimentary tickets")
    const res = await complimentaryTicketApi.get(eventId, pageSize, pageIdx);
    if (res.ok) {
      complimentaryTickets.value = res.data.data;
      pageCount.value = res.data.meta.pagination.pageCount;
      total.value = res.data.meta.pagination.total;
      page.value = res.data.meta.pagination.page;
      loading.value = false;
    } else {
      console.log(res.data);
      loading.value = false;
    }
  };

  onIonViewDidEnter(() => getComplimentaryTickets(1, {}));

  return {
    loading,
    complimentaryTickets,
    getComplimentaryTickets,
    pageCount,
    total,
    page,
  };
}

export default useComplimentaryTickets;
