import client, { requestConfig } from "./client";

const endpoint = "/api/bookings";

const get = (wineryId, pageSize, page, { dateFrom, dateTo, status, search }) =>
  client.get(
    endpoint,
    {
      "populate[0]":"Event.Cover",
      "populate[1]":"Winery",
      "populate[2]":"BillingAddress",
      "populate[3]":"Checkin",
      "populate[4]":"Package",
      "populate[5]":"PackageExtras",
      "filters[Winery][id]": wineryId,
      "pagination[pageSize]": pageSize,
      "pagination[page]": page,
      ...( status ? {"filters[Status]": status} : {"filters[Status][$in]": ['successful', 'attended', 'notattended']}),
      "filters[$or][0][NID][$contains]": search,
      "filters[$or][1][Event][Name][$containsi]": search,
      "filters[BookingDate][$gt]": dateFrom,
      "filters[BookingDate][$lt]": dateTo,
      "sort[BookingDate]": "desc",
      as: 'wineryadmin'
    },
    requestConfig()
  );

const update = (data, bookingId) =>
  client.put(endpoint + "/" + bookingId, data, requestConfig());

const getById = (bookingId) =>
  client.get(`${endpoint}/${bookingId}`, { 
    "populate[0]":"Event.Cover",
    "populate[1]":"Winery",
    "populate[2]":"BillingAddress",
    "populate[3]":"Checkin",
    "populate[4]":"Package",
    "populate[5]":"PackageExtras",
    "populate[6]":"Package.WineList",
    "populate[7]":"Package.Extras",
  }, requestConfig());

export default {
  get,
  update,
  getById,
};
