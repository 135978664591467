<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.77783 11.4485V14.2218H4.55116L12.4445 6.3285L9.67116 3.55517L1.77783 11.4485ZM14.8978 3.88405C15.1912 3.59072 15.1912 3.1285 14.8978 2.83517L13.1645 1.10184C12.8712 0.808503 12.4089 0.808503 12.1156 1.10184L10.6667 2.55961L13.44 5.33294L14.8978 3.88405Z"
      fill="white"
    />
  </svg>
</template>