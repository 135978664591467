import { onIonViewDidEnter } from "@ionic/vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import eventApi from "../api/event";
import useError from "./useError";

function useEvent(eventId, populate = undefined) {
  const loading = ref(eventId !== "new");
  const event = ref(null);
  const error = ref(null);
  const router = useRouter();

  const update = async (data) => {
    const res = await eventApi.update(data, eventId);
    if (res.ok) {
      // event.value = res.data.data;
    } else {
      error.value = useError(res, "Hiba történt az esemény frissítésekor!");
    }
    return res;
  };

  const copy = async (event, config, cb) => {
    loading.value = true;
    const res = await eventApi.copy(
      event,
      event.attributes.Winery.data.id,
      config
    );
    if (res.ok) {
      loading.value = false;
      cb && cb(res.data.data);
    } else {
      useError(res, "Hiba történt az esemény másolása során!");
      loading.value = false;
    }
  };

  const getEvent = async () => {
    const res = await eventApi.getById(eventId, populate);
    if (res.ok) {
      event.value = res.data.data;
      loading.value = false;
    } else {
      error.value = useError(res, "Hiba történt az esemény lekérdezésekor!");
      loading.value = false;
      router.push({ name: "EventsPage" });
    }
  };

  onIonViewDidEnter(() => eventId && eventId !== "new" && getEvent());

  return {
    loading,
    event,
    update,
    copy,
    getEvent,
    error,
  };
}

export default useEvent;
