import { ref } from "vue";
import dayjs from "dayjs";

function multiIndex(obj, is) {
  return is.length ? multiIndex(obj[is[0]], is.slice(1)) : obj;
}
function pathIndex(obj, is) {
  return multiIndex(obj, is.split("."));
}

// VALIDATORS
const required = {
  validate: ({ field, data }) => pathIndex(data, field) === undefined || pathIndex(data, field) === null || pathIndex(data, field) === '',
  defaultError: () => "Ez a mező kötelező!",
};
const minlength = {
  validate: ({ field, data, params }) =>
    pathIndex(data, field).length < params.min,
  defaultError: ({ params }) =>
    `Ennek a mezőnek minimum ${params.min} karakternek kell lenni!`,
};
const equal = {
  validate: ({ field, data, params }) =>
    pathIndex(data, field) !== pathIndex(data, params.field),
  defaultError: ({ params }) =>
    `Nem egyezik meg a(z) ${params.fieldLabel} mezővel!`,
};
const notempty = {
  validate: ({ field, data }) => pathIndex(data, field).length == 0,
  defaultError: () => `Üres lista!`,
};

const date = {
  validate: ({ field, data, params }) => {
    if (params.min && !params.max) {
      return dayjs(params.min) > dayjs(pathIndex(data, field))
    } else if (params.max && !params.mix) {
      return dayjs(params.max) < dayjs(pathIndex(data, field))
    } else if (params.min && params.max) {
      return dayjs(params.min) > dayjs(pathIndex(data, field)) || dayjs(params.max) < dayjs(pathIndex(data, field))
    }
    return true
  },
  defaultError: ({ params }) => {
    if (params.customMessage) {
      return params.customMessage(params)
    }
    if (params.min && !params.max) {
      return `A dátum legyen legalább ${dayjs(params.min).format('YYYY-MM-DD')}`
    } else if (params.max && !params.mix) {
      return `A dátum legyen legfeljebb ${dayjs(params.max).format('YYYY-MM-DD')}`
    } else if (params.min && params.max) {
      return `A dátumnak az alábbiak között kell lennie:  ${dayjs(params.min).format('YYYY-MM-DD')} - ${dayjs(params.max).format('YYYY-MM-DD')}`
    }
  }
}

const number = {
  validate: ({ field, data, params }) => {
    let invalid = false;
    if ("min" in params) {
      if (typeof params.min === 'string') {
        invalid = parseInt(pathIndex(data, field)) < parseInt(pathIndex(data, params.min))
      } else {
        invalid = parseInt(pathIndex(data, field)) < params.min
      }
    }
    if ("max" in params) {
      if (typeof params.max === 'string') {
        invalid = parseInt(pathIndex(data, field)) > parseInt(pathIndex(data, params.max)) || invalid
      } else {
        invalid = parseInt(pathIndex(data, field)) > params.max || invalid
      }
    }
    return invalid;
  },
  defaultError: ({ params, data }) => {
    if ("min" in params && !("max" in params)) {
      if (typeof params.min === 'string') {
        return `Az értéknek minimum ennyinek kell lennie: ${pathIndex(data, params.min)}`
      } else {
        return `Az értéknek minimum ennyinek kell lennie: ${params.min}`
      }
    } else if ("max" in params && !("min" in params)) {
      if (typeof params.max === 'string') {
        return `Az érték maximum ennyi lehet: ${pathIndex(data, params.max)}`
      } else {
        return `Az érték maximum ennyi lehet: ${params.max}`
      }
    } else if ("min" in params && "max" in params) {
      let message = `A beírt értéknek az alábbi két érték között kell lennie:`
      if (typeof params.min === 'string') {
        message += ` ${pathIndex(data, params.min)} és `
      } else {
        message += ` ${params.min} és `
      }

      if (typeof params.max === 'string') {
        message += ` ${pathIndex(data, params.max)}`
      } else {
        message += ` ${params.max}`
      }
      return message
    }
  }
}

const validators = {
  required,
  minlength,
  equal,
  notempty,
  date,
  number
};

// const rules = {
//   'name.value': ["required"],
// };

function useValidation(rules) {
  const errors = ref({});

  const validate = (data, { onAccept, onDecline, only = rules } = {}) => {
    var _errors = errors.value;
    const rulesToCheck = Object.keys(only)
    Object.keys(rules).filter((key) => rulesToCheck.includes(key)).forEach((key) => {
      var keyErrors = [];
      rules[key].forEach((rule) => {
        const validator = validators[rule.validator];
        const params = rule.params;
        if (validator.validate({ field: key, data, params })) {
          keyErrors.push(rule.error || validator.defaultError({ params, data }));
        }
      });
      if (keyErrors.length > 0) {
        _errors[key] = keyErrors;
      } else {
        delete _errors[key];
      }
    });
    errors.value = _errors;

    const canMoveOn = !Object.keys(_errors).length > 0;
    if (canMoveOn) {
      onAccept && onAccept();
    } else {
      onDecline && onDecline();
    }
  };

  return {
    validate,
    errors,
  };
}

export default useValidation;
export { validators, pathIndex };
