<template>
  <div class="container">
    <loading-screen v-if="loading"/>
    <!-- TERMS AND PRIVACY -->
    <div class="card" v-if="!showNeedToAccept">
      <div v-if="route.query?.firstuse" variant="page-title" style="margin-bottom: 16px">
        ÁSZF és AT
      </div>
      <div v-else variant="page-title" style="margin-bottom: 16px">
        Módosult az ÁSZF és AT-nk
      </div>
      <form @submit.prevent="handleSubmit">
        <div v-if="route.query?.firstuse" class="info" variant="small-text" style="margin-bottom: 32px">
          <info-icon />
          Itt található a partnerekre vonatkozó Általános Szerződési Feltételek és az Adatvédelmi
          Tájékoztató! A továbblépéshez kérjük olvasd el!
        </div>
        <div v-else class="info" variant="small-text" style="margin-bottom: 32px">
          <info-icon />
          Változtattunk az Általános Szerződési Feltételeken és az Adatvédelmi
          Tájékoztatón! A továbblépéshez kérjük olvasd el!
        </div>
        <terms-privacy-item
          title="Általános Szerződési Feltételek"
          :subtitle="`Verzió: ${config?.attributes?.WineryAdminTermsVersion}`"
          link="https://orszagosbortura.hu/obt-aszf-partner.pdf"
          style="margin-bottom: 8px"
        />
        <terms-privacy-item
          title="Adatvédelmi Tájékoztató"
          :subtitle="`Verzió: ${config?.attributes?.WineryAdminPrivacyPolicyVersion}`"
          link="https://orszagosbortura.hu/obt-adatvedelmi-tajekoztato-altalanos.pdf"
          style="margin-bottom: 32px"
        />
        <Checkbox
          v-model="formData.terms"
          label="Elolvastam és elfogadom az Adatvédelmi Tájékoztatót!"
        />
        <Checkbox
          v-model="formData.privacy"
          label="Elolvastam és elfogadom az Általános Szerződési Feltételeket!"
        />
        <div class="buttons">
          <button
            type="button"
            style="width: 120px; margin-right: 8px"
            variant="white"
            @click="handleClose()"
          >
            Bezárás
          </button>
          <button
            :disabled="!formData.terms || !formData.privacy"
            type="submit"
            style="width: 120px"
          >
            Mentés
          </button>
        </div>
      </form>
    </div>

    <!-- NEED TO ACCEPT -->
    <div class="card" v-if="showNeedToAccept">
      <div variant="page-title">
        Sajnáljuk, de a boradmin használatához az ÁSZF, AT elolvasása és az
        abban foglaltak elfogadása szükséges!
      </div>
      <form @submit.prevent="">
        <div
          class="info"
          variant="small-text"
          style="margin-top: 16px; margin-bottom: 32px"
        >
          <info-icon />
          Amennyiben mégis elfogadnád kérjük lépj vissza.
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <button @click="handleBack" style="width: 120px" icon-left>
            <arrow-back-icon /> Vissza
          </button>
          <button @click="handleInterrupt" variant="white">Folyamat megszakítása</button>
        </div>
        <div class="card-bottom">
          <div variant="navigation-bold" style="margin-right: 8px">
            Kérdésed van? Írj nekünk!
          </div>
          <a href="mailto:hello@orszagosbortura.hu">hello@orszagosbortura.hu</a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { InfoIcon, ArrowBackIcon } from "../../assets/icons";
import TermsPrivacyItem from "./TermsPrivacyItem.vue";
import Checkbox from "@/components/CheckboxComponent.vue";
import { ref } from "@vue/reactivity";
import useConfig from '../../hooks/useConfig';
import useAuth from '../../hooks/useAuth';
import { useRoute, useRouter } from 'vue-router';
import useMe from '../../hooks/useMe';
import { useToast } from 'vue-toastification';
import LoadingScreen from '../../components/LoadingScreen.vue';
export default {
  components: { InfoIcon, TermsPrivacyItem, Checkbox, ArrowBackIcon, LoadingScreen },
  setup() {
    const {config} = useConfig();
    const {logout} = useAuth();
    const router = useRouter();
    const route = useRoute();
    const { me, updateMe } = useMe();
    const toast = useToast();

    const loading = ref(false)

    const formData = ref({
      terms: false,
      privacy: false,
    });
    const showNeedToAccept = ref(false);

    const handleSubmit = async() => {
      loading.value = true;
      const resp = await updateMe({
        AcceptWineryAdminTermsAndPrivacy: true
      })
      loading.value = false;
      if(resp.ok){
        if(route.query?.next){
          window.location = route.query.next;
        } else {
          router.push({
            name: 'WinerySelectorPage',
            params: {
              userId: me.value.id
            }
          });
        }
        
      } else {
        toast.error('Valami hiba történt, próbáld meg újra')
      }
      
    };

    const handleClose = () => {
      if (formData.value.terms && formData.value.privacy) {
        console.log("close action");
      } else {
        showNeedToAccept.value = true;
      }
    };

    const handleBack = () => {
      showNeedToAccept.value = false;
    };

    const handleInterrupt = () => {
      logout(()=>{
        router.push({
          name: 'LoginPage'
        })
      })
    }

    return {
      formData,
      handleSubmit,
      handleClose,
      handleBack,
      handleInterrupt,
      showNeedToAccept,
      config,
      loading,
      route
    };
  },
};
</script>

<style scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: var(--color-blue-03);
}
.card {
  max-width: 800px;
  width: 100%;
  background-color: var(--color-white);
  padding: 48px 64px;
  border-radius: 8px;
  box-shadow: 0px 40px 80px rgba(96, 88, 143, 0.24),
    0px 16px 32px rgba(96, 88, 143, 0.16), 0px 8px 16px rgba(96, 88, 143, 0.16),
    0px 4px 4px rgba(96, 88, 143, 0.02);
  margin: auto;
}
.card-bottom {
  background-color: var(--color-red-06);
  width: calc(100% + 2 * 64px);
  margin-left: -64px;
  padding: 33px 64px;
  margin-bottom: -48px;
  border-radius: 0px 0px 8px 8px;
  margin-top: 64px;
  display: flex;
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>