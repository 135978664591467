import { onIonViewDidEnter } from "@ionic/vue";
import { ref } from "vue";
import complimentaryTicketApi from "../api/complimentary-ticket";

function useComplimentaryTicket(complimentaryTicketId) {
  const loading = ref(true);
  const complimentaryTicket = ref(null);

  const update = async (data) => {
    const res = await complimentaryTicketApi.update(data, complimentaryTicketId);
    if (res.ok) {
      console.log("ok");
      console.log(res.data.data);
    } else {
      console.log(res.data);
    }
    return res;
  };

  const getComplimentaryTicket = async () => {
    const res = await complimentaryTicketApi.getById(complimentaryTicketId);
    if (res.ok) {
      complimentaryTicket.value = res.data.data;
      loading.value = false;
    } else {
      console.log(res.data);
      loading.value = false;
    }
  };

  onIonViewDidEnter(() => complimentaryTicketId && getComplimentaryTicket());

  return {
    loading,
    complimentaryTicket,
    update,
    getComplimentaryTicket,
  };
}

export default useComplimentaryTicket;
