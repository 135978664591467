<template>
  <div class="container">
    <div class="block">
      <Chip variant="ongoing">Éppen zajlik</Chip>
      <Chip variant="active">Meghirdetett</Chip>
      <Chip variant="cancel">Törölt</Chip>
      <Chip variant="draft">Piszkozat</Chip>
    </div>
    <div class="block">
      <!-- basic button -->
      <button>Red</button>
      <button variant="white">White</button>
      <button disabled>Disabled</button>
      <!-- button with icon -->
      <button icon-right>Red with icon <AddIcon /></button>
      <button icon-right variant="white">White with icon <AddIcon /></button>
      <button icon-right disabled>Disabled with icon <AddIcon /></button>

      <button icon-left><AddIcon />Red with icon</button>
      <button icon-left variant="white"><AddIcon />White with icon</button>
      <button icon-left disabled><AddIcon />Disabled with icon</button>
      <!-- icon -->
      <button icon><AddIcon /></button>
      <button icon variant="white"><AddIcon /></button>
      <button icon disabled><AddIcon /></button>
    </div>

    <div class="block">
      <Input type="text" label="Label" v-model="formData.test" />
      {{ formData.test }}
      <Input label="Nice" :optional="true">
        <template v-slot:left><add-icon /></template>
      </Input>
      <Input label="Keresés" :withCancel="true" v-model="formData.search" />
      {{ formData.search }}
      <Input label="Nice" type="date" :optional="true"></Input>
      <Input label="Összeg" type="number">
        <template v-slot:right>FORINT</template>
      </Input>
    </div>

    <div class="block">
      <Checkbox v-model="formData.check" label="Checkbox label" />
    </div>
  </div>
</template>

<script>
import Chip from "../components/ChipComponent.vue";
import { AddIcon } from "../assets/icons";
import Input from "../components/InputComponent.vue";
import Checkbox from "../components/CheckboxComponent.vue";
import { ref } from "@vue/reactivity";
export default {
  components: { Chip, AddIcon, Input, Checkbox },
  setup() {
    const formData = ref({ test: "", search: "", check: false });
    return {
      formData,
    };
  },
};
</script>

<style scoped>
.container {
  background: var(--color-red-06);
  width: 100%;
  height: 100%;
}
.block {
  padding: 10px;
}
</style>