<template>
  <ion-page>
    <ion-content>
      <div class="login-background">
        <loading-screen v-if="loadingLogin" />
        <LoginHeader />
        <NPForm :email="email" :register="true" :handleSubmit="handleSubmit"/>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import { useRoute, useRouter } from "vue-router";
import NPForm from "../new-password/NPForm.vue";
import LoginHeader from "../../components/LoginHeader.vue";
import LoadingScreen from "../../components/LoadingScreen.vue";
import useAuth from "../../hooks/useAuth";
import { useToast } from "vue-toastification";

export default {
  name: "RegisterPage",
  components: {
    IonPage,
    IonContent,
    LoginHeader,
    NPForm,
    LoadingScreen
  },
  setup() {
    const { params, query } = useRoute();
    const router = useRouter();
    const code = params.code;
    const email = query.email;
    const { login,resetPassword, loading: loadingLogin } = useAuth();
    const toast = useToast();

    const handleSubmit = async (e, formData) => {
      // TODO
      const res = await resetPassword({
        code,
        password: formData.password,
        passwordConfirmation: formData.passwordAgain,
        AcceptWineryAdminTermsAndPrivacy: true,
      });
      if (res.ok) {
        await login(
          { identifier: email, password: formData.password },
          (res) => {
            router.push({
              name: "WinerySelectorPage",
              params: { userId: res.data.user.id },
            });
          }
        );
      } else {
        toast.error('A link már nem érvényes')
      }
    };

    return {
      handleSubmit,
      email,
      loadingLogin
    };
  },
};
</script>

<style>
</style>