<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.70715 8.14645L8.35349 8.50012L8.70726 8.85367L11.6778 11.8223C11.6778 11.8223 11.6778 11.8224 11.6778 11.8224C11.6779 11.8224 11.6779 11.8224 11.6779 11.8224C11.7249 11.8695 11.7514 11.9334 11.7514 12C11.7514 12.0667 11.7249 12.1306 11.6778 12.1777C11.6306 12.2248 11.5667 12.2513 11.5001 12.2513C11.4334 12.2513 11.3695 12.2248 11.3224 12.1777L8.35363 9.20895L8.00008 8.8554L7.64653 9.20895L4.67778 12.1777C4.63065 12.2248 4.56673 12.2513 4.50008 12.2513C4.43343 12.2513 4.36951 12.2248 4.32238 12.1777C4.27526 12.1306 4.24878 12.0667 4.24878 12C4.24878 11.9334 4.27526 11.8694 4.32238 11.8223L7.29113 8.85356L7.64469 8.5L7.29113 8.14645L4.32238 5.1777C4.27526 5.13057 4.24878 5.06665 4.24878 5C4.24878 4.93336 4.27526 4.86944 4.32238 4.82231C4.36951 4.77518 4.43343 4.7487 4.50008 4.7487C4.56673 4.7487 4.63065 4.77518 4.67778 4.82231L7.64653 7.79106L8.00008 8.14461L8.35363 7.79106L11.3221 4.82262C11.3221 4.82259 11.3221 4.82257 11.3222 4.82254C11.3692 4.77563 11.4329 4.74931 11.4994 4.74937C11.5658 4.74942 11.6296 4.77589 11.6765 4.82293C11.7235 4.86998 11.7498 4.93375 11.7498 5.00023C11.7497 5.0667 11.7233 5.13043 11.6762 5.17739L11.6759 5.1777L8.70715 8.14645Z"
      fill="white"
      stroke="white"
    />
  </svg>
</template>