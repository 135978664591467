<template>
  <div class="details-container">
    <div class="column-1">
      <div class="details-item">
        <h3 variant="table-head-button-label">Esemény ID</h3>
        <p variant="list-item" style="display: flex; align-items: center;" v-if="['active','ongoing'].includes(event.attributes.Status)">
          <a :href="`https://www.orszagosbortura.hu/esemenyek/${event.attributes.NID}`" target="_blank">{{ event.attributes.NID }}</a>
          <copy-button :tooltipText="'jegyvásárlási link másolása'" :text="`https://www.orszagosbortura.hu/esemenyek/${event.attributes.NID}`"></copy-button>
        </p>
        <p variant="list-item" style="display: flex; align-items: center;" v-else>
          {{ event.attributes.NID }}
        </p>
      </div>

      <div class="details-item" v-if="event.attributes.ActivatedAt">
        <h3 variant="table-head-button-label">Esemény meghirdetésének időpontja</h3>
        <p variant="list-item">{{ formatDate(event.attributes.ActivatedAt) }}</p>
      </div>

      <div class="details-item">
        <h3 variant="table-head-button-label">Időpont</h3>
        <p variant="list-item">
          Kezdés időpontja: {{ formatDateAndTime(event.attributes.StartAt) }}
        </p>
        <p variant="list-item">
          Befejezés időpontja: {{ formatDateAndTime(event.attributes.EndAt) }}
        </p>
      </div>

      <div class="details-item">
        <h3 variant="table-head-button-label">Helyszín</h3>
        <p variant="list-item">
          {{ event.attributes.LocationName }}
          <span style="color: var(--color-grey-03); margin-left: 8px">{{
            event.attributes.LocationAddress
          }}</span>
        </p>
      </div>

      <div class="details-item">
        <h3 variant="table-head-button-label">Árak</h3>
        <div v-if="!event.attributes.DetailedPricing">
          <p variant="list-item">
            Nettó alapár/fő: {{ event.attributes.NetPrice }} Ft + {{ config.attributes.VATProgram}}% ÁFA
          </p>
          <p variant="list-item">
            Meghirdetett ár/fő: {{ event.attributes.DisplayedPrice }} Ft
          </p>
        </div>
        <div v-else>
          <div :key="type.value" v-for="type in [
            {value: 'NetPriceProgram',label: 'Program', vat: config.attributes.VATProgram},
            {value: 'NetPriceFood',label: 'Ételfogyasztás', vat: config.attributes.VATFood},
            {value: 'NetPriceAccommodation',label: 'Szálláshely', vat: config.attributes.VATAccommodation}]">
            <div v-if="event.attributes[type.value]">
              <p variant="list-item" style="font-style: italic">{{type.label}}</p>
              <p style="margin-left: 16px" variant="list-item">
                Nettó alapár/fő: {{ event.attributes[type.value] }} Ft + {{ type.vat}}% ÁFA
              </p>
            </div>
          </div>
          <hr />
          <p variant="list-item">
            Meghirdetett ár/fő: {{ event.attributes.DisplayedPrice }} Ft
          </p>
        </div>
      </div>

      <div class="details-item" v-if="isPackageEvent()">
        <h3 variant="table-head-button-label">Elérhető extrák</h3>
        <table>
          <tr v-for="extra in event.attributes.Package.data.attributes.Extras" :key="extra.id">
            <td variant="list-item">{{ extra.Name }}</td>
            <td variant="list-item" style="text-align: right;">{{ extra.PriceNet }} Ft{{ extra.Type === 'per-participant' ? '/fő' : ''}} + {{getVAT(extra.PriceType)}}% ÁFA</td>
          </tr>
        </table>
      </div>

      <div class="details-item" v-if="!isPackageEvent()">
        <h3 variant="table-head-button-label">Létszám adatok</h3>
        <p variant="list-item">Eladott jegyek száma: {{event.attributes.Booked || 0}} db</p>
        <form @submit.prevent="onSubmit()">
          <Input
            :disabled="event.attributes.Status !== 'active'"
            label="Minimum létszám"
            v-model="formData.headcount.min"
            :error="validationErrors && validationErrors['headcount.min']"
          />
          <Input
            :disabled="event.attributes.Status !== 'active'"
            label="Férőhelyek száma"
            v-model="formData.headcount.max"
            :error="validationErrors && validationErrors['headcount.max']"
          />

          <button
            v-if="event.attributes.Status == 'active'"
            type="submit"
            icon-right
          >
            Módosítások mentése <check-icon />
          </button>
        </form>
      </div>
    </div>

    <div class="column-2">
      <div class="details-item" v-if="!isPackageEvent()">
        <h3 variant="table-head-button-label">Az esemény tartalmazza</h3>
        <div v-for="tag in event.attributes.Tags.data" :key="tag">
          <div variant="list-item" class="chip">{{ tag.attributes.Name }}</div>
        </div>
      </div>
      <div class="details-item" v-if="!isPackageEvent()">
        <h3 variant="table-head-button-label">Elérhető extrák</h3>
        <div v-for="extra in event.attributes.Extras.data" :key="extra">
          <div variant="list-item" class="chip">
            {{ extra.attributes.Name }}
          </div>
        </div>
      </div>
      <div class="details-item" v-if="!isPackageEvent()">
        <h3 variant="table-head-button-label">A program borai</h3>
        <div variant="list-item" v-html="event.attributes.Wines"></div>
        <p></p>
      </div>

      <div class="details-item" v-if="!isPackageEvent()">
        <h3 variant="table-head-button-label">Közösségi média esemény link</h3>
        <a target="_blank" variant="list-item" :href="event.attributes.Link">{{
          event.attributes.Link
        }}</a>
      </div>
      <div class="details-item" v-if="isPackageEvent()">
        <h3 variant="table-head-button-label">A kóstoló borai</h3>
        <div variant="list-item" style="margin-left: 8px;" v-for="(wine, idx) in event.attributes.Package.data.attributes.WineList" :key="idx">
          {{ wine.value }}
        </div>
      </div>
      <div class="details-item" v-if="isPackageEvent()">
        <h3 variant="table-head-button-label">Program indulási pontja</h3>
        <p variant="list-item">
          {{ event?.attributes.Package.data.attributes.StartLocation }}
        </p>
      </div>
      <div class="details-item" v-if="isPackageEvent()">
        <h3 variant="table-head-button-label">Program végpontja</h3>
        <p variant="list-item">
          {{ event?.attributes.Package.data.attributes.EndLocation }}
        </p>
      </div>
      <div class="details-item" v-if="isPackageEvent()">
        <h3 variant="table-head-button-label">Program nyelve</h3>
        <p variant="list-item">
          {{ language(event?.attributes.Package.data.attributes.Language) }}
        </p>
      </div>
      <div class="details-item" v-if="isPackageEvent()">
        <h3 variant="table-head-button-label" style="margin-bottom: 16px;color: var(--color-red-02)">Összesített adatok</h3>
        <h3 variant="table-head-button-label">Résztvevők összesen</h3>
        <p variant="list-item" style="margin-bottom: 16px;">{{event.attributes.Booked || 0}} db</p>

        <h3 variant="table-head-button-label">Extrák összesen</h3>
        <table>
          <tr v-for="extra in extraSummary()" :key="extra.id">
            <td variant="list-item">{{ extra.Name }}</td>
            <td variant="list-item" style="text-align: right;font-weight: bold;">{{ extra.quantity }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="column-3">
      <div class="details-item">
        <h3 variant="table-head-button-label">Borító kép</h3>
        <img
          :src="event?.attributes.Cover?.data?.attributes?.formats?.small?.url"
        />
      </div>
      <div class="details-item">
        <h3 variant="table-head-button-label">Leírás</h3>
        <div variant="list-item" v-html="event.attributes.Description"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Input from "../../components/InputComponent.vue";
import useFormatDate from "../../hooks/useFormatDate";
import { CheckIcon } from "@/assets/icons";
import useValidation from '../../hooks/useValidation';
import usePackageLanguage from '../../hooks/usePackageLanguage';
import CopyButton from '../../components/CopyButton.vue';
export default {
  props: ["event", "handleSubmit", "config"],
  components: {
    CheckIcon,
    Input,
    CopyButton
  },
  setup(props) {
    const { formatDateAndTime, formatDate  } = useFormatDate();
    const {language} = usePackageLanguage()

    const rules = {
      "headcount.min": [
        { validator: "required" },
        { validator: "number", params:{min:0}},
      ],
      "headcount.max": [
        { validator: "required" },
        { validator: "number", params:{min:1}},
        { validator: "number", params:{min:"headcount.min"} }
      ]
    }

    const {validate, errors: validationErrors} = useValidation(rules)

    const onSubmit = ()=>{
      console.log(formData.value)
      validate(formData.value, {
        onAccept: ()=>{props.handleSubmit(formData.value)}
      })
    }

    const formData = ref({
      headcount: {
        min: props.event.attributes.MinHeadCount,
        max: props.event.attributes.MaxHeadCount,
      },
    });
    return {
      formData,
      formatDateAndTime,
      formatDate,
      validationErrors,
      onSubmit,
      language
    };
  },
  methods: {
    isPackageEvent() {
      return !!this.event.attributes.Package?.data;
    },
    getVAT(type){
      switch(type){
        case 'program':
          return this.config.attributes.VATProgram
        case 'food':
          return this.config.attributes.VATFood
        case 'accommodation':
          return this.config.attributes.VATAccommodation
      }
    },
    extraSummary(){
      console.log(this.event?.attributes.Bookings.data)
      const extras  = this.event?.attributes.Bookings?.data.flatMap(booking=>booking.attributes.PackageExtras.data)
      const extraMap = {}
      extras.forEach(extra=>{
        if(extraMap[extra.Name]){
          extraMap[extra.Name].quantity += extra.attributes.Quantity
        } else {
          extraMap[extra.Name] = {
            ...extra.attributes,
            quantity: extra.attributes.Quantity
          }
        }
      })
      return Object.values(extraMap)
    }
  }
};
</script>

<style scoped>
.details-container {
  display: grid;
  margin: 8px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 0px;
}
.details-container > div {
  background-color: var(--color-white);
  padding: 32px 24px;
}
.details-item {
  margin-bottom: 32px;
}
/* .details-item h3,
.detials-item p {
  margin: 0px;
} */
h3,
p {
  margin: 0px;
  margin-bottom: 8px;
}
.chip {
  background: var(--color-grey-05);
  padding: 8px 16px;
  border-radius: 100px;
  margin: 2px 0px;
  display: inline-block;
}
</style>