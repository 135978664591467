<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 2.375C3.72183 2.375 1.875 4.22183 1.875 6.5C1.875 8.77817 3.72183 10.625 6 10.625C8.27817 10.625 10.125 8.77817 10.125 6.5C10.125 4.22183 8.27817 2.375 6 2.375ZM0.75 6.5C0.75 3.60051 3.10051 1.25 6 1.25C8.8995 1.25 11.25 3.60051 11.25 6.5C11.25 9.3995 8.8995 11.75 6 11.75C3.10051 11.75 0.75 9.3995 0.75 6.5Z"
      fill="#7B6CCC"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.91715 10.2126L2.28809 3.58358L3.08358 2.78809L9.71264 9.41715L8.91715 10.2126Z"
      fill="#7B6CCC"
    />
  </svg>
</template>