<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 2.5H3C2.17157 2.5 1.5 3.17157 1.5 4V13C1.5 13.8284 2.17157 14.5 3 14.5H13C13.8284 14.5 14.5 13.8284 14.5 13V4C14.5 3.17157 13.8284 2.5 13 2.5Z"
      stroke="#FFF6F2"
      stroke-linejoin="round"
    />
    <path
      d="M9.25 8C9.66421 8 10 7.66421 10 7.25C10 6.83579 9.66421 6.5 9.25 6.5C8.83579 6.5 8.5 6.83579 8.5 7.25C8.5 7.66421 8.83579 8 9.25 8Z"
      fill="#FFF6F2"
    />
    <path
      d="M11.75 8C12.1642 8 12.5 7.66421 12.5 7.25C12.5 6.83579 12.1642 6.5 11.75 6.5C11.3358 6.5 11 6.83579 11 7.25C11 7.66421 11.3358 8 11.75 8Z"
      fill="#FFF6F2"
    />
    <path
      d="M9.25 10.5C9.66421 10.5 10 10.1642 10 9.75C10 9.33579 9.66421 9 9.25 9C8.83579 9 8.5 9.33579 8.5 9.75C8.5 10.1642 8.83579 10.5 9.25 10.5Z"
      fill="#FFF6F2"
    />
    <path
      d="M11.75 10.5C12.1642 10.5 12.5 10.1642 12.5 9.75C12.5 9.33579 12.1642 9 11.75 9C11.3358 9 11 9.33579 11 9.75C11 10.1642 11.3358 10.5 11.75 10.5Z"
      fill="#FFF6F2"
    />
    <path
      d="M4.25 10.5C4.66421 10.5 5 10.1642 5 9.75C5 9.33579 4.66421 9 4.25 9C3.83579 9 3.5 9.33579 3.5 9.75C3.5 10.1642 3.83579 10.5 4.25 10.5Z"
      fill="#FFF6F2"
    />
    <path
      d="M6.75 10.5C7.16421 10.5 7.5 10.1642 7.5 9.75C7.5 9.33579 7.16421 9 6.75 9C6.33579 9 6 9.33579 6 9.75C6 10.1642 6.33579 10.5 6.75 10.5Z"
      fill="#FFF6F2"
    />
    <path
      d="M4.25 13C4.66421 13 5 12.6642 5 12.25C5 11.8358 4.66421 11.5 4.25 11.5C3.83579 11.5 3.5 11.8358 3.5 12.25C3.5 12.6642 3.83579 13 4.25 13Z"
      fill="#FFF6F2"
    />
    <path
      d="M6.75 13C7.16421 13 7.5 12.6642 7.5 12.25C7.5 11.8358 7.16421 11.5 6.75 11.5C6.33579 11.5 6 11.8358 6 12.25C6 12.6642 6.33579 13 6.75 13Z"
      fill="#FFF6F2"
    />
    <path
      d="M9.25 13C9.66421 13 10 12.6642 10 12.25C10 11.8358 9.66421 11.5 9.25 11.5C8.83579 11.5 8.5 11.8358 8.5 12.25C8.5 12.6642 8.83579 13 9.25 13Z"
      fill="#FFF6F2"
    />
    <path
      d="M4 1.5V2.5"
      stroke="#FFF6F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 1.5V2.5"
      stroke="#FFF6F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M14.5 5H1.5" stroke="#FFF6F2" stroke-linejoin="round" />
  </svg>
</template>