<template>
  <div class="container" @click="()=>toEvent(event)">
    <div class="event-info">
      <div variant="list-item" style="color: #959595; margin-bottom: 4px">
        {{ event?.attributes.NID }}
      </div>
      <div variant="navigation-bold" style="color: var(--color-blue-01)">
        {{ event?.attributes.Name }}
      </div>
      <div variant="list-item" style="color: var(--color-blue-12)">
        {{ event?.attributes.StartAt ? formatDateAndTime(event?.attributes.StartAt) : 'Még nincs megadva kezdőidőpont'}}
      </div>
    </div>
    <div class="event-actions">
      <Chip
        v-if="!draft"
        :variant="event?.attributes.Status"
        style="margin-right: 32px"
      />
      <div v-if="!draft" class="flex" style="position: relative">
        <button
          v-if="!event.attributes.EventCancellationRequest?.data"
          @click.stop="() => (toBeDeletedEvent = event)"
          variant="white"
          data-tooltip="Törlés"
          icon
        >
          <trash-icon />
        </button>
        <button
          icon
          variant="transparent"
          v-if="event.attributes.EventCancellationRequest?.data"
          data-tooltip="Törlési kérelem elküldve."
        >
          <!-- <info-icon /> -->
          <info-icon-dark />
        </button>
        <button
          data-tooltip="Másolás"
          variant="white"
          icon
          @click.stop="() => $emit('oncopy')"
        >
          <copy-icon />
        </button>
        <button
          variant="white"
          icon
          data-tooltip="Megtekintés"
          @click.stop="
            () =>
              $router.push({
                name: 'ActiveEventPage',
                params: { eventId: event.id },
              })
          "
        >
          <chevron-right-icon />
        </button>
      </div>
      <div v-if="draft" class="flex">
        <button
          variant="white"
          icon
          data-tooltip="Szerkesztés"
          @click.stop="
            () =>
              $router.push({ name: 'EventPage', params: { eventId: event.id } })
          "
        >
          <edit-icon />
        </button>
      </div>
    </div>
  </div>
  <delete-request-modal
    v-if="toBeDeletedEvent"
    :event="toBeDeletedEvent"
    @close="() => (toBeDeletedEvent = null)"
  />
</template>

<script>
import Chip from "@/components/ChipComponent.vue";
import {
  TrashIcon,
  CopyIcon,
  ChevronRightIcon,
  EditIcon,
  // InfoIcon,
  InfoIconDark,
} from "@/assets/icons";
import DeleteRequestModal from "../events/DeleteRequestModal.vue";
import useFormatDate from "../../hooks/useFormatDate";
import { ref } from "@vue/reactivity";
import { useRoute, useRouter } from 'vue-router';

export default {
  props: ["event", "draft"],
  emits: ["oncopy"],
  components: {
    Chip,
    TrashIcon,
    CopyIcon,
    ChevronRightIcon,
    EditIcon,
    DeleteRequestModal,
    // InfoIcon,
    InfoIconDark,
  },
  setup() {
    const toBeDeletedEvent = ref(null);
    const router = useRouter();
    const { params } = useRoute();

    const { formatDateAndTime } = useFormatDate();

    const toEvent = (event)=>{
      router.push({
        name: event.attributes.Status === 'draft' ? 'EventPage': 'ActiveEventPage',
        params: {
          ...params,
          eventId: event.id
        }
      })
    }

    return {
      toBeDeletedEvent,
      formatDateAndTime,
      toEvent
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  cursor: pointer;
}
.event-actions {
  display: flex;
  align-items: center;
}
</style>