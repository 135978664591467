import { createStore, createLogger } from 'vuex'
import me from './modules/me'
import config from './modules/config'
import underApprovalBookings from './modules/underApprovalBookings'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    me,
    config,
    underApprovalBookings
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})