<template>
  <Modal @close="handleClose">
    <!-- REQUEST NO OK -->
    <div v-if="!requestOk" class="delete-request-container">
      <delete-request-image></delete-request-image>
      <h1 v-if="!isDraft" variant="page-title">Törlési kérelem küldése</h1>
      <h1 v-if="isDraft" variant="page-title">Esemény törlése</h1>
      <div v-if="!isDraft" variant="list-item">
        Meghirdetett eseményeket csak az adminisztrátor tud véglegesen törölni a rendszerből. 
        Kérjük írd le, hogy miért szeretnéd törölni ezt az eseményt!
      </div>
      <div v-if="isDraft" variant="list-item">
        Az esemény piszkozata véglegesen törlésre kerül, melyet utólag már nem
        lehet visszaállítani.
      </div>
      <div style="margin-top: 12px" v-if="isDraft" variant="navigation-bold">
        Biztosan törlöd a rendszerből?
      </div>
      <form @submit.prevent="handleSubmit">
        <textarea
          v-if="!isDraft"
          maxlength="500"
          placeholder="Törlési kérelem oka..."
          v-model="message"
        />
        <input-error v-if="validationErrors?.message" :error="validationErrors.message" />
        <div class="bottom-buttons">
          <button type="button" @click="$emit('close')" variant="white">
            Mégsem
          </button>
          <button v-if="!isDraft" type="submit">Törlés kérése</button>
          <button v-if="isDraft" type="submit">Törlöm az eseményt</button>
        </div>
      </form>
    </div>

    <!-- REQUEST OK -->
    <div v-if="requestOk" class="delete-request-container">
      <delete-request-ok-image style="margin-bottom: 32px" />
      <div v-if="!isDraft" variant="navigation-bold">
        Sikeresen kérelmezted a(z)
      </div>
      <div v-if="isDraft" variant="navigation-bold">
        Sikeresen törölted a(z)
      </div>
      <h1
        variant="page-title"
        style="color: var(--color-blue-04-1); margin: 16px 0px"
      >
        {{ event.attributes.Name }}
      </h1>
      <div v-if="!isDraft" variant="navigation-bold">
        nevű esemény törlését!
      </div>
      <div v-if="isDraft" variant="navigation-bold">nevű eseményt!</div>
    </div>
  </Modal>
</template>

<script>
import Modal from "../../components/ModalComponent.vue";
import DeleteRequestImage from "../../assets/images/DeleteRequestImage.vue";
import DeleteRequestOkImage from "../../assets/images/DeleteRequestOkImage.vue";
import { ref } from "@vue/reactivity";
import eventApi from "../../api/event";
import useValidation from "../../hooks/useValidation";
import InputError from '../../components/InputError.vue';

export default {
  props: ["event"],
  emits: ["close", "delete"],
  components: {
    Modal,
    DeleteRequestImage,
    DeleteRequestOkImage,
    InputError,
  },
  setup(props, { emit }) {
    const message = ref("");
    const requestOk = ref(false);
    const isDraft = props.event.attributes.Status == "draft";

    // HANDLERS
    const handleClose = () => emit("close");

    const handleSubmit = async () => {
      if (!isDraft) {
        validate(
          { message: message.value },
          { onAccept: async () => {
              //IF NOT DRAFT -> CANCELLATION REQUEST
              const res = await eventApi.createCancellationRequest(props.event.id, message.value);
              if (res.ok) {
                requestOk.value = true;
                emit("delete");
              } else {
                console.log(res);
              }
            },
            onDecline: () => {}
          }
        );
        
      } else {
        // IF DRAFT
        const res = await eventApi.update(
          { status: "deleted" },
          props.event.id
        );
        if (res.ok) {
          requestOk.value = true;
          emit("delete");
        } else {
          console.log(res);
        }
      }
    };

    // VALIDATION
    const rules = {
      message: [{ validator: "required" }],
    };
    const { validate, errors: validationErrors } = useValidation(rules);
    //--------
    return {
      isDraft,
      message,
      requestOk,
      handleSubmit,
      handleClose,
      validationErrors
    };
  },
};
</script>

<style scoped>
.delete-request-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 34px 64px;
}
.delete-request-container form {
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
textarea {
  width: 100%;
  max-width: 100%;
  min-height: 64px;
}
.bottom-buttons {
  display: flex;
  align-items: stretch;
  margin-top: 16px;
  width: 100%;
  max-width: 400px;
}
.bottom-buttons button {
  flex: 1;
  margin: 3px;
}
</style>