import { onMounted, readonly, ref } from "vue";
import tagsApi from "../api/tags";

function useTags() {
  const loading = ref(true);
  const tags = ref(null);

  const getTags = async () => {
    const res = await tagsApi.get();
    if (res.ok) {
      tags.value = res.data.data.map((tag) => ({
        value: tag.id,
        label: tag.attributes.Name,
      }));
      loading.value = false;
    } else {
      loading.value = false;
    }
  };

  onMounted(() => getTags());

  return {
    loading,
    tags: readonly(tags),
  };
}

export default useTags;
