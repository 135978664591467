<template>
  <ion-page>
    <ion-content>
      <SidebarNavigation active="ReportsPage">
        <div v-if="!loadingReport">
          <div class="background">
            <!-- HEADER -->
            <div class="header">
              <div class="flex">
                <button
                  data-tooltip="Vissza"
                  @click="() => $router.go(-1)"
                  variant="white"
                  icon
                >
                  <arrow-back-icon />
                </button>
                <div variant="page-title" style="margin-left: 16px">
                  {{ report.attributes.Period }}
                </div>
                <div
                  variant="tag"
                  style="
                    margin-left: 16px;
                    font-size: 11px;
                    padding: 4px 12px;
                    background-color: var(--color-grey-05);
                    border-radius: 100px;
                    text-transform: none;
                  "
                >
                  {{ report.attributes.NID }}
                </div>
                <chip-component :variant="report.attributes.Status" />
              </div>
              <Tabs :tabs="tabs" :active="currentView" />
            </div>
          </div>
          <ReportBillingView v-if="currentView == 'billing'" :report="report" />
          <ReportBookingsView
            v-if="currentView == 'bookings'"
            :report="report"
          />
          <ReportEventsView v-if="currentView == 'events'" :report="report" />
        </div>
      </SidebarNavigation>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import SidebarNavigation from "@/components/SidebarNavigation";
import { useRoute } from "vue-router";
import { ArrowBackIcon } from "../../assets/icons";
import Tabs from "@/components/TabsComponent.vue";
import { ref } from "@vue/reactivity";
import ReportBillingView from "./ReportBillingView.vue";
import ReportBookingsView from "./ReportBookingsView.vue";
import ReportEventsView from "./ReportEventsView.vue";
import useReport from "../../hooks/useReport";
import ChipComponent from "../../components/ChipComponent.vue";
export default {
  components: {
    IonPage,
    IonContent,
    SidebarNavigation,
    Tabs,
    ArrowBackIcon,
    ReportBillingView,
    ReportBookingsView,
    ReportEventsView,
    ChipComponent,
  },
  setup() {
    const route = useRoute();
    const reportId = route.params.reportId;
    const currentView = ref("billing");

    const { report, loading: loadingReport } = useReport(reportId, {
      setToRead: true,
    });

    const tabs = [
      {
        value: "billing",
        label: "Számlázási adatok",
        onClick: () => (currentView.value = "billing"),
      },
      {
        value: "bookings",
        label: "Foglalások",
        onClick: () => (currentView.value = "bookings"),
      },
      {
        value: "events",
        label: "Események",
        onClick: () => (currentView.value = "events"),
      },
    ];

    // const report = {
    //   id: "001",
    //   attributes: {
    //     Period: "2022 Február",
    //     Date: "2022-02-20",
    //     Amount: 333666,
    //     Status: "unread",
    //     CompanyName: "Cégnév",
    //     TaxNumber: "28949231-2-09",
    //     HQ: "4027 Debrecen, Viola utca 9. 2. em. 8.",
    //     bookings: [
    //       {
    //         id: "001",
    //         EventDate: "2022.02.01. 12:00-18:00",
    //         EventId: "001",
    //         Date: "2021.12.24. 11:01",
    //         Count: 8,
    //         Price: 6000,
    //         Amount: 48000,
    //       },
    //     ],
    //     events: [
    //       {
    //         id: "001",
    //         Date: "2022.02.01. 12:00-18:00",
    //         Headcount: 21,
    //         Mult: 1,
    //         Price: 6000,
    //         PlatformPrice: 6000,
    //       },
    //     ],
    //   },
    // };

    return {
      reportId,
      report,
      loadingReport,
      tabs,
      currentView,
    };
  },
};
</script>

<style scoped>
.background {
  background-color: var(--color-white);
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px;
}
</style>