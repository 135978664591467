<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 1.625C3.31195 1.625 1.125 3.81195 1.125 6.5C1.125 9.18805 3.31195 11.375 6 11.375C8.68805 11.375 10.875 9.18805 10.875 6.5C10.875 3.81195 8.68805 1.625 6 1.625ZM8.53711 4.86617L5.38711 8.61617C5.35256 8.65733 5.30956 8.69058 5.26104 8.71367C5.21251 8.73676 5.15959 8.74915 5.10586 8.75H5.09953C5.04697 8.74998 4.995 8.73891 4.94699 8.71751C4.89898 8.69611 4.85601 8.66486 4.82086 8.62578L3.47086 7.12578C3.43657 7.08942 3.4099 7.04657 3.39242 6.99975C3.37493 6.95293 3.36698 6.90309 3.36902 6.85315C3.37107 6.80322 3.38309 6.75419 3.40435 6.70896C3.42562 6.66374 3.45571 6.62322 3.49286 6.58978C3.53001 6.55635 3.57347 6.53068 3.62068 6.51429C3.66789 6.49789 3.71791 6.4911 3.76778 6.4943C3.81766 6.49751 3.86639 6.51066 3.91111 6.53297C3.95583 6.55528 3.99564 6.5863 4.0282 6.62422L5.08969 7.80359L7.96289 4.38383C8.02734 4.30932 8.11852 4.26316 8.21673 4.25533C8.31494 4.24751 8.41228 4.27864 8.48771 4.34201C8.56315 4.40537 8.61062 4.49588 8.61986 4.59396C8.62911 4.69205 8.59938 4.78983 8.53711 4.86617Z"
      fill="#6CA32F"
    />
  </svg>
</template>