<template>
  <form class="form-card" @submit.prevent="handleSubmit">
    <!-- TITLE -->
    <div
      variant="page-title"
      style="margin-bottom: 16px; display: flex; align-items: center"
    >
      <button
        @click="handleBack"
        type="button"
        variant="white"
        style="margin-right: 16px"
        icon
        data-tooltip="Vissza"
      >
        <arrow-back-icon />
      </button>
      Elfelejtett jelszó
    </div>

    <!-- INFO -->
    <div class="info">
      <div>
        <InfoIcon />
      </div>
      <div variant="small-text">
        Add meg a regisztrált e-mail címed, majd módosítsd jelszavadat a
        regisztrált e-mail címedre elküldött link segítségével. Ha nem érkezett
        meg, nézd meg a Kéretlen/SPAM levelek között is. Amennyiben ott sem
        találod, akkor kérünk, hogy vedd fel velünk a kapcsolatot a
        <a target="_blank" href="mailto:hiba@orszagosbortura.hu"
          >hiba@orszagosbortura.hu</a
        >
        e-mail címen.
      </div>
    </div>

    <!-- INPUT -->
    <Input
      label="Regisztrált e-mail cím"
      type="email"
      v-model="formData.identifier"
      placeholder="Add meg a regisztrált e-mail címed"
    >
      <template v-slot:left><user-icon /></template>
    </Input>
    <button
      :disabled="!formData.identifier || loading"
      type="submit"
      icon-right
    >
      <span v-if="!loading">Jelszó módosító e-mail elküldése <send-icon /></span>
      <span v-if="loading">E-mail küldése folyamatban...</span>
    </button>
  </form>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import Input from "../../components/InputComponent.vue";
import authApi from "@/api/auth.js";
import {
  UserIcon,
  SendIcon,
  ArrowBackIcon,
  InfoIcon,
} from "../../assets/icons";
import { useToast } from "vue-toastification";
export default {
  components: { Input, UserIcon, SendIcon, ArrowBackIcon, InfoIcon },
  setup() {
    const formData = ref({ identifier: "" });
    const router = useRouter();
    const loading = ref(false);
    const toast = useToast();

    const handleBack = () => router.back();

    const handleSubmit = async () => {
      // TODO
      loading.value = true;
      const res = await authApi.forgotPassword(formData.value.identifier);
      if (res.ok) {
        toast.success("Sikeresen elküldtük a jelszó beállító e-mail-t!");
      } else {
        toast.error("Hiba az e-mail elküldése során!");
      }
      loading.value = false;
      // router.push({ name: "NewPasswordPage" });
    };

    return {
      handleBack,
      handleSubmit,
      formData,
      loading,
    };
  },
};
</script>

<style>
</style>