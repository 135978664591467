import client, { requestConfig } from "./client";

const endpoint = "/api/users";

const getMe = () => client.get(endpoint + "/me", {}, requestConfig());
const update = (id, data) => client.put(endpoint + `/${id}`,data, requestConfig());

export default {
  getMe,
  update
};
