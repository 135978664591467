import { onIonViewDidEnter } from "@ionic/vue";
import { ref } from "vue";
import reportApi from "../api/report";

function useReport(reportId, { setToRead }) {
  const loading = ref(true);
  const report = ref(null);

  const update = async (data) => {
    const res = await reportApi.update(data, reportId);
    if (res.ok) {
      console.log("ok")
    } else {
      console.log(res.data);
    }
    return res;
  };

  const getReport = async () => {
    const res = await reportApi.getById(reportId);
    if (res.ok) {
      if (res.data.data.attributes.Status == "unread" && setToRead) {
        const updateRes = await update({ data: { Status: "read" } });
        if (updateRes.ok) {
          report.value = updateRes.data.data;
          loading.value = false;
        }
      } else {
        report.value = res.data.data;
        loading.value = false;
      }
    } else {
      console.log(res.data);
      loading.value = false;
    }
  };

  onIonViewDidEnter(() => reportId && getReport());

  return {
    loading,
    report,
    update,
    getReport,
  };
}

export default useReport;
