<template>
  <div class="container">
    <button
      :disabled="currentPage == 1"
      @click="onClick(currentPage - 1)"
      icon
      variant="white"
    >
      <ArrowBackIcon />
    </button>
    <div v-for="idx in pageRange" :key="idx">
      <button
        v-if="!(idx < currentPage - 1 || idx > currentPage + 1)"
        icon
        :variant="currentPage == idx ? '' : 'white'"
        @click="onClick(idx)"
      >
        {{ idx }}
      </button>
    </div>
    <button
      :disabled="currentPage == pageRange.length"
      @click="onClick(currentPage + 1)"
      icon
      variant="white"
    >
      <ArrowForwardIcon />
    </button>
  </div>
</template>

<script>
import { ArrowBackIcon, ArrowForwardIcon } from "@/assets/icons";
import { computed } from "@vue/runtime-core";
export default {
  props: ["currentPage", "max", "onClick"],
  components: {
    ArrowBackIcon,
    ArrowForwardIcon,
  },
  setup(props) {
    const pageRange = computed(() => {
      var pages = [];
      for (let i = 1; i <= props.max; i++) {
        pages.push(i);
      }
      return pages;
    });
    return {
      pageRange,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
}
button {
  margin: 1px;
}
</style>