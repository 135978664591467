<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.25 3H7.25C6.78602 3.0005 6.34119 3.18503 6.01311 3.51311C5.68503 3.84119 5.5005 4.28602 5.5 4.75V8H10.2928L8.64656 6.35344C8.55675 6.25891 8.50742 6.13303 8.50909 6.00265C8.51076 5.87227 8.5633 5.7477 8.6555 5.6555C8.7477 5.5633 8.87227 5.51076 9.00265 5.50909C9.13303 5.50742 9.25891 5.55675 9.35344 5.64656L11.8534 8.14656C11.9471 8.24032 11.9998 8.36745 11.9998 8.5C11.9998 8.63255 11.9471 8.75968 11.8534 8.85344L9.35344 11.3534C9.25891 11.4432 9.13303 11.4926 9.00265 11.4909C8.87227 11.4892 8.7477 11.4367 8.6555 11.3445C8.5633 11.2523 8.51076 11.1277 8.50909 10.9973C8.50742 10.867 8.55675 10.7411 8.64656 10.6466L10.2928 9H5.5V12.25C5.5 13.2516 6.55594 14 7.5 14H12.25C12.714 13.9995 13.1588 13.815 13.4869 13.4869C13.815 13.1588 13.9995 12.714 14 12.25V4.75C13.9995 4.28602 13.815 3.84119 13.4869 3.51311C13.1588 3.18503 12.714 3.0005 12.25 3Z"
      fill="#959595"
    />
    <path
      d="M2.5 8C2.36739 8 2.24021 8.05268 2.14645 8.14645C2.05268 8.24021 2 8.36739 2 8.5C2 8.63261 2.05268 8.75979 2.14645 8.85355C2.24021 8.94732 2.36739 9 2.5 9H5.5V8H2.5Z"
      fill="#FFF6F2"
    />
  </svg>
</template>