import { useCookies as useVue3Cookies } from "vue3-cookies";

const { cookies } = useVue3Cookies();

const useCookies = {
  get: (name) => cookies.get(name),
  set: (name, value) => cookies.set(name, value),
  remove: (name) => cookies.remove(name),
};

export default useCookies;
