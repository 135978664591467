<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4555 2.5H3.54484V3C3.54484 3 2.73484 8.5 8.00015 8.5C13.2655 8.5 12.4555 3 12.4555 3V2.5Z"
      stroke="#FFF6F2"
      stroke-linejoin="round"
    />
    <path d="M8 8.5V13.5" stroke="#FFF6F2" stroke-linejoin="round" />
    <path
      d="M11 13.5H5"
      stroke="#FFF6F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M3.5 5H12.5" stroke="#FFF6F2" stroke-linejoin="round" />
  </svg>
</template>