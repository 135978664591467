<template>
  <ion-page>
    <ion-content>
      <div class="login-background">
        <LoadingScreen v-if="loading" />
        <LoginHeader />
        <LoginForm :handleSubmit="handleSubmit" :firstUse="route.query?.firstuse"/>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import { useRoute, useRouter } from "vue-router";
import useCookies from "../../hooks/useCookies";
import LoginForm from "./LoginForm.vue";
import LoginHeader from "../../components/LoginHeader.vue";
import LoadingScreen from "../../components/LoadingScreen.vue";
import useAuth from "../../hooks/useAuth";

export default {
  name: "LoginPage",
  components: {
    IonPage,
    IonContent,
    LoginForm,
    LoginHeader,
    LoadingScreen,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { login, loading } = useAuth();

    const handleSubmit = async (e, formData) => {
      await login({ ...formData }, () => {
        // await router.push({
        //   name: "WinerySelectorPage",
        //   params: { userId: res.data.user.id },
        // });
        if(route.query.next){
          window.location = route.query.next
        } else {
          window.location.reload();
        }
      });
    };

    return {
      handleSubmit,
      router,
      useCookies,
      loading,
      route
    };
  },
};
</script>

<style>
.login-background {
  background-size: cover;
  background-image: url("@/assets/images/background.png");
  width: 100%;
  min-height: 100%;
  padding-bottom: 32px;
}
</style>