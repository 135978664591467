<template>
  <form class="form-card" @submit.prevent="onSubmit($e, formData, email)">
    <div variant="page-title" style="margin-bottom: 16px">
      {{ title }}
    </div>
    <div class="info">
      <div><InfoIcon /></div>
      <div variant="small-text">
        {{ info1 }}
        <span style="text-decoration: underline">{{ email }}</span>
        {{ info2 }}
      </div>
    </div>

    <Input
      label="Új jelszó"
      type="password"
      v-model="formData.password"
      placeholder="Jelszó megadása"
      :error="validationErrors.password"
    >
      <template v-slot:left><password-icon /></template>
    </Input>

    <Input
      label="Új jelszó ismét"
      type="password"
      v-model="formData.passwordAgain"
      placeholder="Jelszó megadása mégegyszer"
      :error="validationErrors.passwordAgain"
    >
      <template v-slot:left><password-icon /></template>
    </Input>

    <div v-if="'privacy' in formData" style="display:flex">
      <Checkbox
        v-model="formData.privacy"
        alabel="Elolvastam és elfogadom az Adatvédelmi Tájékoztatót!"
      />
      <label variant="input-label" style="margin-left: 8px">
        Elolvastam és elfogadom az 
        <a target="_blank" href="https://orszagosbortura.hu/obt-adatvedelmi-tajekoztato-altalanos.pdf">Adatvédelmi Tájékoztatót</a>!
      </label>
    </div>
    <div v-if="'terms' in formData" style="display:flex">
      <Checkbox
        v-model="formData.terms"
        alabel="Elolvastam és elfogadom az Általános Szerződési Feltételeket!"
      />
      <label variant="input-label" style="margin-left: 8px">
        Elolvastam és elfogadom az
        <a target="_blank" href="https://orszagosbortura.hu/obt-aszf-partner.pdf"> Általános Szerződési Feltételeket</a>!
      </label>
    </div>

    <button
      :disabled="
        !formData.password ||
        !formData.passwordAgain ||
        ('privacy' in formData && !formData.privacy) ||
        ('terms' in formData && !formData.terms)
      "
      type="submit"
      icon-right
    >
      Mentés és bejelentkezés<LoginIcon />
    </button>
  </form>
</template>

<script>
import { ref } from "@vue/reactivity";
import Input from "../../components/InputComponent.vue";
import Checkbox from "../../components/CheckboxComponent.vue";
import { PasswordIcon, LoginIcon, InfoIcon } from "../../assets/icons";
import useValidation from "../../hooks/useValidation";
export default {
  props: ["handleSubmit", "register", "email"],
  components: {
    Input,
    Checkbox,
    PasswordIcon,
    LoginIcon,
    InfoIcon,
  },
  setup(props) {
    const formData = ref({
      password: "",
      passwordAgain: "",
      ...(props.register && { privacy: false, terms: false }),
    });

    const title = props.register
      ? "Regisztráció véglegesítése"
      : "Új jelszó beállítása";
    const info1 = props.register
      ? "Egy utolsó lépés a(z) "
      : "Add meg meg a(z) ";
    const info2 = props.register
      ? " e-mail címedhez tartozó fiók aktiválásához. Állíts be jelszavad a fiókodhoz."
      : " e-mail címedhez tartozó új jelszavadat.";

    // VALIDATION
    const rules = {
      password: [{ validator: "minlength", params: { min: 6 } }],
      passwordAgain: [
        { validator: "minlength", params: { min: 6 } },
        {
          validator: "equal",
          params: {
            field: "password",
            fieldLabel: "'jelszó'",
          },
        },
      ],
      // ...(props.register && { privacy: ["required"] }),
      // ...(props.terms && { terms: ["required"] }),
    };
    const { validate, errors: validationErrors } = useValidation(rules);

    const onSubmit = (e, formData, email) => {
      validate(formData, {
        onAccept: () => props.handleSubmit(e, formData, email)
      });
    };

    return {
      title,
      info1,
      info2,
      formData,
      validationErrors,
      onSubmit,
    };
  },
};
</script>

<style>
</style>