import dayjs from "dayjs";
import client, { requestConfig } from "./client";
import * as _ from 'lodash'
const endpoint = "/api/events";

const transformDataToStrapi = (formData) => {
  const fields = [
    { d: "name", s: "Name" },
    { d: "cover", s: "Cover" },
    { d: "startAt", s: "StartAt" },
    { d: "endAt", s: "EndAt" },
    { d: "status", s: "Status" },
    { d: "location.Title", s: "LocationName" },
    { d: "location.Address", s: "LocationAddress" },
    { d: "location.LocationId", s: "LocationId" },
    { d: "price.net", s: "NetPrice" },
    { d: "price.detailed", s: "DetailedPricing" },
    { d: "price.program.net", s: "NetPriceProgram" },
    { d: "price.meal.net", s: "NetPriceFood" },
    { d: "price.accommodation.net", s: "NetPriceAccommodation" },
    { d: "headcount.min", s: "MinHeadCount" },
    { d: "headcount.max", s: "MaxHeadCount" },
    { d: "description", s: "Description" },
    { d: "wines", s: "Wines" },
    { d: "link", s: "Link" },
    { d: "wineryId", s: "Winery" },
    { d: "extras", s: "Extras" },
    { d: "tags", s: "Tags" },
  ];
  var data = {};
  fields.forEach((field) => {
    if (field.d.split(".")[0] in formData) {
      data[field.s] = _.get(formData, field.d);
    }
  });
  return {
    data,
  };
};

const add = (data) =>
  client.post(endpoint, transformDataToStrapi(data), requestConfig());

const copy = (event, wineryId, config) => {
  const offset = config.attributes.EventCreationDayPadding;
  const earliestDate = dayjs().add(offset, "day");
  // TODO CONFIG -> set the offset
  const eventStart = dayjs(event.attributes.StartAt);
  const eventEnd = dayjs(event.attributes.EndAt);
  const dateAllowed = eventStart.diff(earliestDate) > 0;

  return client.post(
    endpoint,
    {
      data: {
        ...event.attributes,
        ...(!dateAllowed && {
          StartAt: earliestDate
            .set("hour", eventStart.get("hour"))
            .set("minute", eventStart.get("minute"))
            .set("second", 0)
            .set("millisecond", 0),
          EndAt: earliestDate
            .set("hour", eventEnd.get("hour"))
            .set("minute", eventEnd.get("minute"))
            .set("second", 0)
            .set("millisecond", 0),
        }),
        Name: event.attributes.Name,
        Winery: parseInt(wineryId),
        Status: "draft",

        Extras: event.attributes.Extras?.data?.map((extra) => extra.id),
        Tags: event.attributes.Tags?.data?.map((tag) => tag.id),

        Cover: undefined,
        Bookings: undefined,
        createdAt: undefined,
        publishedAt: undefined,
        updatedAt: undefined,
        Reserved: undefined,
        Booked: undefined,
        EventCancellationRequest: undefined,
        FinancialReport: undefined,
      },
    },
    requestConfig()
  );
};

const get = (wineryId, pageSize, page, { search, status, dateFrom, dateTo }) =>
  client.get(
    endpoint,
    {
      populate: "*",
      "filters[Winery][id]": wineryId,
      "pagination[pageSize]": pageSize,
      "pagination[page]": page,
      "filters[Status]": status,
      "filters[$or][0][NID][$contains]": search,
      "filters[$or][1][Name][$containsi]": search,
      "filters[StartAt][$gt]": dateFrom,
      "filters[StartAt][$lt]": dateTo,
      "sort[0]":"StartAt:desc"
    },
    requestConfig()
  );

const update = (data, eventId) =>
  client.put(
    endpoint + "/" + eventId,
    transformDataToStrapi(data),
    requestConfig()
  );

const getById = (eventId, populate = {populate: "*"}) =>
  client.get(`${endpoint}/${eventId}`, { ...populate }, requestConfig());

const getUpcoming = (wineryId, startAt) =>
  client.get(
    endpoint,
    {
      "filters[Winery][id]": wineryId,
      "filters[Status]": "active",
      "filters[StartAt]": startAt,
      "sort[0]": "StartAt",
      populate: "EventCancellationRequest",
    },
    requestConfig()
  );

const getRecentDrafts = (wineryId) =>
  client.get(
    endpoint,
    {
      "filters[Winery][id]": wineryId,
      "filters[Status]": "draft",
      "sort[0]": "createdAt",
    },
    requestConfig()
  );

const createCancellationRequest = (eventId, message) =>
  client.post(
    "/api/event-cancellation-requests",
    { data: { Event: eventId, Message: message } },
    requestConfig()
  );

export default {
  add,
  get,
  update,
  copy,
  getById,
  getUpcoming,
  getRecentDrafts,
  createCancellationRequest,
};
