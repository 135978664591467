// initial state
//example: https://github.com/vuejs/vuex/blob/main/examples/classic/shopping-cart/store/modules/cart.js
import configApi from "../../api/config"

const state = () => ({
  data: null
})

// getters
const getters = {
  getConfig(state){
    return state.data
  }
}

// actions
const actions = {
  async getConfig ({ commit, state }) {
    if(state.data){
      return state.data
    } else {
      const resp =await configApi.get()
      if(resp.ok){
        commit('setConfig',resp.data.data)
      }
      return resp.data.data
    }   
  },
  async refresh ({commit}) {
    const resp =await configApi.get()
      if(resp.ok){
        commit('setConfig',resp.data.data)
      }
      return resp.data.data
  }
}

// mutations
const mutations = {
  setConfig(state, config){
    state.data = config
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}