
import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

function useUnderApprovalBookings(wineryId) {
  watch(()=>wineryId,()=>{getBookings()})
  const store = useStore();

  const bookings = ref(store.getters['underApprovalBookings/getUnderApprovalBookings'][wineryId]?.data);
  const meta = ref(store.getters['underApprovalBookings/getUnderApprovalBookings'][wineryId]?.meta)
  const loading = ref(true);

  const getBookings = async () => {
    const bookingsTmp = (await store.dispatch('underApprovalBookings/getUnderApprovalBookings',wineryId)[wineryId]);
    if (bookingsTmp) {
      bookings.value = bookingsTmp['data'];
      meta.value = bookingsTmp['meta'];
    }
    loading.value = false;
    return bookingsTmp;
  };

  watch(
    ()=>store.getters['underApprovalBookings/getUnderApprovalBookings'],
    (oldValue, newValue)=>{
      bookings.value = newValue[wineryId]?.data;
      meta.value = newValue[wineryId]?.meta;
    }
  )

  async function refresh(){
    await store.dispatch('underApprovalBookings/refresh',parseInt(wineryId));
  }
  
  onMounted(getBookings);

  return {
    loading,
    bookings,
    meta,
    getBookings,
    refresh,
  };
}

export default useUnderApprovalBookings;
