<template>
  <ion-page>
      <ion-content>
        <div class="container">
          <div class="logo">
            <OBTLogo />
          </div>
        </div>
      </ion-content>
  </ion-page>
</template>

<script>
import {IonPage,IonContent} from "@ionic/vue"
import useMe from '../hooks/useMe'
import { defineComponent, watchEffect } from '@vue/runtime-core'
import { useRouter } from "vue-router"
import OBTLogo from "../assets/OBTLogo.vue"
export default defineComponent({
    name:"SplashScreen",
    components:{
        IonPage,
        IonContent,
        OBTLogo
    },
    setup(){
        const {me, loading: loadingMe} = useMe()
        const router = useRouter()

        watchEffect(()=>{
            if (!loadingMe.value){
                if(me.value){
                  //return router.push({name : "WinerySelectorPage",params: {userId : me.value.id}})
                  window.location = `/users/${me.value.id}`
                } else {
                  return router.push({name : "LoginPage"})
                }
            } 
        })

        return {
            me, loadingMe, router
        }
    },
})
</script>

<style scoped>
  .container {
    background: var(--color-blue-02);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    animation-name: stretch;
    animation-duration: 2.0s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;
  }

  @keyframes stretch {
    0% {
        transform: scale(2.0);
    }

    100% {
        transform: scale(2.5);
    }
}
</style>