<template>
  <ion-page>
    <ion-content>
      <SidebarNavigation active="EventsPage">
        <div class="background" v-if="!loadingEvent">
          <!-- HEADER -->
          <div class="header">
            <div class="header-container">
              <div class="flex" style="justify-content: flex-start;">
                <!-- BACK BUTTON -->
                <button
                  style="margin-right: 16px"
                  icon
                  variant="white"
                  @click="handleBack"
                  data-tooltip="Vissza"
                >
                  <arrow-back-icon />
                </button>
                <div>
                  <div class="flex">
                    <div variant="page-title">{{ event.attributes.Name }}</div>
                    <Chip :variant="event.attributes.Status" />
                  </div>
                </div>
              </div>
              <div class="flex w-440">
                <!-- PAGE TABS -->
                <Tabs :tabs="pageTabs" :active="activePageTab" />

                <!-- COPY BUTTON -->
                <button
                  data-tooltip="Másolás"
                  style="margin-left: 16px"
                  icon
                  variant="white"
                  @click="
                    () =>
                      copyEvent(event, config, (data) => {
                        return $router.push({
                          name: 'EventPage',
                          params: {
                            ...$router.params,
                            eventId: data.id,
                          },
                        });
                      })
                  "
                >
                  <copy-icon />
                </button>

                <!-- DELETE BUTTON -->
                <button
                  @click="() => (showDeleteModal = true)"
                  icon
                  variant="white"
                  data-tooltip="Törlés"
                  v-if="
                    !['ended','deleted','ongoing'].includes(event.attributes.Status) &&
                    !event.attributes.EventCancellationRequest.data
                  "
                >
                  <trash-icon />
                </button>

                <!-- INFO BUTTON -->
                <div style="position: relative">
                  <button
                    v-if="
                      event.attributes.Status == 'active' &&
                      event.attributes.EventCancellationRequest.data
                    "
                    data-tooltip="Törlési kérelem elküldve."
                    data-tooltip-position="left"
                    variant="transparent"
                    icon
                  >
                    <!-- <info-icon /> -->
                    <info-icon-dark />
                  </button>
                </div>
              </div>
            </div>
            <div variant="list-item" style="display: flex; align-items: center; margin-left: 48px;" v-if="['active','ongoing'].includes(event.attributes.Status)">
              <span style="padding-right: 4px;">Jegyvásárlási link:</span> <a :href="`https://www.orszagosbortura.hu/esemenyek/${event.attributes.NID}`" target="_blank"> {{ `https://www.orszagosbortura.hu/esemenyek/${event.attributes.NID}` }}</a>
              <copy-button :tooltipText="'jegyvásárlási link másolása'" :text="`https://www.orszagosbortura.hu/esemenyek/${event.attributes.NID}`"></copy-button>
            </div>
          </div>
        </div>

        <ActiveEventDetails
          :config="config"
          :event="event"
          v-if="!loadingEvent && !loadingConfig && config && event && activePageTab == 'event'"
          :handleSubmit="handleUpdateEvent"
        />

        <BookingsTable
          :event="event"
          :bookings="event.attributes.Bookings.data"
          :onUpdateFinished="handleBookingUpdateFinished"
          v-if="!loadingEvent && event && activePageTab == 'bookings'"
        />

        <ComplimentaryTicketsTable
          :event="event"
          v-if="!loadingEvent && event && activePageTab == 'complimentary-tickets'"
        />
      </SidebarNavigation>

      <Modal
        v-if="showEventUpdatedModal"
        @close="() => (showEventUpdatedModal = false)"
      >
        <div class="flex" style="flex-direction: column; padding: 48px 64px">
          <svg
            style="margin-bottom: 32px"
            width="144"
            height="144"
            viewBox="0 0 144 144"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="144" height="144" rx="72" fill="#D6244A" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M93.317 54.4949C94.1483 55.2222 94.2325 56.4858 93.5052 57.317L65.5052 89.317C65.1406 89.7336 64.6198 89.9805 64.0666 89.9989C63.5133 90.0173 62.9772 89.8057 62.5858 89.4142L50.5858 77.4142C49.8047 76.6332 49.8047 75.3669 50.5858 74.5858C51.3668 73.8048 52.6332 73.8048 53.4142 74.5858L63.9026 85.0742L90.4948 54.683C91.2222 53.8517 92.4857 53.7675 93.317 54.4949Z"
              fill="#FFF6F2"
            />
          </svg>
          <div variant="navigation-bold">Sikeresen mentetted a</div>
          <div
            variant="page-title"
            style="margin: 16px 0px; color: var(--color-blue-04-1)"
          >
            létszám adatokat
          </div>
          <div variant="navigation-bold">Az alábbi eseményen!</div>
        </div>
      </Modal>

      <delete-request-modal
        :event="event"
        v-if="showDeleteModal"
        @close="() => (showDeleteModal = false)"
      />
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import SidebarNavigation from "@/components/SidebarNavigation";
import { useRoute, useRouter } from "vue-router";
import {
  ArrowBackIcon,
  CopyIcon,
  TrashIcon,
  InfoIconDark,
} from "../../assets/icons";
import useEvent from "../../hooks/useEvent";
import Chip from "../../components/ChipComponent.vue";
import Tabs from "../../components/TabsComponent.vue";
import ActiveEventDetails from "./ActiveEventDetails.vue";
import { ref } from "@vue/reactivity";
import Modal from "../../components/ModalComponent.vue";
import DeleteRequestModal from "../events/DeleteRequestModal.vue";
import BookingsTable from "../bookings/BookingsTable.vue";
import useConfig from "../../hooks/useConfig";
import ComplimentaryTicketsTable from '../complimentary-tickets/ComplimentaryTicketsTable.vue';
import CopyButton from '../../components/CopyButton.vue';

export default {
  name: "ActiveEventPage",
  components: {
    ActiveEventDetails,
    ArrowBackIcon,
    IonPage,
    Chip,
    IonContent,
    SidebarNavigation,
    Tabs,
    CopyIcon,
    TrashIcon,
    Modal,
    DeleteRequestModal,
    BookingsTable,
    InfoIconDark,
    ComplimentaryTicketsTable,
    CopyButton
  },
  setup() {
    const router = useRouter();
    const { params } = useRoute();
    const eventId = params.eventId;

    const { config, loading: configLoading } = useConfig();
    const {
      event,
      loading: loadingEvent,
      update: updateEvent,
      getEvent,
      error: eventError,
      copy: copyEvent,
    } = useEvent(eventId,{
      "populate[Bookings][populate][PackageExtras]": true,
      "populate[Cover]": true,
      "populate[EventCancellationRequest]": true,
      "populate[Extras]": true,
      "populate[FinancialReport]": true,
      "populate[Tags]": true,
      "populate[Winery]": true,
      "populate[Package][populate][Extras]": true,
      "populate[Package][populate][WineList]": true,
      "populate[ComplimentaryTickets]": true
    });
    const showEventUpdatedModal = ref(false);
    const showDeleteModal = ref(false);
    // TABS
    const pageTabs = [
      {
        label: "Esemény adatok",
        value: "event",
        onClick: () => (activePageTab.value = "event"),
      },
      {
        label: "Foglalások",
        value: "bookings",
        onClick: () => (activePageTab.value = "bookings"),
      },
      {
        label: "Tiszteletjegyek",
        value: "complimentary-tickets",
        onClick: () => (activePageTab.value = "complimentary-tickets"),
      },
    ];
    const activePageTab = ref("event");

    // HANDLERS
    const handleBack = () => router.back();
    const handleBookingUpdateFinished = async () => {
      await getEvent();
    };
    const handleUpdateEvent = async (formData) => {
      const res = await updateEvent({
        ...formData,
        status: event.value.attributes.Status,
      });
      if (res.ok) {
        showEventUpdatedModal.value = true;
      }
    };

    return {
      handleBack,
      event,
      eventError,
      loadingEvent,
      pageTabs,
      activePageTab,
      showEventUpdatedModal,
      showDeleteModal,
      handleUpdateEvent,
      handleBookingUpdateFinished,
      copyEvent,
      config,
      configLoading,
    };
  },
};
</script>

<style scoped>
.header {
  padding: 24px;
  display: block;
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.background {
  background-color: var(--color-white);
}

.w-280 {
  min-width: 280px;
}
.w-440 {
  min-width: 440px;
}
</style>