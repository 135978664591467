<template>
  <table>
    <thead>
      <tr>
        <th>Sorozatszám</th>
        <th>Név</th>
        <th>Résztvevők</th>
        <th>Státusz</th>
        <th>Érvényesítés dátuma</th>
        <th></th>
      </tr>
    </thead>
    <tbody v-if="complimentaryTickets?.length">
      <tr v-for="complimentaryTicket in complimentaryTickets"  style="cursor: pointer" @click="handleComplimentaryTicketPage(complimentaryTicket.id)" :key="complimentaryTicket.attributes.NID">
        <td>{{ complimentaryTicket.attributes.NID }}</td>
        <td>{{ complimentaryTicket.attributes.Name  || '- Nincs megadva -'}}</td>
        <td>{{ complimentaryTicket.attributes.Participants }}</td>
        <td>
          <Chip :variant="complimentaryTicket.attributes.Status" type="complimentary-ticket"/>
        </td>
        <td>
          {{ complimentaryTicket.attributes.VerifiedAt ? formatDateAndTime(complimentaryTicket.attributes.VerifiedAt) : ''}}
        </td>
        <!-- IF EVENT IS GIVEN DONT SHOW THE NAME -->
        
        <!-- <td><Chip :variant="booking.attributes.Status" /></td> -->
        <!-- ACTION BUTTONS -->
        <td>
          <div style="display: flex">
            <button
              :disabled="!verificationEnabled(complimentaryTicket, event)"
              :data-tooltip="verificationEnabled(complimentaryTicket, event) ? 'Megjelent':'A verifikáció még nem aktív'"
              v-if="complimentaryTicket.attributes.Status == 'active'"
              variant="success"
              icon
              @click.stop="() => (toBeAttendedBooking = complimentaryTicket)"
            >
              <check-icon />
            </button>
            <button
              @click.stop="() => (toBeEditedBooking = complimentaryTicket)"
              icon
              variant="white"
              v-if="isEditVisible(complimentaryTicket, event)"
              data-tooltip="Szerkesztés"
            >
              <edit-icon />
            </button>
            <button
              data-tooltip="Megtekintés"
              @click.stop="handleComplimentaryTicketPage(complimentaryTicket.id)"
              variant="white"
              icon
            >
              <chevron-right-icon />
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <page-selector
    v-if="complimentaryTickets?.length"
    :currentPage="page"
    :max="pageCount"
    :onClick="handleSelectPage"
  />
  <AttendedModal
    type="complimentary-ticket"
    :visible="!!toBeAttendedBooking"
    :booking="toBeAttendedBooking"
    :handleClose="() => (toBeAttendedBooking = null)"
    :handleStatusUpdate="handleComplimentaryTicketUpdate"
  />
  <NotAttendedModal
    :visible="!!toBeNotAttendedBooking"
    :booking="toBeNotAttendedBooking"
    :handleClose="() => (toBeNotAttendedBooking = null)"
    :handleStatusUpdate="handleComplimentaryTicketUpdate"
  />
  <EditStatusModal
    :visible="!!toBeEditedBooking"
    :booking="toBeEditedBooking"
    :handleClose="() => (toBeEditedBooking = null)"
    :handleStatusUpdate="handleComplimentaryTicketUpdate"
  />
</template>

<script>
import {
  CheckIcon,
  ChevronRightIcon,
  EditIcon,
} from "../../assets/icons";
import Chip from "../../components/ChipComponent.vue";
import dayjs from "dayjs";
import { useRouter } from "vue-router";
import useFormatDate from "../../hooks/useFormatDate";
import AttendedModal from "../bookings/AttendedModal.vue";
import NotAttendedModal from "../bookings/NotAttendedModal.vue";
import EditStatusModal from "../bookings/EditStatusModal.vue";
import { ref } from "@vue/reactivity";
import useComplimentaryTickets from "../../hooks/useComplimentaryTickets"
import PageSelector from '../../components/PageSelector.vue';
import complimentaryTicketApi from '../../api/complimentary-ticket';

export default {
  props: ["onUpdateFinished", "event"],
  components: {
    EditIcon,
    CheckIcon,
    ChevronRightIcon,
    Chip,
    AttendedModal,
    NotAttendedModal,
    EditStatusModal,
    PageSelector
  },
  setup(props) {
    const { params } = useRouter();
    const router = useRouter();
    const { formatDateAndTime } = useFormatDate();
    const toBeAttendedBooking = ref(null);
    const toBeNotAttendedBooking = ref(null);
    const toBeEditedBooking = ref(null);
    const loading = ref(false);

    const { 
      complimentaryTickets, 
      loading: complimentaryTicketsLoading, 
      getComplimentaryTickets, 
      page, 
      pageCount, 
      total 
    } = useComplimentaryTickets(props.event.id, 10)

    const isEditVisible = () => {
      return false
    };

    const verificationEnabled = (complimentaryTicket, event) => {
      if(!complimentaryTicket) return
      const eventStartAt = dayjs(event
        ? event.attributes.StartAt : complimentaryTicket.attributes.Event.data.attributes.StartAt);
      const eventEndAt = dayjs(event
        ? event.attributes.EndAt : complimentaryTicket.attributes.Event.data.attributes.EndAt);
      const now = dayjs();

      if(complimentaryTicket.attributes.Status === "active" && now < eventEndAt.add(24,"hours") && now > eventStartAt.add(-1, "hours")){
        return true;
      } else {
        return false;
      }
    }

    const handleSelectPage = (page) => {
      getComplimentaryTickets(page)
    }

    const handleComplimentaryTicketUpdate = async (complimentaryTicketId, status) => {
      const res = await complimentaryTicketApi.update(
        { data: { Status: status } },
        complimentaryTicketId
      );
      if (res.ok) {
        toBeAttendedBooking.value = null;
        toBeNotAttendedBooking.value = null;
        toBeEditedBooking.value = null;
        getComplimentaryTickets(page.value)
      }
    };

    const handleComplimentaryTicketPage = (complimentaryTicketId) =>
      router.push({
        name: "ComplimentaryTicketDetailsPage",
        params: { ...params, complimentaryTicketId },
      });

    return {
      isEditVisible,
      handleComplimentaryTicketPage,
      formatDateAndTime,
      toBeAttendedBooking,
      toBeNotAttendedBooking,
      toBeEditedBooking,
      handleComplimentaryTicketUpdate,
      loading,
      verificationEnabled,

      complimentaryTickets,
      complimentaryTicketsLoading,

      getComplimentaryTickets,
      page,
      pageCount,
      total,
      handleSelectPage
    };
  },
  created(){
    this.getComplimentaryTickets(1, {})
  }
};
</script>

<style>
</style>