<template>
  <form class="event-form" @submit.prevent="">
    <!-- NAME -->
    <div class="event-input-container">
      <label>Név</label>
      <div class="input-container">
        <!-- INPUT -->
        <Input
          v-if="!previewMode"
          :noMargin="true"
          type="text"
          v-model="formData.name"
          :error="validationErrors.name"
        />
        <!-- PREVIEW -->
        <div v-else variant="list-item" :class="{ empty: !formData.name }">
          {{ formData.name || "- Nem került kitöltésre -" }}
        </div>
      </div>
    </div>
    <hr />

    <!-- COVER -->
    <div class="event-input-container">
      <label>Borító kép</label>
      <div
        v-if="initialData?.attributes?.Cover.data || selectedMediaPreviewUrl"
        class="input-container"
      >
        <div style="display: flex; border-radius: 8px; overflow: hidden">
          <img
            v-if="selectedMediaPreviewUrl"
            :src="selectedMediaPreviewUrl"
            style="height: 200px"
          />
          <img
            v-else-if="initialData?.attributes.Cover.data"
            style="height: 200px"
            :src="
              initialData?.attributes.Cover.data?.attributes.formats?.small
                ?.url ||
              initialData?.attributes.Cover.data?.attributes.formats?.thumbnail
                ?.url
            "
            alt=""
          />
          <div
            style="
              flex: 1;
              background-color: var(--color-grey-05);
              padding: 40px 32px;
            "
          >
            <div variant="thbl" style="margin-bottom: 8px">
              Feltöltött fájl neve
            </div>
            <div variant="list-item" style="margin-bottom: 32px">
              {{
                selectedMedia?.name ||
                initialData?.attributes.Cover.data.attributes.name
              }}
            </div>
            <button
              v-if="initialData?.attributes.Cover.data || selectedMedia"
              variant="white"
              @click="handleRemoveCover"
              style="margin-right: 5px"
            >
              Kép eltávolítása
            </button>
            <button variant="white" @click="() => uploadMediaInputRef.click()">
              Kép cseréje
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <button @click="() => uploadMediaInputRef.click()">
          Keresés a számítógépén...
        </button>
        <div v-if="!previewMode">
          <input-error
            v-if="validationErrors['selectedMedia']"
            :error="validationErrors['selectedMedia']"
          />
        </div>
      </div>
      <input
        :hidden="true"
        ref="uploadMediaInputRef"
        type="file"
        @change="handleChangeMedia"
      />
    </div>
    <hr />

    <!-- DATE -->
    <div class="event-input-container">
      <label>Időpont</label>
      <div v-if="!previewMode" style="flex: 2">
        <div class="input-container" style="display: flex;">
          <Input
            :inputStyle="`display: block; margin: auto; width: 100%; min-width: 100px;`"
            :noMargin="true"
            label="Kezdés időpontja"
            type="date"
            style="flex: 1"
            v-model="formData.date.startDate"
            :error="validationErrors['date.startDate']"
            :min="
              dayjs()
                .add(config.attributes.EventCreationDayPadding, 'day')
                .format('YYYY-MM-DD')
            "
            @blur="onBlur"
          />
          <Input
            :noMargin="true"
            label="Időpont"
            :hideLabel="true"
            type="time"
            style="flex: 1"
            v-model="formData.date.startTime"
            :error="validationErrors['date.startTime']"
            @blur="onBlur"
          />
        </div>
        <div>
          <input-error v-if="error?.StartAt" :error="[error.StartAt]" />
        </div>
        <div style="margin-top: 16px"></div>
        <div class="input-container" style="display: flex">
          <Input
            :noMargin="true"
            :inputStyle="`display: block; margin: auto; width: 100%; min-width: 100px;`"
            label="Befejezés időpontja"
            type="date"
            style="flex: 1"
            v-model="formData.date.endDate"
            :error="validationErrors['date.endDate']"
            :min="
              formData.date.startDate ||
              dayjs()
                .add(config.attributes.EventCreationDayPadding, 'day')
                .format('YYYY-MM-DD')
            "
            @blur="onBlur"
          />
          <Input
            :noMargin="true"
            label="Időpont"
            :hideLabel="true"
            type="time"
            style="flex: 1"
            v-model="formData.date.endTime"
            :error="validationErrors['date.endTime']"
            @blur="onBlur"
          />
        </div>
      </div>

      <div v-else class="input-container" style="display: flex">
        <div style="flex: 1" variant="list-item">
          <span variant="thbl">Kezdés időpontja: </span>
          <span v-if="formData.date.startDate && formData.date.startTime"
            >{{ formData.date.startDate }} {{ formData.date.startTime }}</span
          >
          <span class="empty" v-else>- Nem került kitöltésre -</span>
        </div>
        <div style="flex: 1" variant="list-item">
          <span variant="thbl">Befejezés időpontja: </span>
          <span v-if="formData.date.endDate && formData.date.endTime"
            >{{ formData.date.endDate }} {{ formData.date.endTime }}</span
          >
          <span class="empty" v-else>- Nem került kitöltésre -</span>
        </div>
      </div>
    </div>

    <hr />

    <!-- LOCATION -->
    <div class="event-input-container">
      <label>Helyszín</label>
      <create-location-modal
        v-if="!previewMode"
        :handleSubmit="handleLocationChange"
        :selectedWinery="formData.location"
        :locations="winery.attributes.Addresses"
        :error="validationErrors['location.Title']"
        :wineryName="winery.attributes.Name"
      />
      <div
        v-else-if="
          previewMode && (formData.location.Title || formData.locationAddress)
        "
        class="input-container"
      >
        <div variant="list-item">
          {{ formData.location.Title }}
          <span style="color: var(--color-grey-03); margin-left: 8px">{{
            formData.location.Address
          }}</span>
        </div>
      </div>
      <div
        v-else-if="
          previewMode && !formData.location.Title && !formData.location.Address
        "
        class="empty"
        variant="list-item"
      >
        - Nem került kitöltésre -
      </div>
    </div>
    <hr />

    <!-- PRICE -->
    <div class="event-input-container">
      <label style="display: flex; align-items: center"
        >Árak
        <button
          style="margin-right: 16px"
          icon
          variant="white"
          data-tooltip="A meghirdetett ár tartalmazza az ÁFA-t, az árrést és a kezelési díjat is!"
        >
          <info-icon-dark />
        </button>
      </label>
      <div
        v-if="!previewMode"
        class="input-container"
        style="margin: auto; margin-top: 0px"
      >
        <div>
          <!-- ENABLE DETAILED PRICES -->
          <span style="display: flex; margin-bottom: 16px">
            <Checkbox
              label="Jegyár részletező"
              v-model="formData.price.detailed"
              style="margin-bottom: 0px"
            />
            <button
              icon
              variant="white"
              :data-tooltip="`Amennyiben a jegyár ételfogyasztást, ill. szállást is tartalmaz, akkor itt lehetőséged van részletesen megadni a jegyárhoz tartozó tételeket.`"
            >
              <info-icon-dark />
            </button>
          </span>
        </div>
        <div
          v-if="!formData.price.detailed"
          style="display: flex"
          :class="{ fade: formData.price.detailed }"
        >
          <div>
            <div style="display:flex">
              <Input
                :noMargin="true"
                label="Nettó alapár/fő"
                type="number"
                v-model="formData.price.net"
                max="1000000"
                @focus="activePriceField = '.net'"
                :disabled="formData.price.detailed"
              />
              <Input
                :noMargin="true"
                label="Áfa %"
                type="number"
                :placeholder="formData.price.vat"
                :disabled="true"
              />
              <Input
                :noMargin="true"
                label="Bruttó alapár/fő"
                type="number"
                v-model="formData.price.gross"
                :error="validationErrors['price.gross']"
                max="1000000"
                @focus="activePriceField = '.gross'"
                :disabled="formData.price.detailed"
              />
              <Input
                :noMargin="true"
                label="Meghirdetett ár/fő"
                :disabled="true"
                type="number"
                v-model="formData.price.displayed"
              />
            </div>
            <div>
              <InputError :error="validationErrors['price.displayed']" v-if="validationErrors['price.displayed']" />
            </div>
          </div>
        </div>
        <!-- DETAILED PRICES -->
        <div v-else style="margin-top: 16px">
          <!-- DETAILED PRICES FORM -->
          <div :class="{ fade: !formData.price.detailed }">
            <!-- PROGRAM -->
            <div class="flex" style="gap: 8px">
              <Checkbox
                :reverse="true"
                label="Program"
                v-model="formData.price.program.check"
                class="detailed-checkbox"
                :disabled="!formData.price.detailed"
              />
              <div
                class="flex"
                :class="{ fade: !formData.price.program.check }"
              >
                <Input
                  label="Nettó ár/fő"
                  v-model="formData.price.program.net"
                  :disabled="
                    !formData.price.detailed || !formData.price.program.check
                  "
                  max="1000000"
                  type="number"
                  @focus="activePriceField = 'program.net'"
                />
                <Input
                  label="Áfa %"
                  :placeholder="formData.price.program.vat"
                  :disabled="true"
                  type="number"
                />
                <Input
                  label="Bruttó ár/fő"
                  v-model="formData.price.program.gross"
                  :disabled="
                    !formData.price.detailed || !formData.price.program.check
                  "
                  max="1000000"
                  type="number"
                  @focus="activePriceField = 'program.gross'"
                />
              </div>
            </div>
            <!-- MEAL -->
            <div class="flex" style="gap: 8px">
              <Checkbox
                :reverse="true"
                label="Ételfogyasztás"
                v-model="formData.price.meal.check"
                class="detailed-checkbox"
                :disabled="!formData.price.detailed"
              />
              <div class="flex" :class="{ fade: !formData.price.meal.check }">
                <Input
                  label="Nettó ár/fő"
                  v-model="formData.price.meal.net"
                  :disabled="
                    !formData.price.detailed || !formData.price.meal.check
                  "
                  :error="validationErrors['price.meal.net']"
                  max="1000000"
                  type="number"
                  @focus="activePriceField = 'meal.net'"
                />
                <Input
                  label="Áfa %"
                  :placeholder="formData.price.meal.vat"
                  :disabled="true"
                  type="number"
                />
                <Input
                  label="Bruttó ár/fő"
                  v-model="formData.price.meal.gross"
                  :disabled="
                    !formData.price.detailed || !formData.price.meal.check
                  "
                  :error="validationErrors['price.meal.gross']"
                  max="1000000"
                  type="number"
                  @focus="activePriceField = 'meal.gross'"
                />
              </div>
            </div>
            <!-- ACCOMMODATION -->
            <div class="flex" style="gap: 8px">
              <Checkbox
                :reverse="true"
                label="Szálláshely"
                v-model="formData.price.accommodation.check"
                class="detailed-checkbox"
                :disabled="!formData.price.detailed"
              />
              <div
                class="flex"
                :class="{ fade: !formData.price.accommodation.check }"
              >
                <Input
                  label="Nettó ár/fő"
                  v-model="formData.price.accommodation.net"
                  :disabled="
                    !formData.price.detailed ||
                    !formData.price.accommodation.check
                  "
                  :error="validationErrors['price.accommodation.net']"
                  max="1000000"
                  type="number"
                  @focus="activePriceField = 'accommodation.net'"
                />
                <Input
                  label="Áfa %"
                  :placeholder="formData.price.accommodation.vat"
                  :disabled="true"
                  type="number"
                />
                <Input
                  label="Bruttó ár/fő"
                  v-model="formData.price.accommodation.gross"
                  :disabled="
                    !formData.price.detailed ||
                    !formData.price.accommodation.check
                  "
                  max="1000000"
                  type="number"
                  @focus="activePriceField = 'accommodation.gross'"
                />
              </div>
            </div>
            <div style="max-width: 300px; float: right">
              <Input
                :noMargin="true"
                label="Meghirdetett ár/fő"
                :error="validationErrors['price.displayed'] && ['A nettó árak összegének 1 és 1000000 között kell lennie']"
                :disabled="true"
                type="number"
                v-model="formData.price.displayed"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- PREVIEW -->
      <div v-else class="input-container" style="display: flex">
        <div style="flex: 1" variant="list-item">
          <!-- NOT DETAILED PRICE -->
          <div v-if="!formData.price.detailed">
            <div>
              <span variant="thbl">Nettó alapár/fő: </span>
              <span v-if="formData.price.net"
                >{{ formData.price.net }} Ft</span
              >
              <span class="empty" v-else>- Nem került kitöltésre -</span>
            </div>
            <div>
              <span variant="thbl">Bruttó alapár/fő: </span>
              <span v-if="formData.price.gross"
                >{{ formData.price.gross }} Ft</span
              >
              <span class="empty" v-else>- Nem került kitöltésre -</span>
            </div>
          </div>
          <!-- DETAILED PRICE -->
          <div v-else>
            <div
              v-for="field in [
                { value: 'program', label: 'Program' },
                { value: 'meal', label: 'Ételfogyasztás' },
                { value: 'accommodation', label: 'Szálláshely' },
              ]"
              :key="field.value"
            >
              <span>{{ field.label }}</span>
              <div style="margin-left: 16px">
                <div>
                  <span variant="thbl">Nettó alapár/fő: </span>
                  <span v-if="formData.price[field.value].net"
                    >{{ formData.price[field.value].net }} Ft</span
                  >
                  <span class="empty" v-else>- Nem került kitöltésre -</span>
                </div>
                <div>
                  <span variant="thbl">Bruttó alapár/fő: </span>
                  <span v-if="formData.price[field.value].gross"
                    >{{ formData.price[field.value].gross }} Ft</span
                  >
                  <span class="empty" v-else>- Nem került kitöltésre -</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="flex: 1" variant="list-item">
          <span variant="thbl">Meghirdetett ár/fő: </span>
          <span v-if="formData.price.displayed"
            >{{ formData.price.displayed }} Ft</span
          >
          <span class="empty" v-else>- Nincs adat -</span>
        </div>
      </div>
    </div>
    <hr />

    <!-- HEADCOUNT -->
    <div class="event-input-container">
      <label>Létszám adatok</label>
      <div
        v-if="!previewMode"
        class="input-container"
        style="margin: auto; margin-top: 0px"
      >
        <Input
          :noMargin="true"
          label="Minimum létszám"
          type="number"
          v-model="formData.headcount.min"
          :error="validationErrors['headcount.min']"
        />
      </div>
      <div
        v-if="!previewMode"
        class="input-container"
        style="margin: auto; margin-top: 0px"
      >
        <Input
          :noMargin="true"
          label="Férőhelyek száma"
          type="number"
          v-model="formData.headcount.max"
          :error="validationErrors['headcount.max']"
        />
      </div>
      <div v-else class="input-container" style="display: flex">
        <div style="flex: 1" variant="list-item">
          <span variant="thbl">Minimum létszám: </span>
          <span
            class="empty"
            v-if="
              formData.headcount.min === null ||
              formData.headcount.min === undefined ||
              formData.headcount.min === ''
            "
            >- Nem került kitöltésre -</span
          >
          <span v-else>{{ formData.headcount.min }} Fő</span>
        </div>
        <div style="flex: 1" variant="list-item">
          <span variant="thbl">Férőhelyek száma: </span>
          <span v-if="formData.headcount.max"
            >{{ formData.headcount.max }} Fő</span
          >
          <span class="empty" v-else>- Nem került kitöltésre -</span>
        </div>
      </div>
    </div>
    <div>
      <input-error v-if="error?.MaxHeadCount" :error="[error.MaxHeadCount]" />
    </div>
    <hr />

    <!-- DESCRIPTION -->
    <div class="event-input-container">
      <label
        ><span>Leírás</span> <br /><span class="optional"
          >(opcionális) max {{ limit }} karakter</span
        ></label
      >
      <div class="input-container">
        <div v-if="!previewMode">
          <div style="position: relative">
            <div id="floating-menu" class="floating-menu">
              <div>
              <paste-button
                @paste="(text)=>editor.chain().focus().insertContent(text).run()"
              />
              </div>
            </div>
          </div>
          <editor-content
            @click="editor.chain().focus().run()"
            class="input editor"
            style="height: inherit; min-width: 100%; max-width: 100%"
            :editor="editor"
          />
          <div class="character-count optional" v-if="editor">
            {{ editor.storage.characterCount.characters() }}/{{ limit }}
            karakter
          </div>
        </div>
        <!-- <textarea
          v-if="!previewMode"
          style="min-width: 100%; max-width: 100%"
          placeholder="Röviden mutasd be, hogy miről fog szólni a program!"
          v-model="formData.description"
        /> -->
        <div
          v-else
          variant="list-item"
          :class="{ empty: !formData.description }"
          v-html="formData.description || '- Nem került kitöltésre -'"
        ></div>
      </div>
    </div>
    <hr />

    <div style="position: relative; z-index: 1">
      <!-- TAGS -->
      <div class="event-input-container">
        <label>Az esemény tartalmazza</label>
        <div class="input-container">
          <MultiSelect
            v-if="!previewMode"
            :value="formData.tags"
            :options="tags"
            :onChange="
              (value, cb) => {
                formData.tags = value;
              }
            "
          />
          <div v-else-if="previewMode && formData.tags.length">
            <div class="tag" v-for="tag in formData.tags" :key="tag.value">
              {{ tag.label }}
            </div>
          </div>
          <div
            v-else-if="previewMode && !formData.tags.length"
            variant="list-item"
            class="empty"
          >
            - Nem került kitöltésre -
          </div>
        </div>
      </div>
      <hr />

      <!-- EXTRAS -->
      <div class="event-input-container">
        <label>Elérhető extrák</label>
        <div class="input-container">
          <MultiSelect
            v-if="!previewMode"
            :value="formData.extras"
            :options="extras"
            :onChange="
              (value, cb) => {
                formData.extras = value;
              }
            "
          />
          <div v-if="previewMode && formData.extras.length">
            <div
              class="tag"
              v-for="extra in formData.extras"
              :key="extra.value"
            >
              {{ extra.label }}
            </div>
          </div>
          <div
            v-else-if="previewMode && !formData.extras.length"
            variant="list-item"
            class="empty"
          >
            - Nem került kitöltésre -
          </div>
        </div>
        <!-- <div style="flex: 1">
        <div
          class="checkbox-container"
          v-for="extra in extras"
          :key="extra.value"
        >
          <Checkbox
            :label="extra.label"
            v-model="formData.extras[extra.value]"
          />
        </div>
      </div> -->
      </div>
      <hr />
    </div>

    <!-- WINES -->
    <div class="event-input-container">
      <label
        ><span>A kóstoló borai</span> <br /><span class="optional"
          >(opcionális) max 2000 karakter</span
        ></label
      >
      <div class="input-container">
        <div v-if="!previewMode">
          <editor-content
            @click="editorWines.chain().focus().run()"
            class="input editor"
            style="height: inherit; min-width: 100%; max-width: 100%"
            :editor="editorWines"
          />
          <div class="character-count optional" v-if="editorWines">
            {{ editorWines.storage.characterCount.characters() }}/{{ limit }}
            karakter
          </div>
        </div>
        <div
          v-else
          variant="list-item"
          :class="{ empty: !formData.wines }"
          v-html="formData.wines || '- Nem került kitöltésre -'"
        ></div>
      </div>
    </div>
    <hr />

    <!-- LINK -->
    <div class="event-input-container">
      <label
        ><span>Közösségi média esemény link</span> <br /><span class="optional"
          >(opcionális)</span
        ></label
      >
      <div class="input-container">
        <Input
          v-if="!previewMode"
          placeholder="Ha van, másold be ide az esemény közösségi média linkjét!"
          v-model="formData.link"
        />
        <div v-else variant="list-item" :class="{ empty: !formData.link }">
          {{ formData.link || "- Nem került kitöltésre -" }}
        </div>
      </div>
    </div>

    {{ error }}
    <div style="margin-bottom: 64px"></div>

    <!-- BOTTOM BUTTON PREVIEW -->
    <div v-if="!previewMode" class="bottom-button-container">
      <div></div>
      <button
        icon-right
        @click="
          () => {
            previewMode = true;
            $emit('modechange', 'preview');
          }
        "
      >
        Ellenőrzés <arrow-forward-icon />
      </button>
    </div>
    <!-- BOTTOM BUTTON FORM -->
    <div v-else class="bottom-button-container">
      <button
        icon-left
        variant="white"
        @click="
          () => {
            previewMode = false;
            $emit('modechange', 'form');
          }
        "
      >
        <arrow-back-icon /> Adatok szerkesztése
      </button>
      <div>
        <button
          variant="dark"
          type="button"
          @click="onSubmit(formData, selectedMedia, 'draft')"
        >
          Mentés piszkozatként
        </button>
        <button
          style="margin-left: 8px"
          type="button"
          @click="() => (showPublishModal = true)"
        >
          Meghirdetés
        </button>
      </div>
    </div>
  </form>

  <modal-component v-if="showPublishModal">
    <div
      style="
        padding: 32px 64px;
        display: flex;
        flex-direction: column;
        align-items: center;
      "
    >
      <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="margin-bottom: 24px"
      >
        <rect width="64" height="64" rx="32" fill="#7B6CCC" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.5 28.625C24.5 27.5895 25.3395 26.75 26.375 26.75H32.375C33.4105 26.75 34.25 27.5895 34.25 28.625V44.75C34.25 45.7855 33.4105 46.625 32.375 46.625C31.3395 46.625 30.5 45.7855 30.5 44.75V30.5H26.375C25.3395 30.5 24.5 29.6605 24.5 28.625Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M23.6562 45.125C23.6562 44.0895 24.4957 43.25 25.5312 43.25H38.4688C39.5043 43.25 40.3438 44.0895 40.3438 45.125C40.3438 46.1605 39.5043 47 38.4688 47H25.5312C24.4957 47 23.6562 46.1605 23.6562 45.125Z"
          fill="white"
        />
        <path
          d="M32 23C31.4067 23 30.8266 22.8241 30.3333 22.4944C29.8399 22.1648 29.4554 21.6962 29.2284 21.1481C29.0013 20.5999 28.9419 19.9967 29.0576 19.4147C29.1734 18.8328 29.4591 18.2982 29.8787 17.8787C30.2982 17.4591 30.8328 17.1734 31.4147 17.0576C31.9967 16.9419 32.5999 17.0013 33.1481 17.2284C33.6963 17.4554 34.1648 17.8399 34.4944 18.3333C34.8241 18.8266 35 19.4067 35 20C35 20.7957 34.684 21.5587 34.1213 22.1213C33.5587 22.6839 32.7957 23 32 23Z"
          fill="white"
        />
      </svg>

      <div variant="page-title" style="margin-bottom: 12px">Fontos!</div>
      <div variant="list-item" style="margin-bottom: 12px">
        A meghirdetést követően az esemény publikussá válik, melyet már nem
        fogsz tudni törölni, és módosítani, kivéve a minimum létszámot és a
        férőhelyek számát. Törlési kérelmet azonban tudsz indítani.
      </div>
      <div variant="navigation-bold" style="margin-bottom: 24px">
        Biztos meghirdeted az eseményt?
      </div>

      <div style="width: 100%; display: flex">
        <button
          @click="onSubmit(formData, selectedMedia, 'draft')"
          style="flex: 1; margin-right: 8px"
          variant="white"
        >
          Mentés piszkozatként
        </button>
        <button
          @click="onSubmit(formData, selectedMedia, 'active')"
          style="flex: 1"
          variant="primary-normal"
        >
          Meghirdetés
        </button>
      </div>
    </div>
  </modal-component>
</template>

<script>
import { ref } from "@vue/reactivity";
import { watch, watchEffect } from "@vue/runtime-core";
import CreateLocationModal from "./CreateLocationModal.vue";
import MultiSelect from "../../components/MultiSelect.vue";
import { useRoute } from "vue-router";
import dayjs from "dayjs";
import useExtras from "../../hooks/useExtras";
import useValidation from "../../hooks/useValidation";
import useTags from "../../hooks/useTags";
import Input from "../../components/InputComponent.vue";
import Checkbox from "../../components/CheckboxComponent.vue";
import { ArrowBackIcon, ArrowForwardIcon, InfoIconDark } from "@/assets/icons";
import InputError from "../../components/InputError.vue";
import ModalComponent from "../../components/ModalComponent.vue";
import { useToast } from "vue-toastification";
import { EditorContent, useEditor } from "@tiptap/vue-3";
import FloatingMenu from "@tiptap/extension-floating-menu";
import CharacterCount from "@tiptap/extension-character-count";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import PasteButton from '../../components/PasteButton.vue';

function getGross(price) {
  if (price.net === null) {
    return null;
  }
  return Math.round(price.net * (1 + price.vat / 100));
}

function getNet(price) {
  if (price.gross === null) {
    return null;
  }
  return Math.round(price.gross / (1 + price.vat / 100));
}

export default {
  name: "EventForm",
  props: [
    "handleChange",
    "handleSubmit",
    "initialData",
    "onRemoveCover",
    "winery",
    "config",
    "error",
    "triggerSubmit",
  ],
  emits: ["modechange"],
  components: {
    CreateLocationModal,
    MultiSelect,
    Input,
    Checkbox,
    ArrowBackIcon,
    ArrowForwardIcon,
    InfoIconDark,
    InputError,
    ModalComponent,
    EditorContent,
    PasteButton,
  },
  watch: {
    error(newValue) {
      if (newValue) this.previewMode = false;
    },
    triggerSubmit(newValue, oldValue) {
      if (newValue > oldValue) {
        this.onSubmit(this.formData, this.selectedMedia, "draft");
      }
    },
    // 'formData.description'(newValue) {
    // }
  },

  created() {
    Object.keys(this.rules).forEach((rule) => {
      this.$watch(`formData.${rule}`, () => {
        this.validate(this.formData, { only: { [rule]: true } });
      });
    });

    this.$watch(`formData.price.gross`, this.getPriceWatcher);
    this.$watch(`formData.price.net`, this.getPriceWatcher);
    this.$watch(`formData.price.program.gross`, this.getPriceWatcher);
    this.$watch(`formData.price.program.net`, this.getPriceWatcher);
    this.$watch(`formData.price.meal.gross`, this.getPriceWatcher);
    this.$watch(`formData.price.meal.net`, this.getPriceWatcher);
    this.$watch(`formData.price.accommodation.gross`, this.getPriceWatcher);
    this.$watch(`formData.price.accommodation.net`, this.getPriceWatcher);

    // detailed check watcher
    this.$watch(`formData.price.detailed`, () => {
      if (this.formData.price.detailed) {
        this.formData.price.displayed = this.calculateDetailedDisplayedPrice();
      } else {
        this.formData.price.displayed = this.calculateDisplayedPrice(
          [this.formData.price]
        );
      }
    });

    // price check watchers
    this.$watch(`formData.price.program.check`, () =>
      this.getPriceCheckWatcher("program")
    );
    this.$watch(`formData.price.meal.check`, () =>
      this.getPriceCheckWatcher("meal")
    );
    this.$watch(`formData.price.accommodation.check`, () =>
      this.getPriceCheckWatcher("accommodation")
    );
  },

  setup(props, { emit }) {
    const { params } = useRoute();
    const wineryId = params.wineryId;

    const { extras, loading: loadingExtras } = useExtras();
    const { tags, loading: loadingTags } = useTags();
    const toast = useToast();
    const previewMode = ref(false);
    const showPublishModal = ref(false);
    const floatingMenu = ref(null)

    // media upload
    const uploadMediaInputRef = ref(null);
    const selectedMedia = ref(null);
    const selectedMediaPreviewUrl = ref(null);

    const activePriceField = ref(null);

    const formData = ref({
      wineryId,
      name: props.initialData?.attributes.Name,
      cover: null,
      date: {
        startDate: props.initialData?.attributes.StartAt
          ? dayjs(props.initialData.attributes.StartAt).format("YYYY-MM-DD")
          : "",
        startTime: props.initialData?.attributes.StartAt
          ? dayjs(props.initialData.attributes.StartAt).format("HH:mm")
          : "",
        endDate: props.initialData?.attributes.EndAt
          ? dayjs(props.initialData.attributes.EndAt).format("YYYY-MM-DD")
          : "",
        endTime: props.initialData?.attributes.EndAt
          ? dayjs(props.initialData.attributes.EndAt).format("HH:mm")
          : "",
      },
      location: {
        Title: props.initialData
          ? props.initialData.attributes.LocationName
          : "",
        Address: props.initialData
          ? props.initialData.attributes.LocationAddress
          : "",
        LocationId: props.initialData
          ? props.initialData.attributes.LocationId
          : null,
      },
      price: {
        net: props.initialData ? props.initialData.attributes.NetPrice : null,
        vat: props.config.attributes.VATProgram,
        gross: props.initialData
          ? getGross({
              net: props.initialData.attributes.NetPrice,
              vat: props.config.attributes.VATProgram,
            })
          : null,
        displayed: props.initialData
          ? props.initialData.attributes.DisplayedPrice
          : null,
        detailed: props.initialData
          ? props.initialData.attributes.DetailedPricing
          : false,
        program: {
          check:
            props.initialData &&
            props.initialData.attributes.DetailedPricing &&
            props.initialData.attributes.NetPriceProgram > 0,
          net: props.initialData
            ? props.initialData.attributes.NetPriceProgram
            : null,
          gross: props.initialData
            ? getGross({
                net: props.initialData.attributes.NetPriceProgram,
                vat: props.config.attributes.VATProgram,
              })
            : null,
          vat: props.config.attributes.VATProgram,
        },
        meal: {
          check:
            props.initialData &&
            props.initialData.attributes.DetailedPricing &&
            props.initialData.attributes.NetPriceFood > 0,
          net: props.initialData
            ? props.initialData.attributes.NetPriceFood
            : null,
          gross: props.initialData
            ? getGross({
                net: props.initialData.attributes.NetPriceFood,
                vat: props.config.attributes.VATFood,
              })
            : null,
          vat: props.config.attributes.VATFood,
        },
        accommodation: {
          check:
            props.initialData &&
            props.initialData.attributes.DetailedPricing &&
            props.initialData.attributes.NetPriceAccommodation > 0,
          net: props.initialData
            ? props.initialData.attributes.NetPriceAccommodation
            : null,
          gross: props.initialData
            ? getGross({
                net: props.initialData.attributes.NetPriceAccommodation,
                vat: props.config.attributes.VATAccommodation,
              })
            : null,
          vat: props.config.attributes.VATAccommodation,
        },
      },
      headcount: {
        min: props.initialData
          ? props.initialData.attributes.MinHeadCount
          : null,
        max: props.initialData
          ? props.initialData.attributes.MaxHeadCount
          : null,
      },
      description: props.initialData
        ? props.initialData.attributes.Description
        : "",
      tags: props.initialData
        ? props.initialData.attributes.Tags.data.map((tag) => ({
            value: tag.id,
            label: tag.attributes.Name,
          }))
        : [],
      extras: props.initialData
        ? props.initialData.attributes.Extras.data.map((extra) => ({
            value: extra.id,
            label: extra.attributes.Name,
          }))
        : [],
      wines: props.initialData ? props.initialData.attributes.Wines : "",
      link: props.initialData ? props.initialData.attributes.Link : "",
    });

    const limit = 2000;

    const editor = useEditor({
      content: formData.value.description,
      extensions: [
        // StarterKit.configure({
        //   heading: {
        //     levels: [1, 2, 3],
        //   },
        // })
        StarterKit,
        CharacterCount.configure({
          limit: limit,
        }),
        FloatingMenu.configure({
          shouldShow: ({ editor }) => {
            // show the floating within any paragraph
            return editor.isFocused
          },
          element: document.getElementById("floating-menu")
        })
      ],
      onUpdate: () => {
        // HTML
        formData.value.description = editor.value.getHTML();

        // JSON
        // this.$emit('update:modelValue', this.editor.getJSON())
      },

    });
    const editorWines = useEditor({
      content: formData.value.wines,
      extensions: [
        StarterKit,
        CharacterCount.configure({
          limit: limit,
        }),
        Placeholder.configure({
          placeholder:
            "Add meg, hogy milyen borokat kóstolhatnak a program során!",
        }),
      ],
      onUpdate: () => {
        formData.value.wines = editorWines.value.getHTML();
      },
    });

    const handleChangeMedia = (e) => {
      selectedMedia.value = e.target.files[0];
      selectedMediaPreviewUrl.value = URL.createObjectURL(e.target.files[0]);
    };

    const handleLocationChange = (locationData) => {
      formData.value.location = locationData;
    };

    const handleRemoveCover = async () => {
      if (props.initialData?.attributes.Cover.data) {
        props.onRemoveCover && props.onRemoveCover();
      } else {
        selectedMedia.value = null;
        selectedMediaPreviewUrl.value = null;
      }
    };

    const calculateDisplayedPrice = (prices, plain = false, withoutCeil = false) => {
      let gross = prices.map(p=>{
        const net = parseInt(p.net || 0);
        if(net <= 0){
          return 0;
        }
        const vat = 1 + parseInt(p.vat) / 100;
        return vat*net;
      }).reduce((a,b)=>a+b, 0);
      if (plain) return gross

      const Margin = parseInt(props.config.attributes.Margin);
      const ServiceFee = parseInt(props.config.attributes.ServiceFee);

      let priceWithoutServiceFee = gross + Margin;
      let serviceFee = ServiceFee
      
      if(props.config.attributes.PercentageServiceFeeEnabledForFixEvents){
        const ServiceFeePercentage = parseInt(props.config.attributes.ServiceFeePercentage);

        let percentageServiceFee = priceWithoutServiceFee * ServiceFeePercentage / 100;
        if(percentageServiceFee > ServiceFee){
          serviceFee = percentageServiceFee;
        }
      }

      let fullPrice = priceWithoutServiceFee + serviceFee; 
      
      if(withoutCeil){
        return fullPrice
      }


      // let aboveThousand = fullPrice % 1000;

      // let base = fullPrice - aboveThousand;
      // let addition = aboveThousand;

      // if(addition <= 390){
      //   addition = 390;
      // } else if(addition <=690) {
      //   addition = 690;
      // } else if(addition <=990) {
      //   addition = 990;
      // } else {
      //   // > 990
      //   addition = 1390;
      // }

      // return Math.ceil(base + addition);
      // let displayed;
      // if(props.config.attributes.PercentageServiceFeeEnabledForFixEvents){
      //   displayed = Math.round(fullPrice);
      // } else {
      //   displayed = Math.ceil((fullPrice + 10) / 100) * 100 - 10;
      // }
      const displayed = Math.ceil((fullPrice + 10) / 100) * 100 - 10;

      if (isNaN(displayed)) {
        return null;
      } else {
        return displayed;
      }
    };

    const calculateDetailedDisplayedPrice = () => {
      const prices = [
        formData.value.price.program,
        formData.value.price.meal,
        formData.value.price.accommodation]
      // const programPrice =
      //   calculateDisplayedPrice(formData.value.price.program, true) || 0.0;
      // const mealPrice =
      //   calculateDisplayedPrice(formData.value.price.meal, true) || 0.0;
      // const accommodationPrice =
      //   calculateDisplayedPrice(formData.value.price.accommodation, true) ||
      //   0.0;
      return calculateDisplayedPrice(prices)

      // if (!programPrice && !mealPrice && !accommodationPrice) return null;

      // const Margin = parseInt(props.config.attributes.Margin);
      // const ServiceFee = parseInt(props.config.attributes.ServiceFee);

      // const fullPrice =
      //   programPrice +
      //   mealPrice +
      //   accommodationPrice +
      //   Margin +
      //   ServiceFee +
      //   10;

      // return Math.ceil(fullPrice / 100) * 100 - 10;
    };

    // VALIDATION
    const rules = ref({
      name: [{ validator: "required" }],
      selectedMedia: [{ validator: "required" }],
      "location.Title": [{ validator: "required" }],
      "date.startDate": [
        { validator: "required" },
        {
          validator: "date",
          params: {
            min: dayjs()
              .add(props.config.attributes.EventCreationDayPadding, "day")
              .format("YYYY-MM-DD"),
            customMessage: () => {
              return `Minimum ${
                props.config.attributes.EventCreationDayPadding * 24
              } órával később kezdődő eseményt hirdethetsz meg!`;
            },
          },
        },
      ],
      "date.endDate": [
        { validator: "required" },
        {
          validator: "date",
          params: {
            min: formData.value.date.startDate
              ? dayjs(formData.value.date.startDate).format("YYYY-MM-DD")
              : dayjs()
                  .add(props.config.attributes.EventCreationDayPadding, "day")
                  .format("YYYY-MM-DD"),
          },
        },
      ],
      "date.startTime": [{ validator: "required" }],
      "date.endTime": [{ validator: "required" }],
      "price.displayed": [
        { validator: "required" },
        { validator: "number",
          params: { 
            min: calculateDisplayedPrice([{net: 1, vat: props.config.attributes.VATProgram}], true), 
            max: calculateDisplayedPrice([{net: 1000001, vat: props.config.attributes.VATProgram}],false, true), 
          },
          error: 'A nettó árnak 1 és 1000000 között kell lennie'
        },
      ],
      "headcount.min": [
        { validator: "required" },
        { validator: "number", params: { min: 0 } },
      ],
      "headcount.max": [
        { validator: "required" },
        { validator: "number", params: { min: 1 } },
        { validator: "number", params: { min: "headcount.min" } },
      ],
    });
    const { validate, errors: validationErrors } = useValidation(rules.value);
    // ----------

    // HANDLERS
    const onSubmit = (formData, selectedMedia, status) => {
      // close publishmodal if open
      showPublishModal.value = false;
      // VALIDATION
      if (status == "active") {
        validate(
          {
            ...formData,
            selectedMedia:
              selectedMedia ||
              props.initialData?.attributes.Cover?.data?.attributes.url,
          },
          {
            onAccept: () => props.handleSubmit(formData, selectedMedia, status),
            onDecline: () => {
              console.log("toast");
              toast.error(
                "Még néhány információ hiányzik, kérlek nézd át az adatokat"
              );
              previewMode.value = false;
              emit("modechange", "form");
            },
          }
        );
      } else {
        props.handleSubmit(formData, selectedMedia, status);
      }
    };

    const addTimeToDate = (date, time) => {
      if (time) {
        const hour = parseInt(time.split(":")[0]);
        const minutes = parseInt(time.split(":")[1]);
        date = date
          .set("hour", hour)
          .set("minute", minutes)
          .set("second", 0)
          .set("millisecond", 0);
      }
      return date;
    };

    // SET THE END DATE IF IT IS BEFORE THE START DATE
    watch(formData.value, () => {
      if (formData.value.date.startDate) {
        rules.value["date.endDate"][1] = {
          validator: "date",
          params: {
            min: dayjs(formData.value.date.startDate).format("YYYY-MM-DD"),
          },
        };
      } else {
        rules.value["date.endDate"][1] = {
          validator: "date",
          params: {
            min: dayjs()
              .add(props.config.attributes.EventCreationDayPadding, "day")
              .format("YYYY-MM-DD"),
          },
        };
      }
    });

    const getPriceWatcher = () => {
      const field = activePriceField.value
          ? activePriceField.value.split(".")
          : [];
      if (field[1] === "gross") {
        if (field[0]) {
          formData.value.price[field[0]].net = getNet(
            formData.value.price[field[0]]
          );
          // detailed displayed price
          formData.value.price.displayed = calculateDetailedDisplayedPrice();
        } else {
          formData.value.price.net = getNet(formData.value.price);
          // displayed price
          formData.value.price.displayed = calculateDisplayedPrice(
            [formData.value.price]
          );
        }
      } else if (field[1] === "net") {
        if (field[0]) {
          formData.value.price[field[0]].gross = getGross(
            formData.value.price[field[0]]
          );
          // detailed displayed price
          formData.value.price.displayed = calculateDetailedDisplayedPrice();
        } else {
          formData.value.price.gross = getGross(formData.value.price);
          // displayedPrice
          formData.value.price.displayed = calculateDisplayedPrice(
            [formData.value.price]
          );
        }
      }
    };

    const getPriceCheckWatcher = (type) => {
      if (!formData.value.price[type].check) {
        const field = activePriceField.value
          ? activePriceField.value.split(".")
          : [];
        if (field[0] !== type) {
          formData.value.price[type].gross = null;
          formData.value.price[type].net = null;
        } else if (field[1] === "net") {
          formData.value.price[type].net = null;
        } else if (field[1] === "gross") {
          formData.value.price[type].gross = null;
        }
      }
    };

    const onBlur = () => {
      let start = formData.value.date.startDate
        ? dayjs(formData.value.date.startDate)
        : undefined;
      let end = formData.value.date.endDate
        ? dayjs(formData.value.date.endDate)
        : undefined;

      start =
        formData.value.date.startTime && start
          ? addTimeToDate(start, formData.value.date.startTime)
          : start;
      end =
        formData.value.date.endTime && end
          ? addTimeToDate(end, formData.value.date.endTime)
          : end;
      //date
      if (start && (!end || start > end)) {
        formData.value.date.endDate = formData.value.date.startDate;
        if (formData.value.date.startTime && end) {
          end = addTimeToDate(end, formData.value.date.startTime);
          formData.value.date.endTime = end.format("HH:mm");
        }
      }
    };

    watchEffect(() => {
      // have to decompose it to register the change
      // try {
      //   validate(formData.value);
      // } catch (e) {
      //   console.log(e);
      // }
      props.handleChange(
        {
          ...formData.value,
        },
        selectedMedia.value
      );
    });

    return {
      formData,
      handleLocationChange,
      handleRemoveCover,
      extras,
      loadingExtras,
      tags,
      loadingTags,
      uploadMediaInputRef,
      selectedMedia,
      selectedMediaPreviewUrl,
      handleChangeMedia,
      previewMode,
      onSubmit,
      validate,
      validationErrors,
      showPublishModal,
      rules,
      editor,
      editorWines,
      dayjs,
      onBlur,
      limit,
      activePriceField,
      calculateDisplayedPrice,
      calculateDetailedDisplayedPrice,
      getPriceWatcher,
      getPriceCheckWatcher,
      floatingMenu
    };
  },
};
</script>

<style scoped>
.floating-menu {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 10;
}
.event-input-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
}
.event-input-container .input,
textarea {
  cursor: text;
  margin-right: 8px;
  resize: vertical;
}
.event-input-container label {
  width: 250px;
  font-family: Signika;
  font-weight: 600;
  font-size: 14px;
}
.event-input-container .input-container {
  flex: 1;
}
.checkbox-container {
  display: inline-block;
  width: 50%;
}
.optional {
  color: #959595;
  font-weight: 500;
  font-size: 12px;
}
.tag {
  font-family: Barlow;
  font-weight: 600;
  font-size: 11px;
  padding: 8px 12px;
  text-transform: uppercase;
  background-color: var(--color-grey-04);
  border-radius: 100px;
  margin: 2px;
  float: left;
}
.empty {
  color: #959595;
}
.fade {
  opacity: 0.3;
}

.bottom-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 20px 32px;
  background-color: var(--color-blue-03);
  width: calc(100vw - 268px);
  z-index: 100;
}

.character-count {
  font-family: Signika;
  float: right;
}

.detailed-checkbox {
  min-width: 100px;
}
</style>

<style>
/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #9e9e9e;
  pointer-events: none;
  height: 0;
}
</style>