<template>
  <modal-component @close="$emit('close')">
    <div
      style="
        padding: 32px 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      "
    >
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="64" height="64" rx="32" fill="#6CA32F"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M47.9878 18.8707C48.6112 19.4162 48.6744 20.3638 48.1289 20.9873L27.1289 44.9873C26.8555 45.2997 26.4648 45.4849 26.0499 45.4987C25.635 45.5125 25.2329 45.3538 24.9393 45.0602L15.9393 36.0602C15.3536 35.4744 15.3536 34.5247 15.9393 33.9389C16.5251 33.3531 17.4749 33.3531 18.0607 33.9389L25.9269 41.8051L45.8711 19.0118C46.4167 18.3883 47.3643 18.3251 47.9878 18.8707Z" fill="white"/>
      </svg>
      <div variant="page-title">Foglalás jóváhagyása</div>
      <div variant="list-item">Jóváhagyást követően e-mailben tájékoztatjuk a vendéget, hogy a foglalása sikeres volt.</div>
      <div class="buttons">
        <button variant="white" @click="$emit('close')">Vissza</button>
        <button @click="approveBooking" :disabled="loading">Jóváhagyom</button>
      </div>
    </div>
  </modal-component>
</template>

<script>
import { ref } from 'vue';
import ModalComponent from '../ModalComponent.vue';
import booking from '../../api/booking';

export default {
  name: 'BookingUnderApprovalApproveModal',
  components: {
    ModalComponent,
  },
  emits: ['close','approve'],
  props: {
    booking: {
      type: Object,
      required: true,
    }
  },
  setup(){
    const loading = ref(false)

    return {
      loading,
    }
  },
  data() {
    return {
      // Your component's data goes here
    };
  },
  methods: {
    async approveBooking(){
      this.loading = true
      await booking.update({data: {Status: "approved"}}, this.booking.id)
      this.loading = false
      this.$emit('approve')
    }
    // Your component's methods go here
  },
  computed: {
    // Your computed properties go here
  },
  mounted() {
    // Code to run when the component is mounted goes here
  },
};
</script>

<style scoped>
.buttons {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.buttons button {
  width: 40%
}
/* Your component's styles go here */
</style>