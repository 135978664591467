import reportApi from "../api/report";
import eventApi from "../api/event";
import { ref } from "vue";
import { useToast } from "vue-toastification";
import { onIonViewDidEnter } from "@ionic/vue";

function useDashboard(wineryId, period, startAt) {
  const loading = ref(true);
  const report = ref(null);
  const upcomingEvents = ref(null);
  const recentDrafts = ref(null);
  const toast = useToast();

  const copyEvent = async (event, config, cb) => {
    loading.value = true;
    const res = await eventApi.copy(event, wineryId, config);
    if (res.ok) {
      loading.value = false;
      cb && cb(res.data.data);
    } else {
      toast.error('Hiba történt a másolásnál, próbáld újra.')
      loading.value = false;
    }
  };

  const getLatestUnreadReport = async () => {
    const res = await reportApi.getLatestUnread(wineryId, period);
    if (res.ok) {
      report.value = res.data.data;
    }
    return res;
  };

  const getUpcomingEvents = async () => {
    const res = await eventApi.getUpcoming(wineryId, startAt);
    if (res.ok) {
      upcomingEvents.value = res.data.data;
    }
  };

  const getRecentDrafts = async () => {
    const res = await eventApi.getRecentDrafts(wineryId);
    if (res.ok) {
      recentDrafts.value = res.data.data;
    }
  };

  onIonViewDidEnter(async () => {
    await getLatestUnreadReport();
    await getUpcomingEvents();
    await getRecentDrafts();
  })

  return {
    loading,
    report,
    getLatestUnreadReport,
    upcomingEvents,
    getUpcomingEvents,
    recentDrafts,
    getRecentDrafts,
    copyEvent,
  };
}

export default useDashboard;
