import { onMounted, ref } from "vue";
import wineryApi from "../api/winery";

function useWinery(wineryId) {
  const loading = ref(true);
  const winery = ref(null);

  const getWinery = async () => {
    loading.value = true;
    const res = await wineryApi.getById(wineryId);
    if (res.ok) {
      winery.value = res.data.data;
    } else {
      console.log(res);
    }
    loading.value = false;
  };

  onMounted(getWinery);

  return {
    loading,
    winery,
    getWinery,
  };
}

export default useWinery;
