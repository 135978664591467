<template>
  <ion-page>
    <ion-content>
      <div class="login-background">
        <loading-screen v-if="loadingLogin" />
        <LoginHeader />
        <NPForm :handleSubmit="handleSubmit" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import NPForm from "./NPForm.vue";
import LoginHeader from "../../components/LoginHeader.vue";
import { useRoute, useRouter } from "vue-router";
import useAuth from "../../hooks/useAuth";
import LoadingScreen from "../../components/LoadingScreen.vue";
import { useToast } from 'vue-toastification';

export default {
  name: "NewPasswordPage",
  components: {
    IonPage,
    IonContent,
    NPForm,
    LoginHeader,
    LoadingScreen,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { code } = route.query;
    const { login,resetPassword, loading: loadingLogin } = useAuth();
    const toast = useToast()

    const handleSubmit = async (e, formData, email) => {
      // TODO
      const res = await resetPassword({
        code,
        password: formData.password,
        passwordConfirmation: formData.passwordAgain,
      });
      if (res.ok) {
        await login(
          { identifier: res.data.user?.email || email, password: formData.password },
          (res) => {
            router.push({
              name: "WinerySelectorPage",
              params: { userId: res.data.user.id },
            });
          }
        );
      } else {
        toast.error('A link már nem érvényes')
      }
    };

    return {
      handleSubmit,
      loadingLogin,
    };
  },
};
</script>

<style>
</style>