<template>
  <div class="background">
    <!-- BACKDROP -->
    <div
      @click="toggleOptionsVisible(false)"
      v-if="optionsVisible"
      class="backdrop"
    ></div>

    <!-- MAIN CONTAINER -->
    <div @click.self="toggleOptionsVisible(true)" class="container">
      <!-- INPUT FIELD -->
      <div><input
        placeholder="Kattints ide és válassz a listából!"
        @click.self="toggleOptionsVisible(true)"
        type="text"
        v-model="newOption"
      /></div>
      <!-- SELECTED ITEMS -->
      <div v-if="value && value.length > 0">
        <hr style="width: 100%"/>
      </div>
      <div class="chips">
        <div class="chip" variant="tag" v-for="v in value" :key="v.value">
          {{ v.label }}
          <span class="remove-icon" @click="handleRemoveOption(v)"
            ><times-icon
          /></span>
        </div>
      </div>
      
    </div>
    <!-- OPTIONS -->
    <div v-if="optionsVisible" class="options">
      <div
        class="option"
        variant="table-head-button-label"
        v-for="option in availableOptions"
        :key="option.value"
        @click.self="handleSelectOption(option)"
      >
        {{ option.label }}
      </div>
      <!-- ADD NEW OPTION IF ALLOWED -->
      <button
        v-if="canAddNew && availableOptions?.length < 1 && newOption !== ''"
        @click="handleAddNewOption"
      >
        + címke létrehozása
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import { computed } from "@vue/runtime-core";
import { TimesIcon } from "@/assets/icons";
export default {
  name: "MultiSelect",
  props: ["value", "options", "onChange", "canAddNew"],
  components: {
    TimesIcon,
  },
  setup(props) {
    const newOption = ref("");
    const optionsVisible = ref(false);

    const handleSelectOption = (option) => {
      const selectedValues = props.value.map((val) => val.value);
      if (!selectedValues.includes(option.value)) {
        props.onChange([...props.value, option]);
      }
    };

    const handleRemoveOption = (option) => {
      props.onChange([
        ...props.value.filter((val) => val.value !== option.value),
      ]);
    };

    const handleAddNewOption = () => {
      // TODO parent component should handle this logic i think
      props.onChange([
        ...props.value,
        {
          value: newOption.value.replace(/\s+/g, "").toLowerCase(),
          label: newOption.value,
        },
      ]);
      newOption.value = "";
    };

    const availableOptions = computed(() => {
      return props.options
        ?.map((opt) => {
          const selectedValues = props.value.map((val) => val.value);
          if (
            !selectedValues.includes(opt.value) &&
            newOption.value.length !== "" &&
            opt.label.includes(newOption.value)
          ) {
            return opt;
          } else {
            return null;
          }
        })
        ?.filter((opt) => !!opt);
    });

    const toggleOptionsVisible = (val) => {
      optionsVisible.value = val;
    };

    return {
      newOption,
      handleAddNewOption,
      handleSelectOption,
      handleRemoveOption,
      availableOptions,
      toggleOptionsVisible,
      optionsVisible,
    };
  },
};
</script>

<style scoped>
.background {
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  /* background: rgba(255, 255, 255, 0.5); */
  background: transparent;
}
.container {
  border: 1px solid var(--color-blue-04-3);
  border-radius: 4px;
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
}
input {
  border: none;
  min-width: 200px;
  margin: 0;
  padding: 0;
  width: 100%;
  
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
}
input:focus {
  outline: none;
}
.options {
  background-color: var(--color-red-06);
  border-radius: 4px;
  margin-top: 5px;
  /* padding: 0px 16px 16px 0px; */
  padding: 8px 0px;
  position: absolute;
  z-index: 0;
  min-width: 350px;
  max-height: 350px;
  overflow-y: auto;
}
.option {
  padding: 8px 16px;
  cursor: pointer;
  color: var(--color-blue-01);
  border-radius: 4px;
}
.option:hover {
  background-color: var(--color-red-05);
}
.chips {
  display: block;
}
.chip {
  border-radius: 4px;
  background-color: var(--color-blue-04-1);
  padding: 4px;
  margin: 2px;
  color: var(--color-white);
  display: flex;
  align-items: center;
  float: left;
}
.remove-icon {
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-left: 4px;
}
</style>