import { create } from "apisauce";
//import { useCookies } from "vue3-cookies";
import useCookies from "../hooks/useCookies";

import store from "../store";

console.log("api client", process.env.VUE_APP_CLIENT);

const apiClient = create({
  baseURL: process.env.VUE_APP_CLIENT,
});

const requestConfig = () => {
  const jwt = useCookies.get("jwt");
  if(jwt){
    return {
      headers: {
        Authorization: `Bearer ${useCookies.get("jwt")}`,
      },
    }
  } else {
    return {}
  }
};

apiClient.addMonitor(async(response) => {
  if(!response.ok) {
    if(response.status === 401) {
      console.log('logout')
      try{
        //TODO ez itt elszáll a pcsába
        const me = store.getters['me/getMe'];
        console.log(me)
        if(me){
          store.commit('me/setMe',null);
          useCookies.remove("jwt");
          window.location.reload();
        }
        
      } catch(err){
        console.log(err)
      }
    } else if(response.status === 403) {
      window.location = `/not-found?from=${response.config.url}`
    }
  }
})

apiClient.addRequestTransform(request => {
  request.params = {
    ...request.params,
    as: 'wineryadmin'
  }
})

export default apiClient;
export { requestConfig };
