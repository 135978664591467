<template>
  <div style="flex: 1">
    <div variant="i" class="input location-input" @click="toggleModal">
      {{ selectedWinery.Title }}
      <span style="color: var(--color-grey-03); margin-left: 8px">
        {{ selectedWinery.Address }}</span
      >
    </div>
    <input-error :error="error" v-if="error" />
    <!-- <Input :noMargin="true" :value="selectedWinery.name" /> -->
  </div>
  <Modal v-if="visible" @close="toggleModal">
    <div style="padding: 32px 24px">
      <div variant="page-title" style="margin-bottom: 16px">
        Helyszín beállítása
      </div>
      <hr />
      <div variant="thbl" style="margin: 12px 0px">Helyszíneim</div>
      <div
        v-for="location in locations.map((loc) => ({
          ...loc,
          Title: loc.Title || wineryName,
          LocationId: loc.id,
        }))"
        :key="location.LocationId"
        class="location-item"
      >
        <div>
          <div variant="navigation-semibold">{{ location.Title }}</div>
          <div variant="paragraph-xxsmall">{{ location.Address }}</div>
        </div>
        <button variant="success" icon @click="handleSelectLocation(location)">
          <check-icon />
        </button>
      </div>

      <!-- UNIQUE -->
      <hr />
      <div variant="thbl" style="margin: 12px 0px">
        Másik helyszín megadása
      </div>
      <form @submit.prevent="onSubmit">
        <div class="input-container">
          <Input
            type="text"
            v-model="formData.Title"
            placeholder="Helyszín elnevezése"
          />
        </div>
        <div class="input-container">
          <Input
            type="text"
            v-model="formData.Address"
            placeholder="Cím megadása"
          />
        </div>
        <button :disabled="!formData.Title || !formData.Address" type="submit">
          Beállítás
        </button>
      </form>
    </div>
  </Modal>
</template>

<script>
import { ref } from "@vue/reactivity";
import Modal from "../../components/ModalComponent.vue";
import Input from "../../components/InputComponent.vue";
import InputError from "../../components/InputError.vue";
import { CheckIcon } from "../../assets/icons";
export default {
  components: {
    Modal,
    Input,
    CheckIcon,
    InputError,
  },
  props: ["selectedWinery", "handleSubmit", "locations", "error", "wineryName"],
  setup(props) {
    const visible = ref(false);

    const toggleModal = () => {
      visible.value = !visible.value;
    };

    const onSubmit = () => {
      props.handleSubmit(formData.value);
      toggleModal();
    };

    const handleSelectLocation = (location) => {
      props.handleSubmit(location);
      toggleModal();
    };

    const formData = ref({
      Title: !props.selectedWinery.LocationId ? props.selectedWinery.Title : "",
      Address: !props.selectedWinery.LocationId
        ? props.selectedWinery.Address
        : "",
      LocationId: null,
    });

    return {
      formData,
      visible,
      toggleModal,
      onSubmit,
      handleSelectLocation,
    };
  },
};
</script>

<style scoped>
.info {
  background: #fff;
  padding: 16px;
}
.location-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  background-color: var(--color-grey-05);
  padding: 8px 12px;
  border-radius: 8px;
}
.location-input {
  cursor: pointer;
  width: 100%;
}
</style>