import { onMounted, onUnmounted, ref } from "vue"

function useScreen(){
  const height = ref(null);
  const width = ref(null);
  const maxMobileWidth = 768;

  onMounted(()=>{
    height.value = window.innerHeight;
    width.value = window.innerWidth;
  })

  onUnmounted(()=>{

  })

  function isMobile(){
    return width.value < maxMobileWidth
  }

  return {
    height, width, maxMobileWidth, isMobile
  }
}

export default useScreen