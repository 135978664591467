<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 1.625C3.31195 1.625 1.125 3.81195 1.125 6.5C1.125 9.18805 3.31195 11.375 6 11.375C8.68805 11.375 10.875 9.18805 10.875 6.5C10.875 3.81195 8.68805 1.625 6 1.625ZM7.76508 7.73492C7.80137 7.7694 7.83038 7.81079 7.85042 7.85666C7.87046 7.90252 7.88112 7.95194 7.88176 8.00199C7.8824 8.05204 7.87301 8.10171 7.85416 8.14807C7.8353 8.19444 7.80735 8.23656 7.77195 8.27195C7.73656 8.30735 7.69444 8.3353 7.64807 8.35416C7.60171 8.37301 7.55204 8.3824 7.50199 8.38176C7.45194 8.38112 7.40252 8.37046 7.35666 8.35042C7.31079 8.33038 7.2694 8.30137 7.23492 8.26508L6 7.03039L4.76508 8.26508C4.69418 8.33244 4.59977 8.36943 4.50199 8.36818C4.4042 8.36693 4.31077 8.32753 4.24162 8.25838C4.17247 8.18923 4.13307 8.0958 4.13182 7.99801C4.13057 7.90023 4.16756 7.80582 4.23492 7.73492L5.46961 6.5L4.23492 5.26508C4.16756 5.19418 4.13057 5.09977 4.13182 5.00199C4.13307 4.9042 4.17247 4.81077 4.24162 4.74162C4.31077 4.67247 4.4042 4.63307 4.50199 4.63182C4.59977 4.63057 4.69418 4.66756 4.76508 4.73492L6 5.96961L7.23492 4.73492C7.30582 4.66756 7.40023 4.63057 7.49801 4.63182C7.5958 4.63307 7.68923 4.67247 7.75838 4.74162C7.82753 4.81077 7.86693 4.9042 7.86818 5.00199C7.86943 5.09977 7.83244 5.19418 7.76508 5.26508L6.53039 6.5L7.76508 7.73492Z"
      fill="#959595"
    />
  </svg>
</template>
