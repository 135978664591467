<template>
  <ion-page>
    <ion-content>
      <LoadingScreen v-if="loadingMe" />
      <SidebarNavigation :hideLinks="true">
        <div class="content-container">
          <WineryPicker
            v-if="!loadingMe && me"
            :wineries="me?.Wineries"
            :handleWineryClick="handleWineryClick"
          />
        </div>
      </SidebarNavigation>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import WineryPicker from "./WineryPicker.vue";
import LoadingScreen from "../../components/LoadingScreen.vue";
import SidebarNavigation from "../../components/SidebarNavigation.vue";
import useMe from "../../hooks/useMe";
import { useRouter } from "vue-router";
import { watchEffect } from "@vue/runtime-core";

export default {
  name: "WinerySelectorPage",
  components: {
    IonPage,
    IonContent,
    WineryPicker,
    LoadingScreen,
    SidebarNavigation,
  },
  setup() {
    const { me, loading: loadingMe } = useMe();
    const router = useRouter();

    const handleWineryClick = (winery) => {
      router.push(`/users/${me.value.id}/wineries/${winery.id}`);
    };

    watchEffect(() => {
      // REDIRECT TO DASHBOARD IF THERE IS ONLY ON
      if (me.value && me.value.Wineries.length == 1) {
        router.replace({
          name: "DashboardPage",
          params: { userId: me.value.id, wineryId: me.value.Wineries[0].id },
        });
      }
    });

    return {
      handleWineryClick,
      me,
      loadingMe,
    };
  },
};
</script>

<style>
.content-container {
  background-color: var(--color-blue-04-1);
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>