<template>
  <table>
    <thead>
      <tr>
        <th>Foglalás ID</th>
        <th>Foglaló neve</th>
        <th>Foglalás dátuma</th>
        <th v-if="!event">Esemény név</th>
        <th>Résztvevők</th>
        <th>Státusz</th>
        <th></th>
      </tr>
    </thead>
    <tbody v-if="bookings?.length">
      <tr v-for="booking in bookings"  style="cursor: pointer" @click="handleBookingPage(booking.id)" :key="booking.attributes.NID">
        <td>{{ booking.attributes.NID }}</td>
        <td>{{ booking.attributes.WineAdventurer?.data?.attributes.LastName}} {{ booking.attributes.WineAdventurer?.data?.attributes.FirstName}}</td>
        <td>
          {{ booking.attributes.BookingDate ? formatDateAndTime(booking.attributes.BookingDate) : 'Nincs adat'}}
        </td>
        <!-- IF EVENT IS GIVEN DONT SHOW THE NAME -->
        <td v-if="!event">
          {{ booking.attributes.Event.data?.attributes.Name }}
        </td>
        <td>{{ booking.attributes.Participants }}</td>
        <td><Chip :variant="booking.attributes.Status" /></td>
        <!-- ACTION BUTTONS -->
        <td>
          <div style="display: flex">
            <button
              :disabled="!verificationEnabled(booking, event)"
              :data-tooltip="verificationEnabled(booking, event) ? 'Megjelent':'A verifikáció még nem aktív'"
              v-if="booking.attributes.Status == 'successful'"
              variant="success"
              icon
              @click.stop="() => (toBeAttendedBooking = booking)"
            >
              <check-icon />
            </button>
            <button
              :disabled="!verificationEnabled(booking, event)"
              :data-tooltip="verificationEnabled(booking, event)?'Nem jelent meg':'A verifikáció még nem aktív'"
              v-if="booking.attributes.Status == 'successful'"
              variant="dark"
              icon
              @click.stop="() => (toBeNotAttendedBooking = booking)"
            >
              <ban-icon />
            </button>
            <button
              @click.stop="() => (toBeEditedBooking = booking)"
              icon
              variant="white"
              v-if="isEditVisible(booking, event)"
              data-tooltip="Szerkesztés"
            >
              <edit-icon />
            </button>
            <button
              data-tooltip="Megtekintés"
              @click.stop="handleBookingPage(booking.id)"
              variant="white"
              icon
            >
              <chevron-right-icon />
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <AttendedModal
    :visible="!!toBeAttendedBooking"
    :booking="toBeAttendedBooking"
    :handleClose="() => (toBeAttendedBooking = null)"
    :handleStatusUpdate="handleBookingUpdate"
  />
  <NotAttendedModal
    :visible="!!toBeNotAttendedBooking"
    :booking="toBeNotAttendedBooking"
    :handleClose="() => (toBeNotAttendedBooking = null)"
    :handleStatusUpdate="handleBookingUpdate"
  />
  <EditStatusModal
    :visible="!!toBeEditedBooking"
    :booking="toBeEditedBooking"
    :handleClose="() => (toBeEditedBooking = null)"
    :handleStatusUpdate="handleBookingUpdate"
  />
</template>

<script>
import {
  CheckIcon,
  ChevronRightIcon,
  BanIcon,
  EditIcon,
} from "../../assets/icons";
import Chip from "../../components/ChipComponent.vue";
import dayjs from "dayjs";
import { useRouter } from "vue-router";
import useFormatDate from "../../hooks/useFormatDate";
import AttendedModal from "./AttendedModal.vue";
import NotAttendedModal from "./NotAttendedModal.vue";
import EditStatusModal from "./EditStatusModal.vue";
import { ref } from "@vue/reactivity";
import bookingApi from "../../api/booking";
export default {
  props: ["bookings", "onUpdateFinished", "event"],
  components: {
    BanIcon,
    EditIcon,
    CheckIcon,
    ChevronRightIcon,
    Chip,
    AttendedModal,
    NotAttendedModal,
    EditStatusModal,
  },
  setup(props) {
    const { params } = useRouter();
    const router = useRouter();
    const { formatDateAndTime } = useFormatDate();
    const toBeAttendedBooking = ref(null);
    const toBeNotAttendedBooking = ref(null);
    const toBeEditedBooking = ref(null);
    const loading = ref(false);

    const isEditVisible = (booking, event) => {
      const startTime = event
        ? event.attributes.StartAt
        : booking.attributes.Event?.data?.attributes.StartAt;
      const endTime = event
        ? event.attributes.EndAt
        : booking.attributes.Event?.data?.attributes.StartAt;

      const eventStartAt = dayjs(startTime);
      const eventEndAt = dayjs(endTime)
      const now = dayjs();
      if (
        now < eventEndAt.add(24,"hours") && now > eventStartAt.add(-1, "hours") &&
        booking.attributes.Status !== "successful" &&
        booking.attributes.Status !== "deleted" && 
        booking.attributes.Status !== "cancelled"
      ) {
        return true;
      } else {
        return false;
      }
    };

    const verificationEnabled = (booking, event) => {
      if(!booking) return
      const eventStartAt = dayjs(event
        ? event.attributes.StartAt : booking.attributes.Event.data?.attributes.StartAt);
      const eventEndAt = dayjs(event
        ? event.attributes.EndAt : booking.attributes.Event.data?.attributes.EndAt);
      const now = dayjs();

      if(booking.attributes.Status === "successful" && now < eventEndAt.add(24,"hours") && now > eventStartAt.add(-1, "hours")){
        return true;
      } else {
        return false;
      }
    }

    const handleBookingUpdate = async (bookingId, status) => {
      const res = await bookingApi.update(
        { data: { Status: status } },
        bookingId
      );
      if (res.ok) {
        toBeAttendedBooking.value = null;
        toBeNotAttendedBooking.value = null;
        toBeEditedBooking.value = null;
        props.onUpdateFinished && props.onUpdateFinished();
      }
    };

    const handleBookingPage = (bookingId) =>
      router.push({
        name: "BookingDetailsPage",
        params: { ...params, bookingId },
      });

    return {
      isEditVisible,
      handleBookingPage,
      formatDateAndTime,
      toBeAttendedBooking,
      toBeNotAttendedBooking,
      toBeEditedBooking,
      handleBookingUpdate,
      loading,
      verificationEnabled,
    };
  },
};
</script>

<style>
</style>