import { onIonViewDidEnter } from "@ionic/vue";
import { ref } from "vue";
import bookingApi from "../api/booking";

function useBooking(bookingId) {
  const loading = ref(true);
  const booking = ref(null);

  const update = async (data) => {
    const res = await bookingApi.update(data, bookingId);
    if (res.ok) {
      console.log("ok");
      console.log(res.data.data);
    } else {
      console.log(res.data);
    }
    return res;
  };

  const getBooking = async () => {
    const res = await bookingApi.getById(bookingId);
    if (res.ok) {
      booking.value = res.data.data;
      loading.value = false;
    } else {
      console.log(res.data);
      loading.value = false;
    }
  };

  onIonViewDidEnter(() => bookingId && getBooking());

  return {
    loading,
    booking,
    update,
    getBooking,
  };
}

export default useBooking;
