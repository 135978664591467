import client, { requestConfig } from "./client";

const endpoint = "/api/upload";

const upload = (data) => client.post(endpoint, data, requestConfig());

const remove = (id) =>
  client.delete(endpoint + "/files/" + id, {}, requestConfig());

export default {
  upload,
  remove,
};
