<template>
  <table>
    <thead>
      <tr>
        <th>Esemény ID</th>
        <th>Esemény dátuma</th>
        <th>Név</th>
        <th class="large-desktop">Eladott jegyszám /<br>Max. férőhely</th>
        <th>Helyszín</th>
        <th>Státusz</th>
        <th></th>
      </tr>
    </thead>
    <tbody v-if="events?.length">
      <tr style="cursor: pointer" @click="()=>openEvent(event)" v-for="event in events" :key="event.attributes.NID" :class="event.attributes.Status == 'deleted'?'deleted':''">
        <td>
          <span v-if="!['active', 'ongoing'].includes(event.attributes.Status)">{{ event.attributes.NID }}</span>
          <span v-else style="display: flex; align-items:center;">
            <a @click.stop="" :href="`https://www.orszagosbortura.hu/esemenyek/${event.attributes.NID}`" target="_blank" >{{ event.attributes.NID }}</a><copy-button @click.stop="" :tooltipText="'jegyvásárlási link másolása'" tooltipAlign="right" :text="`https://www.orszagosbortura.hu/esemenyek/${event.attributes.NID}`"/>
          </span>
        </td>
        <td>
          {{ event.attributes.StartAt ? 
              formatDateAndTime(event.attributes.StartAt):
              '(üres kezdődátum)'
              
          }}-{{
            event.attributes.EndAt ? 
            formatDateAndTime(event.attributes.EndAt):
            '(üres végdátum)'
          }}
        </td>
        <td>
          {{ event.attributes.Name }}
        </td>
        <td class="large-desktop">
          <div v-if="event.attributes.Status != 'draft'">{{ event.attributes.Booked }}/{{ event.attributes.MaxHeadCount }}</div>
          <div v-else>-</div>
        </td>
        <td>
          {{ event.attributes.LocationName }}
        </td>
        <td>
          <Chip :variant="event.attributes.Status" />
        </td>
        <!-- ACTION BUTTONS -->
        <td>
          <div style="display: flex; position: relative">
            <!-- DELETE BUTTON -->
            <button
              v-if="
                !['ended','deleted','ongoing'].includes(event.attributes.Status) &&
                !event.attributes.EventCancellationRequest.data
              "
              data-tooltip="Törlés"
              variant="white"
              icon
              @click.stop="() => (toBeDeletedEvent = event)"
            >
              <trash-icon />
            </button>

            <!-- INFO BUTTON -->
            <button
              v-if="
                event.attributes.Status == 'active' &&
                event.attributes.EventCancellationRequest.data
              "
              variant="transparent"
              icon
              data-tooltip="Törlési kérelem elküldve."
              @click.stop="()=>({})"
            >
              <!-- <info-icon /> -->
              <info-icon-dark />
            </button>
            <!-- COPY BUTTON -->
            <button
              data-tooltip="Másolás"
              variant="white"
              icon
              @click.stop="() => $emit('copy', event)"
            >
              <copy-icon />
            </button>

            <!-- EDIT BUTTON -->
            <button
              data-tooltip="Szerkesztés"
              v-if="event.attributes.Status == 'draft'"
              @click.stop="editEvent(event.id)"
              variant="white"
              icon
            >
              <edit-icon />
            </button>

            <!-- EDIT ACTIVE EVENT BUTTON -->
            <button
              data-tooltip="Megtekintés"
              icon
              variant="white"
              v-if="event.attributes.Status !== 'draft'"
              @click.stop="editActiveEvent(event.id)"
            >
              <chevron-right-icon />
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <delete-request-modal
    :event="toBeDeletedEvent"
    v-if="toBeDeletedEvent"
    @close="handleDeleteModalClose"
    @delete="$emit('delete')"
  />
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRoute, useRouter } from "vue-router";
import {
  TrashIcon,
  CopyIcon,
  EditIcon,
  ChevronRightIcon,
  InfoIconDark,
} from "../../assets/icons";
import Chip from "../../components/ChipComponent.vue";
import DeleteRequestModal from "./DeleteRequestModal.vue";
import useFormatDate from "../../hooks/useFormatDate";
import CopyButton from '../../components/CopyButton.vue';
export default {
  props: ["events"],
  emits: ["delete", "copy"],
  components: {
    CopyIcon,
    TrashIcon,
    EditIcon,
    ChevronRightIcon,
    Chip,
    DeleteRequestModal,
    InfoIconDark,
    CopyButton,
  },
  setup() {
    const route = useRoute();
    const params = route.params;
    const router = useRouter();
    const { formatDateAndTime } = useFormatDate();

    const toBeDeletedEvent = ref(null);

    const editEvent = (eventId) =>
      router.push({ name: "EventPage", params: { ...params, eventId } });

    const editActiveEvent = (eventId) =>
      router.push({ name: "ActiveEventPage", params: { ...params, eventId } });

    const handleDeleteModalClose = () => {
      toBeDeletedEvent.value = null;
      // router.go();
    };

    const openEvent = (event) => {
      event.attributes.Status == "draft"
        ? editEvent(event.id)
        : editActiveEvent(event.id);
    };

    return {
      editEvent,
      editActiveEvent,
      toBeDeletedEvent,
      handleDeleteModalClose,
      formatDateAndTime,
      openEvent,
    };
  },
};
</script>

<style>
.deleted td{
  color: #959595;
}

@media screen and (max-width: 1200px) {
  .large-desktop {
    display: none;
  }  
}
</style>