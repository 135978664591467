<template>
  <div class="container">
    <loading-screen v-if="loading"/>
    <!-- TERMS AND PRIVACY -->
    <div class="card" v-if="!showNeedToAccept">
      <div variant="page-title" style="margin-bottom: 16px">
        Nem találtuk a keresett {{ entityName }}!
      </div>
      <div class="info" variant="paragraph-normal">
        {{entityDescription}}
      </div>
      <div class="info" variant="small-text" style="margin-bottom: 32px;margin-top: 32px;">
        <info-icon />
        Kérdés esetén lépj kapcsolatba az Országor Bortúra csapatával az alábbi e-mail címen: <a href="mailto:partner@orszagosbortura.hu">
          partner@orszagosbortura.hu
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { InfoIcon } from "../assets/icons";
import { ref } from "@vue/reactivity";
import { useRoute, useRouter } from 'vue-router';
import LoadingScreen from '../components/LoadingScreen.vue';
export default {
  components: { InfoIcon, LoadingScreen },
  setup() {
    const router = useRouter();
    const route = useRoute();

    let entityName = "elemet";
    let entityDescription = "";

    console.log(route.query)
    if(route.query?.from){
      if(route.query?.from.includes('complimentary-ticket')){
        entityName = 'tiszteletjegyet'
        entityDescription = "A beolvasott tiszteletjegy nem érvényes egyik eseményedre sem. Győződj meg róla, hogy a megfelelő felhasználóval jelentkeztél be a boradminba."
      }
    }

    const formData = ref({
      terms: false,
      privacy: false,
    });

    const handleBack = () => {
      router.replace('/')
    }

    return {
      formData,
      handleBack,
      route,
      entityName,
      entityDescription
    };
  },
};
</script>

<style scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: var(--color-blue-03);
}
.card {
  max-width: 800px;
  width: 100%;
  background-color: var(--color-white);
  padding: 48px 64px;
  border-radius: 8px;
  box-shadow: 0px 40px 80px rgba(96, 88, 143, 0.24),
    0px 16px 32px rgba(96, 88, 143, 0.16), 0px 8px 16px rgba(96, 88, 143, 0.16),
    0px 4px 4px rgba(96, 88, 143, 0.02);
  margin: auto;
}
.card-bottom {
  background-color: var(--color-red-06);
  width: calc(100% + 2 * 64px);
  margin-left: -64px;
  padding: 33px 64px;
  margin-bottom: -48px;
  border-radius: 0px 0px 8px 8px;
  margin-top: 64px;
  display: flex;
}
.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
  .card {
    padding: 24px 32px;
  }
}
</style>