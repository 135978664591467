<template>
  <div class="input-error" variant="paragraph-normal">
    <div v-for="(e, idx) in error" :key="idx">{{ e }}</div>
  </div>
</template>

<script>
export default {
  props: ["error"],
};
</script>

<style scoped>
.input-error {
  margin-top: 8px;
  border: 1px solid var(--color-red-03);
  background-color: var(--color-red-05);
  padding: 12px 16px;
  border-radius: 4px;
}
</style>