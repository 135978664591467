<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.11723 10.8126C2.97333 10.8123 2.83197 10.7746 2.70707 10.7031C2.42582 10.5438 2.25098 10.2344 2.25098 9.89853V3.10165C2.25098 2.76486 2.42582 2.45642 2.70707 2.29704C2.83495 2.22355 2.98021 2.18576 3.12769 2.18761C3.27517 2.18947 3.41944 2.23091 3.54543 2.30759L9.35441 5.78478C9.47548 5.86069 9.57528 5.96611 9.64446 6.09114C9.71364 6.21617 9.74993 6.35673 9.74993 6.49962C9.74993 6.64252 9.71364 6.78307 9.64446 6.90811C9.57528 7.03314 9.47548 7.13856 9.35441 7.21447L3.54449 10.6926C3.41556 10.7705 3.26789 10.812 3.11723 10.8126Z"
      fill="#6CA32F"
    />
  </svg>
</template>