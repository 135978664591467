<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 1.625C3.30773 1.625 1.125 3.80773 1.125 6.5C1.125 9.19227 3.30773 11.375 6 11.375C8.69227 11.375 10.875 9.19227 10.875 6.5C10.875 3.80773 8.69227 1.625 6 1.625ZM8.25 7.25H6C5.90054 7.25 5.80516 7.21049 5.73484 7.14017C5.66451 7.06984 5.625 6.97446 5.625 6.875V3.5C5.625 3.40054 5.66451 3.30516 5.73484 3.23484C5.80516 3.16451 5.90054 3.125 6 3.125C6.09946 3.125 6.19484 3.16451 6.26517 3.23484C6.33549 3.30516 6.375 3.40054 6.375 3.5V6.5H8.25C8.34946 6.5 8.44484 6.53951 8.51517 6.60983C8.58549 6.68016 8.625 6.77554 8.625 6.875C8.625 6.97446 8.58549 7.06984 8.51517 7.14017C8.44484 7.21049 8.34946 7.25 8.25 7.25Z"
      fill="#30BBEA"
    />
  </svg>
</template>