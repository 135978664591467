<template>
  <div
    class="container"
    :style="noMargin ? 'margin-bottom: 0px' : 'margin-bottom: 24px'"
  >
    <label
      variant="table-head-button-label"
      :style="hideLabel ? 'opacity: 0.0' : 'opacity: 1.0'"
      v-if="label"
      >{{ label }}
      <span class="optional" v-if="optional">(opcionális)</span></label
    >
    <div :disabled="disabled" class="input" @click="focusInput()">
      <div class="left-wrapper">
        <slot name="left" />
      </div>
      <input
        ref="inputRef"
        :value="modelValue"
        @input="updateValue"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
        :min="min"
        :max="max"
        @blur="$emit('blur')"
        @change="updateValue"
        @focus="$emit('focus')"
        :style="inputStyle || ''"
      />
      <cancel-icon
        v-if="withCancel"
        @click="resetValue()"
        class="cancel-icon"
      />
      <slot name="right" />
    </div>
    <InputError :error="error" v-if="error" />
  </div>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { CancelIcon } from "../assets/icons";
import InputError from "./InputError.vue";
export default {
  components: { CancelIcon, InputError },
  props: [
    "modelValue",
    "type",
    "placeholder",
    "label",
    "hideLabel",
    "optional",
    "withCancel",
    "noMargin",
    "disabled",
    "error",
    "min",
    "max",
    "inputStyle"
  ],
  emits: ['blur','focus'],
  setup(props, { emit }) {
    const inputRef = ref(null);
    const focusInput = () => {
      inputRef.value.focus();
    };
    const resetValue = () => emit("update:modelValue", null);
    const updateValue = (event) => 
      emit("update:modelValue", event.target.value);
    return {
      focusInput,
      inputRef,
      resetValue,
      updateValue,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
label {
  margin-bottom: 8px;
}
.optional {
  font-family: Signika;
  font-size: 14px;
  color: var(--color-grey-03);
  font-weight: 400;
}
.cancel-icon {
  width: 16px;
  height: 16px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
.cancel-icon:hover {
  width: 18px;
  height: 18px;
}
.left-wrapper {
  margin-right: 6px;
}
</style>