<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" rx="4" fill="#382E74" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.3293 3.62355C13.5371 3.80539 13.5581 4.12127 13.3763 4.32909L6.37629 12.3291C6.28516 12.4332 6.15495 12.495 6.01664 12.4996C5.87833 12.5042 5.7443 12.4512 5.64645 12.3534L2.64645 9.35339C2.45118 9.15813 2.45118 8.84155 2.64645 8.64629C2.84171 8.45102 3.15829 8.45102 3.35355 8.64629L5.97564 11.2684L12.6237 3.67059C12.8056 3.46277 13.1214 3.44171 13.3293 3.62355Z"
      fill="#F9E2D6"
    />
  </svg>
</template>