export default function usePackageLanguage() {
  function language(lang){
    const langMap = {
      'HU': 'Magyar',
      'EN': 'Angol',
      'DE': 'Német',
      'ES': 'Spanyol',
      'SK': 'Szlovák',
    }

    if(langMap[lang]){
      return `(${lang}) ${langMap[lang]}`;
    } else {
      return lang;
    }
  }

  return {
    language
  }
}