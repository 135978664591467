// initial state
//example: https://github.com/vuejs/vuex/blob/main/examples/classic/shopping-cart/store/modules/cart.js
import userApi from "../../api/user"

const state = () => ({
  data: null
})

// getters
const getters = {
  getMe(state){
    return state.data
  }
}

// actions
const actions = {
  async getMe ({ commit, state }) {
    if(state.data){
      return state.data
    } else {
      const resp =await userApi.getMe();
      if(resp.ok){
        commit('setMe',resp.data);
        return resp.data;
      } else {
        if(state.data){
          commit('setMe',null)
        }
        return null;
      }
    }   
  },
  async refresh ({commit,state}) {
    const resp =await userApi.getMe();
    if(resp.ok){
      commit('setMe',resp.data);
      return resp.data;
    } else {
      if(state.data){
        commit('setMe',null)
      }
      return null;
    }
  }
}

// mutations
const mutations = {
  setMe(state, me){
    state.data = me
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}