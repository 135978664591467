import { onIonViewDidEnter } from "@ionic/vue";
import { ref } from "vue";
import reportApi from "../api/report";

function useReports(wineryId) {
  const loading = ref(true);
  const reports = ref(null);

  const getReports = async (data) => {
    const res = await reportApi.get(wineryId, data);
    if (res.ok) {
      reports.value = res.data.data;
      loading.value = false;
    } else {
      console.log(res.data);
      loading.value = false;
    }
  };

  // The ReportsPage gets the date in a watchEffect
  // onMounted(() => getReports());
  onIonViewDidEnter(() => getReports())


  return {
    loading,
    reports,
    getReports,
  };
}

export default useReports;
