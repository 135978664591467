<template>
  <button
    :data-tooltip="tooltipText || 'Másolás vágólapra'"
    :data-tooltip-position="tooltipAlign || 'left'"
    icon
    variant="white" 
    @click="copyToClipboard(text)">
    <copy-icon :size="iconSize || 16"/>
  </button>
</template>

<script>
import { useToast } from 'vue-toastification';
import CopyIcon from '../assets/icons/CopyIcon.vue';
export default {
  components: { CopyIcon },
  props: ["text", "tooltipText", "tooltipAlign", "iconSize"],
  setup() {
    const {info} = useToast()

    function copyToClipboard(text) {
      if(navigator && navigator.clipboard){
        navigator.clipboard.writeText(text);
      } else {
        var textArea = document.createElement('textArea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
      }
      info("Vágólapra másolva")
    }

    return {
      copyToClipboard
    };
  }
}
</script>

<style lang="scss" scoped>

</style>