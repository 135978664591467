<template>
  <ion-page>
    <ion-content>
      <SidebarNavigation active="DashboardPage">
        <div class="content-container">
          <!-- TITLE -->
          <h1 class="title" variant="page-title">Kezdőlap</h1>

          <!-- REPORT READY -->
          <div v-if="report">
            <div class="report-ready" v-for="rep in report" :key="rep.id">
              <div style="display: flex; align-items: center">
                <ReportAltIcon />
                <div
                  style="margin-left: 8px; color: var(--color-white)"
                  variant="navigation-bold"
                >
                  Elkészült a {{ rep.attributes.Period }} havi elszámolási
                  riportod!
                </div>
              </div>
              <button @click="handleGoToReport(rep.id)" variant="white">
                Megnézem
              </button>
            </div>
          </div>

          <bookings-under-approval-list :wineryId="wineryId" />

          <!-- CARDS -->
          <div class="cards">
            <!-- EVENTS -->
            <div class="card-left">
              <h3 variant="navigation-bold">Közelgő eseményeid</h3>
              <div class="card-content">
                <event-item
                  v-for="event in upcomingEvents"
                  :key="event.id"
                  :event="event"
                  @oncopy="
                    copyEvent(event, config, (data) =>
                      $router.push({
                        name: 'EventPage',
                        params: { ...$router.params, eventId: data.id },
                      })
                    )
                  "
                />
              </div>
            </div>

            <!-- DRAFTS -->
            <div class="card-right">
              <h3 variant="navigation-bold">Legutóbbi piszkozataid</h3>
              <div class="card-content">
                <event-item
                  v-for="draft in recentDrafts"
                  :key="draft.id"
                  :event="draft"
                  :draft="true"
                />
              </div>
            </div>
          </div>
        </div>
      </SidebarNavigation>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import SidebarNavigation from "../../components/SidebarNavigation.vue";
import { ReportAltIcon } from "../../assets/icons";
import EventItem from "./EventItem.vue";
import useDashboard from "../../hooks/useDashboard";
import { useRoute, useRouter } from "vue-router";
import dayjs from "dayjs";
import useConfig from "../../hooks/useConfig";
import BookingsUnderApprovalList from '../../components/BookingApproval/BookingsUnderApprovalList.vue';

export default {
  name: "DashboardPage",
  components: {
    IonPage,
    IonContent,
    ReportAltIcon,
    SidebarNavigation,
    EventItem,
    BookingsUnderApprovalList
  },
  setup() {
    const { params } = useRoute();
    const router = useRouter();
    const wineryId = params.wineryId;

    const { loading, report, upcomingEvents, recentDrafts, copyEvent } =
      useDashboard(wineryId, dayjs().add(-1, "month").format("YYYY-MM"));

    const { loading: loadingConfig, config } = useConfig();

    const handleGoToReport = (reportId) =>
      router.push({
        name: "ReportDetailsPage",
        params: { ...params, reportId },
      });

    return {
      config,
      loadingConfig,
      loading,
      report,
      upcomingEvents,
      recentDrafts,
      handleGoToReport,
      copyEvent,
      wineryId
    };
  },
};
</script>

<style scoped>
.content-container {
  width: 100%;
  height: 100%;
  display: block;
  background-color: var(--color-red-06);
  /* display: flex;
  flex-direction: column;
  align-items: stretch; */
}
.title {
  background-color: var(--color-white);
  margin: 0px;
  padding: 24px 32px 24px 32px;
}
.report-ready {
  background-color: var(--color-blue-05);
  display: flex;
  padding: 16px 40px;
  align-items: center;
  justify-content: space-between;
}

.cards {
  display: flex;
  flex: 1;
  padding: 8px;
  justify-content: space-between;
}
.cards h3 {
  padding: 20px 24px;
  margin: 0;
  box-shadow: inset 0px -1px 0px #dddddd;
}
.cards .card-content {
  padding: 16px 24px;
}
.card-left {
  min-height: 100px;
  background-color: var(--color-white);
  flex: 3;
  margin-right: 8px;
}
.card-right {
  background-color: var(--color-white);
  min-height: 100px;
  flex: 2;
}
</style>