<template>
  <div class="card">
    <div>
      <div class="card-header">
        <div class="name-address">
          <div style="width: 100%">
            <div class="name-time">
              <div class="name" variant="h4">{{booking.attributes.Package?.data?.attributes?.Name}}</div>
              <vue-countdown v-if="timeRemaining() > 0" :time="timeRemaining()" v-slot="{ days, hours, minutes, seconds }">
                  <div class="time">
                    <span variant="h4" v-if="days > 0">{{ days }} nap </span>
                    <span variant="h4">{{ hours }}:{{ minutes > 9 ? minutes : `0${minutes}` }}:{{ seconds > 9 ? seconds : `0${seconds}` }}</span>
                  </div>
              </vue-countdown>
            </div>
            <div style="min-height: 40px">
              <location-icon />
              {{ booking.attributes.Package.data?.attributes.LocationName }}
                <span v-if="booking.attributes.Package.data?.attributes.LocationName"> - </span>
                <span>{{
                  booking.attributes.Package.data?.attributes.LocationAddress
                }}</span>
            </div>
          </div>
        </div>
        <hr class="divider" />
      </div>
      <div class="card-body">
        <table>
          <tr>
            <td class="row-name">Foglalt időpont:</td>
            <td variant="paragraph-normal" class="row-value">{{
              formatDates(
                `${booking.attributes.PackageAppointmentDate} ${booking.attributes.PackageAppointmentTime}`,
                dayjs(`${booking.attributes.PackageAppointmentDate} ${booking.attributes.PackageAppointmentTime}`).add(booking.attributes.Package.data?.attributes.Duration, "hour").toISOString(),
              )
            }}</td>
          </tr>
          <tr>
            <td class="row-name">Foglaló neve:</td>
            <td variant="paragraph-normal" class="row-value">{{ booking.attributes.WineAdventurer?.data.attributes.LastName}} {{ booking.attributes.WineAdventurer?.data.attributes.FirstName}}</td>
          </tr>
          <tr>
            <td class="row-name">Foglalt helyek:</td>
            <td variant="paragraph-normal" class="row-value">{{booking.attributes.Participants}} fő</td>
          </tr>
          <tr v-if="booking.attributes.PackageExtras.data?.length > 0">
            <td class="row-name">Extrák</td>
            <td variant="paragraph-normal" class="row-value"></td>
          </tr>
          <tr v-for="extra in booking.attributes.PackageExtras.data" :key="extra.id">
            <td style="padding-left: 16px;">{{extra.attributes.Name}}</td>
            <td variant="paragraph-normal" class="row-value row-name">{{extra.attributes.Quantity}}</td>
          </tr>
          <tr v-if="booking.attributes.PackageComment">
            <td class="row-name">Megjegyzés:</td><td></td>
          </tr>
          <tr v-if="booking.attributes.PackageComment">
            <td style="padding-left: 16px;" colspan="2">{{booking.attributes.PackageComment}}</td>
          </tr>
        </table>


        <!-- Card body content goes here -->
      </div>
    </div>
    <div class="card-footer">
      <div variant="paragraph-normal" class="row-value total-value">
        Fizetve
      </div>
      <!-- Card footer content goes here -->
      <div class="btn-container">
        <button variant="success" @click="$emit('approve')"><check-icon />Jóváhagyás</button>
        <button variant="dark" @click="$emit('reject')"><ban-icon /> Elutasítás</button>
      </div>
    </div>
  </div>
</template>

<script>
import { LocationIcon } from '../../assets/icons'
import BanIcon from '../../assets/icons/BanIcon.vue'
import CheckIcon from "../../assets/icons/CheckIcon.vue"
import useFormatDate from '../../hooks/useFormatDate'
import VueCountdown from '@chenfengyuan/vue-countdown';
import dayjs from 'dayjs'

export default {
  name: 'BookingUnderApprovalCard',
  components: { CheckIcon, BanIcon, LocationIcon, VueCountdown },
  props: {
    // Component props go here
    booking: {
      type: Object,
      required: true,
    }
  },
  emits: ['approve','reject'],
  setup(){
    const {formatDates} = useFormatDate()

    return {
      formatDates,
      dayjs
    }
  },
  // Component properties and methods go here
  methods: {
    timeRemaining(){
      return dayjs(this.booking?.attributes.BookingUnderApprovalTo).diff(dayjs())
    }
  }
}
</script>

<style scoped>
.card {
  border-radius: 24px;
  overflow: hidden;
  width: 342px;
  min-width: 342px;
  /* Card styles go here */
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: stretch;
}

.card-header {
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  margin: 16px;
  /* Card header styles go here */
}

.card-body {
  margin: 16px;
  margin-top: 8px;
  /* Card body styles go here */
}

.card-footer {
  margin: 16px;
  /* Card footer styles go here */
}
.btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  gap: 8px;
}
.btn-container button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.name-address {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.name-time {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
}

.time {
  color: #7B6CCC;
}

.name {
  height: 40px;
}
.info-icon {
  cursor: pointer;
}
.divider {
  border-top: 1px dashed #E5E2F5;
  width: 100%;
  background-color: white;
}

td {
  margin: 0px;
  margin-bottom: 4px;
  padding: 0px;
  padding-bottom: 6px;
  box-shadow: none;
}
td.row-value {
  text-align: right;
  
}

td.row-name {
  font-weight: bold;
}

.total-value {
  text-align: center;
  font-weight: 600;
  color: var(--color-red-03);
  font-size: 16px;
}
tr {
  border: none;
}
</style>