<template>
  <button 
    style="background: #ffffff00;"
    type="button"
    :data-tooltip="tooltipText || 'Beillesztés vágólapról'"
    :data-tooltip-position="tooltipAlign || 'left'"
    icon
    variant="white" 
    @click="paste()">
    <paste-icon />{{clipboardText}}
  </button>
</template>

<script>
import { ref } from 'vue';
// import { useToast } from 'vue-toastification';

import PasteIcon from '../assets/icons/PasteIcon.vue';
export default {
  components: { PasteIcon },
  props: ["tooltipText", "tooltipAlign"],
  emits: ["paste"],
  setup() {
    const navigator = ref(null)
    const clipboardText = ref('')

    return {
      navigator,
      clipboardText
    }
  },
  mounted() {
    this.navigator = navigator
  },
  methods: {
    async paste() {
      if(this.navigator && this.navigator.clipboard){
        try{
          const text = await this.navigator.clipboard.readText()
          this.$emit('paste', text)
        } catch(err){
          console.log(err)
        }
      } else {
        var textArea = document.createElement('textArea');
        textArea.value = "";
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('paste');
        const text = textArea.value
        document.body.removeChild(textArea);
        this.$emit('paste', text)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>