<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 10.5V1.5L6 2L7 1.5L7.99813 2L9.00969 1.5L10 2L10.9934 1.5L11.9913 2L13 1.5L14.0003 2L15 1.5V8.5"
      stroke="#FFF6F2"
      stroke-linejoin="round"
    />
    <path
      d="M15 8.5V12C15 12.663 14.7366 13.2989 14.2678 13.7678C13.7989 14.2366 13.1631 14.5 12.5 14.5V14.5C11.837 14.5 11.2011 14.2366 10.7323 13.7678C10.2634 13.2989 10 12.663 10 12V10.5H1.50002C1.4342 10.4994 1.36892 10.512 1.30799 10.5369C1.24706 10.5618 1.19171 10.5986 1.14517 10.6451C1.09862 10.6917 1.06181 10.747 1.03689 10.808C1.01197 10.8689 0.999436 10.9342 1.00002 11C1.00002 13 1.21064 14.5 3.50002 14.5H12.5"
      stroke="#FFF6F2"
      stroke-linejoin="round"
    />
    <path
      d="M7 4.5H13"
      stroke="#FFF6F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9 7H13"
      stroke="#FFF6F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>