<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.09074 1.50012C4.43512 1.45074 1.45074 4.43512 1.50012 8.09074C1.54887 11.5967 4.40355 14.4514 7.90949 14.5001C11.5657 14.5501 14.5495 11.5657 14.4995 7.91012C14.4514 4.40355 11.5967 1.54887 8.09074 1.50012ZM12.0414 11.7267C12.0289 11.7401 12.0137 11.7507 11.9967 11.7576C11.9797 11.7645 11.9614 11.7676 11.9431 11.7666C11.9248 11.7657 11.9069 11.7607 11.8907 11.7521C11.8745 11.7434 11.8605 11.7314 11.8495 11.7167C11.57 11.351 11.2277 11.0379 10.8386 10.792C10.0429 10.2814 9.0348 10.0001 8.00012 10.0001C6.96543 10.0001 5.9573 10.2814 5.16168 10.792C4.77258 11.0378 4.43027 11.3508 4.15074 11.7164C4.13976 11.731 4.12568 11.7431 4.10951 11.7518C4.09333 11.7604 4.07546 11.7654 4.05715 11.7663C4.03884 11.7673 4.02054 11.7642 4.00356 11.7573C3.98657 11.7504 3.97131 11.7398 3.95887 11.7264C3.04189 10.7365 2.52246 9.44302 2.50012 8.09387C2.44918 5.05293 4.94449 2.50762 7.98668 2.50012C11.0289 2.49262 13.5001 4.96293 13.5001 8.00012C13.5012 9.38181 12.9801 10.7129 12.0414 11.7267Z"
      fill="#12101A"
    />
    <path
      d="M8.00014 4.5C7.38389 4.5 6.8267 4.73094 6.43076 5.15063C6.03482 5.57031 5.83701 6.15062 5.8817 6.77344C5.97232 8 6.92264 9 8.00014 9C9.07764 9 10.0261 8 10.1186 6.77375C10.1648 6.15687 9.96857 5.58188 9.56607 5.15438C9.16857 4.7325 8.61232 4.5 8.00014 4.5Z"
      fill="#12101A"
    />
  </svg>
</template>