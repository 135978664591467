<template>
  <table>
    <thead>
      <tr>
        <th>Riport ID</th>
        <th>Elszámolási időszak</th>
        <th>Riport dátum</th>
        <th>Számlázandó összeg</th>
        <th>Státusz</th>
        <th></th>
      </tr>
    </thead>
    <tbody v-if="reports?.length">
      <tr v-for="report in reports" @click="handleNavigate(report.id)" style="cursor: pointer" :key="report.attributes.NID">
        <td>{{ report.attributes.NID }}</td>
        <td>
          {{ report.attributes.Period }}
        </td>
        <td>{{ formatDateAndTime(report.attributes.createdAt) }}</td>
        <td>{{ report.attributes.InvoiceTotal + (report.attributes.InvoiceTotalFood || 0) + (report.attributes.InvoiceTotalAccommodation || 0)}} Ft</td>
        <td><Chip :variant="report.attributes.Status" /></td>
        <!-- ACTION BUTTONS -->
        <td>
          <div style="display: flex">
            <button
              data-tooltip="Megtekintés"
              @click.stop="handleNavigate(report.id)"
              variant="white"
              icon
            >
              <chevron-right-icon />
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { ChevronRightIcon } from "../../assets/icons";
import Chip from "@/components/ChipComponent.vue";
import { useRoute, useRouter } from "vue-router";
import useFormatDate from "../../hooks/useFormatDate";
export default {
  props: ["reports"],
  components: {
    ChevronRightIcon,
    Chip,
  },
  setup() {
    const router = useRouter();
    const { params } = useRoute();
    const { formatDateAndTime } = useFormatDate();

    // HANDLERS

    const handleNavigate = (reportId) => {
      router.push({
        name: "ReportDetailsPage",
        params: { ...params, reportId },
      });
    };

    return {
      formatDateAndTime,
      handleNavigate,
    };
  },
};
</script>

<style>
</style>