<template>
  <div class="item">
    <div class="flex">
      <file-icon style="margin-right: 24px" />
      <div class="">
        <div variant="navigation-bold">
          {{ title }}
        </div>
        <div
          v-if="subtitle"
          variant="list-item"
          style="margin-top: 8px; color: var(--color-grey-02)"
        >
          {{ subtitle }}
        </div>
      </div>
    </div>
    <div class="flex">
      <a :href="link" target="_blank"
        ><div variant="table-head-button-label">Elolvasom <link-icon /></div
      ></a>
    </div>
  </div>
</template>

<script>
import { FileIcon, LinkIcon } from "@/assets/icons";
export default {
  components: { FileIcon, LinkIcon },
  props: ["title", "subtitle", "link"],
};
</script>

<style scoped>
.item {
  background-color: var(--color-red-07);
  padding: 16px 24px;
  border-radius: 4px;
  border: 1px solid var(--color-red-06);
  display: flex;
  justify-content: space-between;
}
</style>