<template>
  <!-- CONTENT BODY -->
  <div class="body">
    <div class="header">
      <div class="flex">
        <!-- ICON -->
        <div
          class="flex"
          style="
            background-color: var(--color-blue-04-1);
            border-radius: 40px;
            width: 66px;
            height: 66px;
          "
        >
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.7188 32.7344V4.89062L18.8125 6.4375L21.9062 4.89062L24.9942 6.4375L28.1237 4.89062L31.1875 6.4375L34.2609 4.89062L37.3479 6.4375L40.4688 4.89062L43.5635 6.4375L46.6562 4.89062V26.5469"
              stroke="white"
              stroke-width="2.0625"
              stroke-linejoin="round"
            />
            <path
              d="M46.6563 26.5469V37.375C46.6563 39.4263 45.8414 41.3936 44.391 42.844C42.9405 44.2945 40.9732 45.1094 38.9219 45.1094V45.1094C36.8706 45.1094 34.9034 44.2945 33.4529 42.844C32.0024 41.3936 31.1876 39.4263 31.1876 37.375V32.7344H4.89069C4.68704 32.7326 4.48508 32.7713 4.29659 32.8484C4.1081 32.9255 3.93686 33.0394 3.79286 33.1834C3.64885 33.3274 3.53498 33.4987 3.45788 33.6872C3.38078 33.8756 3.342 34.0776 3.34381 34.2812C3.34381 40.4688 3.99543 45.1094 11.0782 45.1094H38.9219"
              stroke="white"
              stroke-width="2.0625"
              stroke-linejoin="round"
            />
            <path
              d="M21.9062 14.1719H40.4688"
              stroke="white"
              stroke-width="2.0625"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M28.0938 21.9062H40.4688"
              stroke="white"
              stroke-width="2.0625"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="invoice-total">
          <div variant="navigation-bold" style="margin-left: 24px">
            A {{ report.attributes.Period }} időszakra számlázandó összeg összesen:
          </div>
          <div variant="list-item" style="min-width: 150px; text-align: right;">
            Nettó <span variant="navigation-bold">{{ report.attributes.InvoiceTotal + (report.attributes.InvoiceTotalFood || 0) + (report.attributes.InvoiceTotalAccommodation || 0)}} Ft + ÁFA</span>
          </div>
        </div>
      </div>

      <div v-if="!loadingConfig && config" class="invoice-details">
        <div variant="navigation-bold">Amelyből</div>
        <table>
          <tbody>
            <tr :key="item.key" variant="list-item" v-for="item in 
              [
                {key: 'program',label: 'programok után számlázandó összeg:', value: (report.attributes.InvoiceTotal || 0), suffix: `+ ${config.attributes.VATProgram}% ÁFA`},
                {key: 'food',label: 'étefogyasztás után számlázandó összeg:', value: (report.attributes.InvoiceTotalFood || 0), suffix: `+ ${config.attributes.VATFood}% ÁFA`},
                {key: 'accommodation',label: 'szálláshely után számlázandó összeg:', value: (report.attributes.InvoiceTotalAccommodation || 0), suffix: `+ ${config.attributes.VATAccommodation}% ÁFA`},
              ].filter(invoiceItem=>invoiceItem.value > 0)
            ">
            <td class="item-header">{{item.label}}</td>
            <td class="item-content">
              nettó:
              <span variant="navigation-bold"
                >{{ item.value }} Ft {{ item.suffix }}</span
              >
            </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="summary">
      <div variant="table-head-button-label" style="margin-bottom: 24px">
        A {{ report.attributes.Period }} hónap összegzése
      </div>
      <div variant="list-item" class="item">
        <span>Foglalások után befolyt összeg összesen</span>
        <span style="font-weight: 600">
          nettó: {{ report.attributes.TransactionsTotal + report.attributes.TransactionsTotalFood + report.attributes.TransactionsTotalAccommodation }} Ft</span
        >
      </div>
      <hr />
      <div variant="list-item" class="item">
        <span>Országos Bortúra mennyiségi kedvezménye összesen</span>
        <span style="font-weight: 600">
          nettó: {{ report.attributes.ServiceFee }} Ft</span
        >
      </div>
      <hr />
      <div v-if="report.attributes.EventsTotal != null && report.attributes.EventsTotal != undefined">
          <div variant="list-item" class="item">
            <span>Ebben a hónapban megtartott események száma</span>
            <span style="font-weight: 600">
              {{ report.attributes.EventsTotal }} db</span
            >
          </div>
          <hr />
      </div>
      <div v-if="report.attributes.ParticipantsTotal != null && report.attributes.ParticipantsTotal != undefined">
        <div variant="list-item" class="item">
          <span>Ebben a hónapban az összes résztvevőszám az eseményeken</span>
          <span style="font-weight: 600">
            {{ report.attributes.ParticipantsTotal }} fő</span
          >
        </div>
        <hr />
      </div>
    </div>
    <div class="billing" v-if="!loadingConfig && config">
      <div variant="navigation-bold" style="margin-bottom: 24px">
        Számlázási adatok
      </div>
      <div variant="list-item" class="item">
        <div style="font-weight: 600; width: 120px">Cégnév</div>
        <span>{{ config.attributes.CompanyName }}</span>
      </div>
      <hr />
      <div variant="list-item" class="item">
        <span style="font-weight: 600; width: 120px">Adószám</span>
        <span>{{ config.attributes.TaxNumber }}</span>
      </div>
      <hr />
      <div variant="list-item" class="item">
        <span style="font-weight: 600; width: 120px">Székhely</span>
        <span>{{ config.attributes.BillingAddress }}</span>
      </div>
      <hr />
      <div
        variant="list-item"
        style="display: flex; justify-content: space-between; padding: 24px 0px"
      >
        <span style="font-weight: 600">Megjegyzés rovatban feltüntetendő:</span>
        <span style="display: flex; align-items: center;">
          <span>REPORT#{{ report.attributes.NID }}</span>
          <copy-button :text="`REPORT#${report.attributes.NID}`"></copy-button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CopyButton from '../../components/CopyButton.vue';
import useConfig from "../../hooks/useConfig";
export default {
  components: { CopyButton },
  props: ["report"],
  setup() {
    const { config, loading: loadingConfig } = useConfig();

    return {
      config,
      loadingConfig
    };
  },
};
</script>

<style scoped>
.body {
  margin: 8px;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.body .header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--color-white);
}

.body .header>.flex {
    width: 100%;
    justify-content: flex-start;
}

.body .header .invoice-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 66px);
}

.body .summary {
  grid-area: 2 / 1 / 4 / 2;
  background-color: var(--color-white);
  padding: 24px;
}

.body .invoice-details {
  margin-left: 96px;
}

.body table tbody tr td {
  box-shadow: none;
  padding: 0;
  padding-left: 24px;
  padding-bottom: 12px;
}

.body .invoice-details .item-content {
  margin-left: 24px;
  margin-bottom: 12px;
}

.body .summary .item {
  padding: 16px 0px;
  display: flex;
  justify-content: space-between;
}
.body .billing {
  grid-area: 1 / 2 / 4 / 3;
  background-color: var(--color-white);
  padding: 24px;
}
.body .billing .item {
  padding: 16px 0px;
  display: flex;
}
</style>