<template>
  <div class="header">
    <OBTLogo />
    <div class="divider"></div>
    <p variant="page-title" style="color: var(--color-white)">Boradmin</p>
  </div>
</template>

<script>
import OBTLogo from "../assets/OBTLogo.vue";
export default {
  components: { OBTLogo },
};
</script>

<style scoped>
.header {
  padding-top: 64px;
  padding-bottom: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.divider {
  margin-left: 8px;
  margin-right: 8px;
  width: 2px;
  height: 40px;
  background-color: var(--color-blue-04-1);
}
</style>