import { onMounted, ref } from "vue";
import extrasApi from "../api/extras";

function useExtras() {
  const loading = ref(true);
  const extras = ref(null);

  const getExtras = async () => {
    const res = await extrasApi.get();
    if (res.ok) {
      extras.value = res.data.data.map((extra) => ({
        value: extra.id,
        label: extra.attributes.Name,
      }));
      loading.value = false;
    } else {
      console.log(res.data);
      loading.value = false;
    }
  };

  onMounted(() => getExtras());

  return {
    loading,
    extras,
  };
}

export default useExtras;
