import client from "./client";

const endpoint = "/api/auth";

const login = ({ identifier, password }) =>
  client.post(endpoint + "/local", { identifier, password });

const forgotPassword = (email) =>
  client.post(endpoint + "/forgot-password", { email });

const resetPassword = ({ code, password, passwordConfirmation,AcceptWineryAdminTermsAndPrivacy }) =>
  client.post(endpoint + "/reset-password", {
    code,
    password,
    passwordConfirmation,
    AcceptWineryAdminTermsAndPrivacy
  });
export default {
  login,
  forgotPassword,
  resetPassword,
};
