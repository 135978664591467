<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.21967 2.96967C5.51256 2.67678 5.98744 2.67678 6.28033 2.96967L10.7803 7.46967C11.0732 7.76256 11.0732 8.23744 10.7803 8.53033L6.28033 13.0303C5.98744 13.3232 5.51256 13.3232 5.21967 13.0303C4.92678 12.7374 4.92678 12.2626 5.21967 11.9697L9.18934 8L5.21967 4.03033C4.92678 3.73744 4.92678 3.26256 5.21967 2.96967Z"
      fill="#12101A"
    />
  </svg>
</template>