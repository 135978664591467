<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.5 7.5H1V1H7.5V7.5Z" fill="white" />
    <path d="M15 7.5H8.5V1H15V7.5Z" fill="white" />
    <path d="M7.5 15H1V8.5H7.5V15Z" fill="white" />
    <path d="M15 15H8.5V8.5H15V15Z" fill="white" />
  </svg>
</template>