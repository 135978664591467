<template>
  <Modal v-if="visible" @close="handleClose">
    <div class="body-container">
      <svg
        style="margin-bottom: 32px"
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="100" height="100" rx="50" fill="#6CA32F" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M74.9809 29.4862C75.955 30.3386 76.0537 31.8193 75.2014 32.7934L42.3889 70.2934C41.9617 70.7816 41.3513 71.0709 40.703 71.0925C40.0547 71.1141 39.4264 70.866 38.9677 70.4073L24.9052 56.3448C23.9899 55.4295 23.9899 53.9455 24.9052 53.0303C25.8205 52.115 27.3045 52.115 28.2198 53.0303L40.5108 65.3213L71.6736 29.7067C72.526 28.7325 74.0067 28.6338 74.9809 29.4862Z"
          fill="white"
        />
      </svg>
      <div style="margin-bottom: 12px" variant="navigation-bold">
        Megerősíti, hogy a
      </div>
      <div
        style="margin-bottom: 12px; color: var(--color-blue-04-1)"
        variant="page-title"
      >
        {{ booking.attributes.NID }}
      </div>
      <div variant="list-item">
        {{ type !== 'complimentary-ticket' ? "foglalásszámú résztvevő " : "sorozatszámú tiszteletjegy résztvevője "}} megjelent az eseményen.
      </div>
      <div class="flex" style="margin-top: 24px">
        <button
          style="min-width: 120px; margin-right: 16px"
          @click="handleClose"
          variant="white"
        >
          Vissza
        </button>
        <button
          style="min-width: 120px"
          @click="handleStatusUpdate(booking.id, 'attended')"
        >
          Megjelent
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "../../components/ModalComponent.vue";
export default {
  components: {
    Modal,
  },
  props: ["booking", "visible", "handleClose", "handleStatusUpdate", "type"],
};
</script>

<style>
.body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 24px;
}
</style>