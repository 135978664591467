<template>
  <div @click.self="hideModal" class="backdrop">
    <div class="modal-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalComponent",
  emits: ["close"],
  setup(props, { emit }) {
    const hideModal = () => {
      emit("close");
    };

    return {
      hideModal,
    };
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* backdrop-filter: blur(2px); */
  /* background: transparent; */
  background: rgba(32, 26, 66, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-body {
  margin: auto;
  padding: 10px;
  background: var(--color-white);
  border-radius: 8px;
  flex: 1;
  max-width: 600px;
  box-shadow: 0px 0px 4px 10px rgba(0, 0, 0, 0.025);
}
.show-container {
  display: inline-block;
}
</style>