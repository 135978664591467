import { onIonViewDidEnter } from "@ionic/vue";
import { ref } from "vue";
import eventApi from "../api/event";

function useEvents(wineryId, pageSize) {
  const loading = ref(true);
  const events = ref(null);
  const pageCount = ref(null);
  const total = ref(null);
  const page = ref(null);

  const copyEvent = async (event, config, cb) => {
    loading.value = true;
    const res = await eventApi.copy(event, wineryId, config);
    if (res.ok) {
      loading.value = false;
      cb && cb(res.data.data);
    } else {
      loading.value = false;
    }
  };

  const getEvents = async (pageIdx, { search, status, dateFrom, dateTo }) => {
    const res = await eventApi.get(wineryId, pageSize, pageIdx, {
      search,
      status,
      dateFrom,
      dateTo
    });
    if (res.ok) {
      events.value = res.data.data;
      pageCount.value = res.data.meta.pagination.pageCount;
      total.value = res.data.meta.pagination.total;
      page.value = res.data.meta.pagination.page;
      loading.value = false;
    } else {
      console.log(res.data);
      loading.value = false;
    }
  };

  onIonViewDidEnter(() => getEvents(1, {}));

  return {
    loading,
    events,
    getEvents,
    copyEvent,
    pageCount,
    total,
    page,
  };
}

export default useEvents;
