<template>
  <div>
    <!-- Your component's HTML template goes here -->
    <div class="container" v-if="meta?.pagination?.total > 0">
      <div class="title" variant="navigation-bold">Jóváhagyásra váró foglalások ({{meta?.pagination?.total}})</div>
      <div class="content-container">
        <div class="scrollable-content">
          <!-- Content to be displayed inside the scrollable container -->
          <booking-under-approval-card :booking="booking" :key="booking.key" v-for="booking in bookings" @approve="bookingToApprove = booking" @reject="bookingToReject= booking"/>
        </div>
      </div>
    </div>
    <booking-under-approval-approve-modal @close="bookingToApprove = null" v-if="bookingToApprove" :booking="bookingToApprove" @approve="onApproved"/>
    <booking-under-approval-reject-modal @close="bookingToReject = null" v-if="bookingToReject" :booking="bookingToReject" @reject="onReject"/>
  </div>
</template>

<script>
import { ref } from 'vue';
import BookingUnderApprovalApproveModal from './BookingUnderApprovalApproveModal.vue';
import BookingUnderApprovalCard from './BookingUnderApprovalCard.vue';
import useUnderApprovalBookings from '../../hooks/useUnderApprovalBookings';
import BookingUnderApprovalRejectModal from './BookingUnderApprovalRejectModal.vue';
import { useToast } from 'vue-toastification';

export default {
  name: 'BookingsUnderApprovalList',
  props: {
    wineryId: {
      type: Number,
      required: true,
    },
  },
  components: {
    BookingUnderApprovalCard,
    BookingUnderApprovalApproveModal,
    BookingUnderApprovalRejectModal
  },
  setup(props) {
    const {bookings, getBookings, meta, refresh} = useUnderApprovalBookings(props.wineryId)
    const toast = useToast()
    const bookingToApprove = ref(null)
    const bookingToReject = ref(null)

    function onApproved () {
      bookingToApprove.value = null
      toast.success("Foglalás jóváhagyva")
      refresh()
    }

    function onReject () {
      bookingToReject.value = null
      toast.success("Foglalás elutasítva")
      refresh()
    }


    // Your component's setup goes here
    return {
      bookings,
      getBookings,
      meta,
      bookingToApprove,
      bookingToReject,
      onApproved,
      onReject,
      refresh
    }
  },
  data() {
    return {
      // Your component's data goes here
    };
  },
  methods: {
    // Your component's methods go here
  },
  mounted() {
    // Code to run when the component is mounted goes here
    this.getBookings()
  },
};
</script>

<style scoped>
/* Your component's styles go here */
.container {
  margin: 8px;
  margin-bottom: 24px;
}

.title {
  margin-left: 24px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.scrollable-content {
  overflow-x: scroll;
  display: flex;
  width: 110%;
  gap: 8px;
  
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable-content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.content-container {
  width: 100%;
  background: transparent;
}
</style>