// initial state

import bookingApi from "../../api/booking"

//example: https://github.com/vuejs/vuex/blob/main/examples/classic/shopping-cart/store/modules/cart.js

const state = () => ({
  data: {}
})
// getters
const getters = {
  getUnderApprovalBookings(state){
    return state.data
  }
}

// actions
const actions = {
  async getUnderApprovalBookings({ commit, state }, wineryId) {
    if(state.data[wineryId]){
      return state.data[wineryId]
    } else {
      const resp = await bookingApi.get(wineryId, 100, 1, {status: 'under-approval'});
      if(resp.ok){
        commit('setBookings',{bookings: resp.data, wineryId});
        return {
          ...state.data,
          [wineryId]: resp.data
        };
      } else {
        if(state.data){
          commit('setBookings',{bookings: null, wineryId});
        }
        return {
          ...state.data,
          [wineryId]: null
        };
      }
    }
  },
  async refresh ({commit,state}, wineryId) {
    const resp = await bookingApi.get(wineryId, 100, 1, {status: 'under-approval'});
    if(resp.ok){
      commit('setBookings',{bookings: resp.data, wineryId});
      return {
        ...state.data,
        [wineryId]: resp.data
      };
    } else {
      if(state.data){
        commit('setBookings',{bookings: null, wineryId});
      }
      return {
        ...state.data,
        [wineryId]: null
      };
    }
  }
}

// mutations
const mutations = {
  setBookings(state, {bookings, wineryId}){
    console.log("setBookings", bookings)
    state.data[wineryId] = bookings
    state.data = {...state.data}
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}