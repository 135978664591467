<template>
  <div>
    <table v-if="hasSimpleEvents()">
      <thead>
        <tr>
          <th colspan="6" style="text-align: center; border-bottom: 2px solid var(--color-grey-04);">
            Borprogram események
          </th>
        </tr>
        <tr>
          <th>Esemény neve</th>
          <th>Esemény dátuma</th>
          <th>Foglalt helyek száma</th>
          <th>Nettó alapár/fő</th>
          <th>Az eseményhez tartozó<br/>mennyiségi kedvezmény (%)</th>
          <th>Nettó mennyiségi<br/>kedvezmény összesen</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="event in report?.attributes.Events.data.filter(e=>!e.attributes.Package.data)" :key="event.attributes.NID">
          <td>{{ event.attributes.Name }}</td>
          <td>
            {{ formatDateAndTime(event.attributes.StartAt) }} -
            {{ formatDateAndTime(event.attributes.EndAt) }}
          </td>
          <td>{{ event.attributes.Booked }}</td>
          <td v-if="!event.attributes.DetailedPricing">{{ event.attributes.NetPrice }} Ft</td>
          <td v-else class="tooltip-container">{{ (event.attributes.NetPriceProgram || 0) + 
                        (event.attributes.NetPriceFood || 0) +
                        (event.attributes.NetPriceAccommodation || 0)}} Ft
            <span>
              <button
                :data-tooltip="`
                  Program: ${event.attributes.NetPriceProgram || 0} Ft,
                  Étel: ${event.attributes.NetPriceFood || 0} Ft,
                  Szállás: ${event.attributes.NetPriceAccommodation || 0} Ft
                `"
                data-tooltip-position="left"
                variant="transparent"
                icon
              >
                <!-- <info-icon /> -->
                <info-icon-dark />
              </button>
            </span>
          </td>
          <td>{{ event.attributes.CommissionPercentage === null ? '-' : `${event.attributes.CommissionPercentage}%` }}</td>
          <td>{{  event.attributes.CommissionTotal === null ? 0 : event.attributes.CommissionTotal }} Ft</td>
        </tr>
      </tbody>
    </table>
    <table v-if="hasPackageEvents()" style="margin-top: 24px;">
    <thead>
      <tr>
        <th colspan="6" style="text-align: center; border-bottom: 2px solid var(--color-grey-04);">
          Csomagajánlat események
        </th>
      </tr>
      <tr>
        <th>Esemény neve</th>
        <th>Esemény dátuma</th>
        <th>Foglalt helyek száma</th>
        <th>Nettó össz. alapár</th>
        <th>Az eseményhez tartozó<br/>mennyiségi kedvezmény (%)</th>
        <th>Nettó mennyiségi<br/>kedvezmény összesen</th>
      </tr>
    </thead>
      <tbody>
        <tr v-for="event in report?.attributes.Events.data.filter(e=>e.attributes.Package.data)" :key="event.attributes.NID">
          <td>{{ event.attributes.Name }}</td>
          <td>
            {{ formatDateAndTime(event.attributes.StartAt) }} -
            {{ formatDateAndTime(event.attributes.EndAt) }}
          </td>
          <td>{{ event.attributes.Booked }}</td>
          <td>{{ calculatePackagePriceFields(event).total }} Ft <span>
              <button
                :data-tooltip="`
                  Program: ${calculatePackagePriceFields(event).program} Ft,
                  Étel: ${calculatePackagePriceFields(event).food} Ft,
                  Szállás: ${calculatePackagePriceFields(event).accommodation} Ft
                `"
                data-tooltip-position="left"
                variant="transparent"
                icon
              >
                <!-- <info-icon /> -->
                <info-icon-dark />
              </button>
            </span></td>
          <td>{{ event.attributes.CommissionPercentage === null ? '-' : `${event.attributes.CommissionPercentage}%` }}</td>
          <td>{{  event.attributes.CommissionTotal === null ? 0 : event.attributes.CommissionTotal }} Ft</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import InfoIconDark from '../../assets/icons/InfoIconDark.vue';
import useFormatDate from "../../hooks/useFormatDate";
export default {
  components: { InfoIconDark },
  props: ["report"],
  setup(props) {
    const { formatDateAndTime } = useFormatDate();
    function calculatePackagePriceFields(event){
      const obtPackage = event.attributes.Package.data.attributes;
      const booked = event.attributes.Booked;
      const packageMinPricePerParticipant = (obtPackage.NetPriceProgramMin || 0) + (obtPackage.NetPriceFoodMin || 0) + (obtPackage.NetPriceAccommodationMin || 0);
      const total = Math.max(obtPackage.NetPriceWineryTotal, booked * packageMinPricePerParticipant)
      const eventTotal = total * (obtPackage.NetPriceProgramMin || 0) / packageMinPricePerParticipant;
      const eventTotalFood = total * (obtPackage.NetPriceFoodMin || 0) / packageMinPricePerParticipant;
      const eventTotalAccommodation = total * (obtPackage.NetPriceAccommodationMin || 0) / packageMinPricePerParticipant;
      return {
        total: eventTotal + eventTotalFood + eventTotalAccommodation,
        program: eventTotal,
        food: eventTotalFood,
        accommodation: eventTotalAccommodation
      }
    }

    function hasPackageEvents(){
      return props.report?.attributes.Events.data.some(e=>e.attributes.Package.data)
    }

    function hasSimpleEvents(){
      return props.report?.attributes.Events.data.some(e=>!e.attributes.Package.data)
    }

    return {
      formatDateAndTime,
      calculatePackagePriceFields,
      hasPackageEvents,
      hasSimpleEvents
    };
  },
};
</script>

<style>
</style>