<template>
  <div class="container">
    <label variant="table-head-button-label" v-if="label"
      >{{ label }}
      <span class="optional" v-if="optional">(opcionális)</span></label
    >
    <!-- <div class="input" @click="focusInput()">
      <div class="left-wrapper">
        <slot name="left" />
      </div> -->
    <select
      ref="inputRef"
      :value="modelValue"
      @input="updateValue"
      :placeholder="placeholder"
    >
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
    <cancel-icon v-if="withCancel" @click="resetValue()" class="cancel-icon" />
    <slot name="right" />
    <!-- </div> -->
  </div>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { CancelIcon } from "../assets/icons";
export default {
  components: { CancelIcon },
  props: [
    "modelValue",
    "placeholder",
    "label",
    "optional",
    "withCancel",
    "options",
  ],
  setup(props, { emit }) {
    const inputRef = ref(null);
    const focusInput = () => {
      var event = new MouseEvent("mousedown");
      inputRef.value.dispatchEvent(event);
    };
    const resetValue = () => emit("update:modelValue", null);
    const updateValue = (event) =>
      emit("update:modelValue", event.target.value);
    return {
      focusInput,
      inputRef,
      resetValue,
      updateValue,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
label {
  margin-bottom: 8px;
}
.optional {
  font-family: Signika;
  font-size: 14px;
  color: var(--color-grey-03);
  font-weight: 400;
}
.cancel-icon {
  width: 16px;
  height: 16px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
.cancel-icon:hover {
  width: 18px;
  height: 18px;
}
.left-wrapper {
  margin-right: 6px;
}
</style>