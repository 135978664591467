import dayjs from "dayjs";

function useFormatDate() {
  const formatDateAndTime = (date) => dayjs(date).format("YYYY.MM.DD. HH:mm");
  const formatDate = (date) => dayjs(date).format("YYYY.MM.DD.");
  const formatDates = (start,end) => `${formatDateAndTime(start)} - ${dayjs(end).format("HH:mm")}`;

  return {
    formatDateAndTime,
    formatDate,
    formatDates
  };
}

export default useFormatDate;
