<template>
  <div class="container">
    <div class="sidebar-navigation">
      <div class="top-content">
        <div style="padding: 12px">
          <OBTLogo />
          <div
            style="width: 100%; margin-top: 12px; margin-bottom: 36px"
          >
            <button
              v-if="!hideLinks && me?.Wineries?.length > 1"
              variant="white"
              style="width: 100%;"
              icon-left
              @click="() => $router.push({ name: 'WinerySelectorPage' })"
            >
              <arrow-back-icon /> Borászataim
            </button>
          </div>
        </div>
        <div v-for="link in links" :key="link.name">
          <div
            v-if="!hideLinks"
            @click="handleLinkClick(link)"
            :class="`sidebar-link ${active == link.name ? 'active' : ''}`"
          >
            <div class="icon-text-container">
              <span class="icon" v-if="link.icon == 'home'"><HomeIcon /></span>
              <span class="icon" v-if="link.icon == 'event'"><EventIcon /></span>
              <span class="icon" v-if="link.icon == 'reservation'"
                ><ReservationIcon
              /></span>
              <span class="icon" v-if="link.icon == 'report'"
                ><ReportIcon
              /></span>
              {{ link.text }}
            </div>
            <span style="padding-left: 4px"><booking-count v-if="link.icon == 'home'" :wineryId="wineryId" /></span>
          </div>
        </div>
      </div>
      <div class="bottom-content">
        <!-- SIGNOUT BUTTON -->
        <div style="display: flex; padding: 16px">
          <!-- <span
            class="flex"
            style="
              background-color: var(--color-blue-01);
              border-radius: 32px;
              width: 32px;
              height: 32px;
            "
          >
            <signout-icon />
          </span> -->
          <button
            style="flex: 1"
            variant="dark"
            icon-left
            @click="handleLogout"
          >
            <signout-icon /> Kijelentkezés
          </button>
        </div>
        <!-- INFO -->
        <div class="bottom-info">
          <info-icon />
          <div variant="small-text">
            Hibát észleltél? Jelezd nekünk a
            <a href="mailto:hiba@orszagosbortura.hu">hiba@orszagosbortura.hu</a>
            e-mail címen. Köszönjük!
          </div>
        </div>
        <!-- VERSION -->
        <div
          variant="small-text"
          class="flex"
          style="
            color: var(--color-white);
            background-color: var(--color-blue-01);
            padding: 8px;
          "
        >
          Országos Bortúra - Boradmin 2.0
        </div>
      </div>
    </div>
    <div class="main-content">
      <slot />
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import OBTLogo from "../assets/OBTLogo.vue";
import {
  HomeIcon,
  EventIcon,
  ReservationIcon,
  ReportIcon,
  SignoutIcon,
} from "../assets/icons";
import InfoIcon from "../assets/icons/InfoIcon.vue";
import useAuth from "../hooks/useAuth";
import ArrowBackIcon from "../assets/icons/ArrowBackIcon.vue";
import useMe from '../hooks/useMe';
import BookingCount from './BookingCount.vue';
export default {
  props: ["active", "hideLinks"],
  components: {
    OBTLogo,
    HomeIcon,
    EventIcon,
    ReservationIcon,
    ReportIcon,
    SignoutIcon,
    InfoIcon,
    ArrowBackIcon,
    BookingCount,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const userId = route.params.userId;
    const wineryId = route.params.wineryId;
    const params = {
      userId,
      wineryId,
    };
    const { logout } = useAuth();

    const {me, loading} = useMe();

    const handleLinkClick = (link) => {
      router.push({ name: link.name, params });
    };

    const handleLogout = () => {
      logout(() => router.push({ name: "LoginPage" }));
    };

    const links = [
      {
        text: "Kezdőlap",
        name: "DashboardPage",
        icon: "home",
      },
      {
        text: "Események",
        name: "EventsPage",
        icon: "event",
      },
      {
        text: "Foglalások",
        name: "BookingsPage",
        icon: "reservation",
      },
      {
        text: "Elszámolási riportok",
        name: "ReportsPage",
        icon: "report",
      },
    ];

    return {
      links,
      handleLinkClick,
      handleLogout,
      me,
      loading,
      wineryId
    };
  },
};
</script>

<style scoped>
.sidebar-navigation {
  width: 268px;
  padding: 0px;
  background-color: var(--color-blue-02);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
.bottom-content {
}
.bottom-info {
  color: var(--color-white);
  display: flex;
  box-shadow: inset 0px 1px 0px #382e74;
  padding: 16px;
}
.bottom-info svg {
  min-width: 16px;
  margin-right: 3px;
  fill: var(--color-white);
}
.sidebar-link {
  padding: 10px;
  background: var(--color-blue-02);
  color: var(--color-white);
  font-family: Signika;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-text-container{
  display: flex;
  align-items: center;
}

.sidebar-link.active {
  background: var(--color-red-02);
}
.sidebar-link .icon {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background-color: var(--color-blue-01);
  margin-right: 8px;
}
.sidebar-link.active .icon {
  background-color: var(--color-red-01);
}
.sidebar-link:hover {
  background: var(--color-blue-03);
}
.sidebar-link.active:hover {
  background-color: var(--color-red-03);
}
.container {
  display: flex;
  flex-direction: row;
  flex: 1;
}
.main-content {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  background-color: var(--color-red-06);
}

@media only screen and (max-width: 768px) {
  .sidebar-navigation {
    display: none;
  }
}
</style>