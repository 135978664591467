<template>
  <div class="container">
    <div
      :class="`tag ${active == tab.value ? 'active' : ''}`"
      v-for="(tab, idx) in tabs"
      :style="setBorderStyle(idx)"
      :key="tab.value"
      @click="tab.onClick"
    >
      {{ tab.label }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["tabs", "active"],
  setup(props) {
    const setBorderStyle = (idx) => {
      if (idx == 0) {
        return "border-radius: 4px 0px 0px 4px";
      } else if (idx == props.tabs.length - 1) {
        return "border-radius: 0px 4px 4px 0px";
      }
    };

    return { setBorderStyle };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tag {
  border: 1px solid var(--color-grey-03);
  padding: 12px 16px;
  font-family: Signika;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}
.tag:hover {
  background-color: var(--color-grey-05);
}
.tag.active {
  border: 1px solid var(--color-red-02);
  background-color: var(--color-red-02);
  color: var(--color-red-07);
}
</style>