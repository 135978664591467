<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.3293 3.62372C13.5371 3.80556 13.5581 4.12144 13.3763 4.32926L6.37629 12.3293C6.28516 12.4334 6.15495 12.4951 6.01664 12.4997C5.87833 12.5043 5.7443 12.4514 5.64645 12.3536L2.64645 9.35356C2.45118 9.1583 2.45118 8.84172 2.64645 8.64645C2.84171 8.45119 3.15829 8.45119 3.35355 8.64645L5.97564 11.2685L12.6237 3.67075C12.8056 3.46294 13.1214 3.44188 13.3293 3.62372Z"
      fill="white"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>