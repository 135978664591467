<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8125 3.375C12.4965 3.93616 13.0474 4.64206 13.4257 5.44182C13.804 6.24158 14.0001 7.11529 14 8C14 11.3125 11.3125 14 8.00003 14C4.68753 14 2.00003 11.3125 2.00003 8C1.9974 7.11799 2.18927 6.24625 2.56196 5.44684C2.93466 4.64744 3.47902 3.94004 4.15628 3.375"
      stroke="#FFF6F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8 2V8"
      stroke="#FFF6F2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>