<template>
  <modal-component @close="$emit('close')">
    <div
      style="
        padding: 32px 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      "
    >
      <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="64" height="64" rx="32" fill="#201A42"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M32 15.5C22.8873 15.5 15.5 22.8873 15.5 32C15.5 41.1127 22.8873 48.5 32 48.5C41.1127 48.5 48.5 41.1127 48.5 32C48.5 22.8873 41.1127 15.5 32 15.5ZM11 32C11 20.402 20.402 11 32 11C43.598 11 53 20.402 53 32C53 43.598 43.598 53 32 53C20.402 53 11 43.598 11 32Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M43.6671 46.8491L17.1509 20.3329L20.3329 17.1509L46.8491 43.6671L43.6671 46.8491Z" fill="white"/>
      </svg>

      <div variant="page-title">Foglalás elutasítása</div>
      <div variant="list-item">Kérjük, írd le az elutasítás okát az adminisztrátor részére.</div>
      <textarea
        maxlength="500"
        placeholder="Elutasítás oka..."
        v-model="formData.reason"
        style="width: 100%; height: 100px"
      />
      <input-error v-if="errors?.reason" :error="errors?.reason" />
      <div class="buttons">
        <button variant="white" @click="$emit('close')">Vissza</button>
        <button @click="rejectBooking" :disabled="loading">Küldés</button>
      </div>
    </div>
  </modal-component>
</template>

<script>
import { ref } from 'vue';
import ModalComponent from '../ModalComponent.vue';
import booking from '../../api/booking';
import useValidation from '../../hooks/useValidation';
import InputError from '../InputError.vue';
export default {
  name: 'BookingUnderApprovalRejectModal',
  components: {
    ModalComponent,
    InputError
  },
  emits: ['close','reject'],
  props: {
    booking: {
      type: Object,
      required: true,
    }
  },
  setup(){
    const loading = ref(false)
    const formData = ref({
      reason: null
    })

    const {errors, validate} = useValidation({
      reason: [
        { validator: "required" }
      ]
    })

    return {
      loading,
      errors,
      validate,
      formData,
    }
  },
  data() {
    return {
      // Your component's data goes here
    };
  },
  methods: {
    async rejectBooking(){
      this.validate(this.formData, {
        onAccept: async ()=>{
          this.loading = true
          await booking.update({data: {Status: "rejected", PackageRejectReason: this.formData.reason}}, this.booking.id)
          this.loading = false
          this.$emit('reject')
        }
      })

    }
    // Your component's methods go here
  },
  computed: {
    // Your computed properties go here
  },
  mounted() {
    // Code to run when the component is mounted goes here
  },
};
</script>

<style scoped>
.buttons {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.buttons button {
  width: 40%
}
/* Your component's styles go here */
</style>