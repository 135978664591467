<template>
  <ion-page>
    <ion-content>
      <SidebarNavigation active="BookingsPage">
        <div
          v-if="
            !loadingBookings && bookings && (filterInUse || bookings?.length)
          "
        >
          <div class="background">
            <div class="header">
              <div variant="page-title">Foglalások</div>
              <div variant="small-text">Összesen {{ total }} foglalás</div>
            </div>
            <EventsFilter :bookings="true" :handleChange="handleFilterChange" />
            <BookingsTable
              :onUpdateFinished="handleUpdateFinished"
              :bookings="bookings"
            />
          </div>
          <page-selector
            v-if="bookings?.length"
            :currentPage="currentPage"
            :max="pageCount"
            :onClick="handleSelectPage"
          />
        </div>
        <no-bookings-content v-if="!filterInUse && !bookings?.length" />
      </SidebarNavigation>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import SidebarNavigation from "@/components/SidebarNavigation";
import EventsFilter from "../events/EventsFilter.vue";
import BookingsTable from "./BookingsTable.vue";
import PageSelector from "../../components/PageSelector.vue";
import NoBookingsContent from "./NoBookingsContent.vue";
import { useRoute } from "vue-router";
import useBookings from "../../hooks/useBookings";
import { ref } from "@vue/reactivity";
import dayjs from 'dayjs';
export default {
  name: "BookingsPage",
  components: {
    IonPage,
    IonContent,
    SidebarNavigation,
    EventsFilter,
    BookingsTable,
    PageSelector,
    NoBookingsContent,
  },
  setup() {
    const { params } = useRoute();
    const wineryId = params.wineryId;

    // do not show the no bookings card, when filter is used
    const filterInUse = ref(null);

    const {
      bookings,
      loading: loadingBookings,
      pageCount,
      page: currentPage,
      total,
      getBookings,
    } = useBookings(wineryId);

    // HANDLERS
    const handleSelectPage = async (pageIdx) => {
      await getBookings(pageIdx, { ...filterInUse.value });
      currentPage.value = pageIdx;
    };

    const handleFilterChange = (filterData) => {
      const _filterData = {
        dateFrom: filterData.dateFrom || undefined,
        dateTo: filterData.dateTo ? dayjs(filterData.dateTo).add(1,'day').format('YYYY-MM-DD'): undefined,
        status: filterData.status == "all" ? undefined : filterData.status,
        search: filterData.search || undefined,
      };
      filterInUse.value = _filterData;
      getBookings(1, { ..._filterData });
    };

    const handleUpdateFinished = async () => {
      await getBookings(currentPage.value, { ...filterInUse.value });
    };

    return {
      currentPage,
      pageCount,
      loadingBookings,
      bookings,
      total,
      handleFilterChange,
      handleSelectPage,
      handleUpdateFinished,
      filterInUse,
    };
  },
};
</script>

<style>
.background {
  background-color: var(--color-white);
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px;
}
</style>