<template>
  <!-- IF THERE IS ET LEAST ONE WINERY -->
  <div v-if="wineries?.length">
    <div
      variant="page-title"
      style="margin-bottom: 40px; color: var(--color-white)"
    >
      Válassz borászataid közül…
    </div>
    <div class="card">
      <div v-for="winery in wineries" :key="winery.id">
        <div class="winery-button" @click="handleWineryClick(winery)">
          {{ winery.Name }}
          <div class="winery-action">
            <BookingCount :wineryId="winery.id" />
            <ChevronRightIcon />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- NO WINERY -->
  <div v-else class="no-winery-content">
    <div variant="page-title" style="margin-bottom: 32px">
      Még nem lettél hozzáadva egy borászathoz sem!
    </div>
    <div variant="list-item" style="margin-bottom: 40px">
      Hamarosan hozzárendelünk a borászatodhoz. Kis türelmed kérjük, illetve
      hogy látogass vissza kicsit később.
    </div>
    <div variant="table-head-button-label" style="color: var(--color-red-07)">
      Köszönettel,
    </div>
    <div variant="list-item" style="color: var(--color-red-07)">
      Az Országos Bortúra csapata
    </div>
  </div>
</template>

<script>
import { ChevronRightIcon } from "../../assets/icons";
import BookingCount from '../../components/BookingCount.vue';
export default {
  props: ["handleWineryClick", "wineries"],
  components: {
    ChevronRightIcon,
    BookingCount
  },
  name: "WineryPicker",
  setup() {
    return {};
  },
};
</script>

<style scoped>
.winery-button {
  padding: 16px;
  cursor: pointer;
  box-shadow: inset 0px -1px 0px #dddddd;
  font-family: Signika;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  transition: 0.2s ease-in-out;
}

.winery-action {
  display: flex;
  align-items: center;
  margin-left: 4px;
}

.winery-button:hover {
  background-color: var(--color-red-06);
}
.card {
  background-color: var(--color-white);
  border-radius: 8px;
  padding: 0px 24px 0px 24px;
}
.no-winery-content {
  color: var(--color-white);
  max-width: 400px;
  margin: auto;
  text-align: center;
}
</style>