<template>
  <Modal v-if="visible" @close="handleClose">
    <div class="body-container">
      <svg
        style="margin-bottom: 24px"
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="100" height="100" rx="50" fill="#201A42" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M50 24.2187C35.7614 24.2187 24.2187 35.7614 24.2187 50C24.2187 64.2386 35.7614 75.7812 50 75.7812C64.2386 75.7812 75.7812 64.2386 75.7812 50C75.7812 35.7614 64.2386 24.2187 50 24.2187ZM17.1875 50C17.1875 31.8782 31.8782 17.1875 50 17.1875C68.1218 17.1875 82.8125 31.8782 82.8125 50C82.8125 68.1218 68.1218 82.8125 50 82.8125C31.8782 82.8125 17.1875 68.1218 17.1875 50Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M68.2295 73.2013L26.7979 31.7697L31.7697 26.7979L73.2013 68.2295L68.2295 73.2013Z"
          fill="white"
        />
      </svg>

      <div style="margin-bottom: 12px" variant="navigation-bold">
        Megerősíti, hogy a
      </div>
      <div
        style="margin-bottom: 12px; color: var(--color-blue-04-1)"
        variant="page-title"
      >
        {{ booking.attributes.NID }}
      </div>
      <div variant="list-item">
        foglalásszámú résztvevő nem megjelent az eseményen.
      </div>
      <div class="flex" style="margin-top: 24px">
        <button
          style="min-width: 120px; margin-right: 16px"
          @click="handleClose"
          variant="white"
        >
          Vissza
        </button>
        <button
          style="min-width: 120px"
          @click="handleStatusUpdate(booking.id, 'notattended')"
        >
          Nem jelent meg
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "../../components/ModalComponent.vue";
export default {
  components: {
    Modal,
  },
  props: ["booking", "visible", "handleClose", "handleStatusUpdate"],
};
</script>

<style>
.body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 24px;
}
</style>