<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.6667 2.5H3.33333C2.59333 2.5 2 3.1 2 3.83333V13.1667C2 13.9 2.59333 14.5 3.33333 14.5H12.6667C13.4 14.5 14 13.9 14 13.1667V3.83333C14 3.1 13.4 2.5 12.6667 2.5ZM11.3333 9.16667H8.66667V11.8333H7.33333V9.16667H4.66667V7.83333H7.33333V5.16667H8.66667V7.83333H11.3333V9.16667Z"
      fill="white"
    />
  </svg>
</template>