<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8934 7.5949L14.8884 7.59272L1.54219 2.05709C1.42993 2.0101 1.30778 1.99168 1.18666 2.00346C1.06554 2.01524 0.949225 2.05686 0.848125 2.12459C0.741311 2.19458 0.653573 2.29003 0.592805 2.40234C0.532037 2.51466 0.500145 2.64032 0.5 2.76803V6.30834C0.50006 6.48292 0.561019 6.65201 0.67237 6.78646C0.783722 6.92092 0.938491 7.01232 1.11 7.0449L8.38906 8.39084C8.41767 8.39626 8.44348 8.4115 8.46205 8.43392C8.48062 8.45634 8.49078 8.48454 8.49078 8.51365C8.49078 8.54277 8.48062 8.57097 8.46205 8.59339C8.44348 8.61581 8.41767 8.63104 8.38906 8.63647L1.11031 9.9824C0.938851 10.0149 0.784092 10.1062 0.67269 10.2405C0.561288 10.3749 0.500219 10.5438 0.5 10.7183V14.2593C0.499917 14.3812 0.530111 14.5013 0.587871 14.6087C0.645632 14.7161 0.729152 14.8074 0.830938 14.8746C0.953375 14.9559 1.09706 14.9994 1.24406 14.9996C1.34626 14.9995 1.4474 14.979 1.54156 14.9393L14.8875 9.43522L14.8934 9.4324C15.0731 9.3552 15.2262 9.22702 15.3337 9.06373C15.4413 8.90043 15.4986 8.70919 15.4986 8.51365C15.4986 8.31812 15.4413 8.12687 15.3337 7.96358C15.2262 7.80029 15.0731 7.67211 14.8934 7.5949Z"
      fill="#959595"
    />
  </svg>
</template>