<template>
  <div class="sidebar-container">
    <h3 variant="navigation-bold" :class="{ checked: mode == 'form' }">
      1. Új esemény részletei
    </h3>
    <div class="event-details-container" v-if="mode == 'form'">
      <div
        variant="small-text"
        :class="{ checked: field.checked }"
        v-for="field in formFields"
        :key="field"
      >
        <span v-if="field.checked">&#10004;</span>
        <span v-else style="margin-right: 12px"></span>
        {{ field.label }}
      </div>
    </div>
    <h3 variant="navigation-bold" :class="{ checked: mode == 'preview' }">
      2. Ellenőrzés
    </h3>
    <h3 variant="navigation-bold">3. Meghirdetés</h3>
  </div>
</template>

<script>
export default {
  name: "EventPageSidebar",
  props: ["formFields", "mode"],
  setup() {
    return {};
  },
};
</script>

<style scoped>
.sidebar-container {
  width: 100%;
  height: 100%;
  background-color: var(--color-blue-01);
  padding: 32px 24px;
}
.event-details-container {
  margin-left: 8px;
  padding-left: 4px;
  border-left: 1px solid var(--color-blue-05);
}
.event-details-container > div {
  margin-bottom: 4px;
}
[variant="small-text"],
[variant="navigation-bold"] {
  color: var(--color-grey-06);
  opacity: 0.5;
}
.checked {
  opacity: 1;
}
</style>