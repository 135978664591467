import client, { requestConfig } from "./client";

const endpoint = "/api/complimentary-tickets";

const get = (eventId, pageSize, page) =>
  client.get(
    endpoint,
    {
      "populate[0]": "Event",
      "filters[Event][id]": eventId,
      "pagination[pageSize]": pageSize,
      "pagination[page]": page,
      // "filters[Status]": status,
      // "filters[$or][0][NID][$contains]": search,
      // "filters[$or][1][Event][Name][$containsi]": search,
      // "filters[BookingDate][$gt]": dateFrom,
      // "filters[BookingDate][$lt]": dateTo,
      // "sort[BookingDate]": "desc",
      as: 'wineryadmin'
    },
    requestConfig()
  );

const update = (data, complimentaryTicketId) =>
  client.put(endpoint + "/" + complimentaryTicketId, data, requestConfig());

const getById = (complimentaryTicketId) =>
  client.get(`${endpoint}/${complimentaryTicketId}`, { populate: "*" }, requestConfig());

export default {
  get,
  update,
  getById,
};
