import { onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import userApi from "../api/user";

function useMe() {
  const store = useStore();

  const me = ref(store.getters['me/getMe']);
  const loading = ref(true);

  const getMe = async () => {
    const user = await store.dispatch('me/getMe');
    if (user) {
      me.value = user;
    }
    loading.value = false;
    return user;
  };

  const updateMe = async (data) => {
    if(me.value){
      const resp = await userApi.update(me.value.id,data)
      if(resp.ok){
        await store.dispatch('me/refresh');
        await getMe();
       
      }
      return resp;
    }
  }

  watch(
    ()=>store.getters['me/getMe'],
    (oldValue, newValue)=>{
      me.value = newValue;
    }
  )

  onMounted(getMe);

  

  // watchEffect(getMe);

  return {
    me,
    loading,
    getMe,
    updateMe
  };
}

export default useMe;
