import { onIonViewDidEnter } from "@ionic/vue";
import { ref } from "vue";
import bookingApi from "../api/booking";

function useBookings(wineryId, pageSize) {
  const loading = ref(true);
  const bookings = ref(null);
  const pageCount = ref(null);
  const total = ref(null);
  const page = ref(null);

  const getBookings = async (pageIdx, { dateFrom, dateTo, status, search }) => {
    const res = await bookingApi.get(wineryId, pageSize, pageIdx, {
      dateFrom,
      dateTo,
      status,
      search,
    });
    if (res.ok) {
      bookings.value = res.data.data;
      pageCount.value = res.data.meta.pagination.pageCount;
      total.value = res.data.meta.pagination.total;
      page.value = res.data.meta.pagination.page;
      loading.value = false;
    } else {
      console.log(res.data);
      loading.value = false;
    }
  };

  onIonViewDidEnter(() => getBookings(1, {}));

  return {
    loading,
    bookings,
    getBookings,
    pageCount,
    total,
    page,
  };
}

export default useBookings;
