<template>
  <Modal v-if="visible" @close="handleClose">
    <div class="body-container">
      <svg
        style="margin-bottom: 24px"
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="64" height="64" rx="32" fill="#FFF6F2" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M41.6194 18.0586C42.1669 18.0583 42.692 18.2756 43.0791 18.6627L45.3385 20.9221C45.7256 21.3092 45.9429 21.8343 45.9426 22.3817C45.9422 22.9292 45.7243 23.454 45.3367 23.8407L19.7617 49.3529C19.57 49.544 19.3427 49.6956 19.0925 49.7989L15.35 51.3449C14.5797 51.6631 13.6935 51.4864 13.1042 50.897C12.5148 50.3076 12.3381 49.4215 12.6563 48.6512L14.2022 44.9087C14.3056 44.6585 14.4571 44.4311 14.6483 44.2395L40.1605 18.6645C40.5471 18.2769 41.072 18.0589 41.6194 18.0586Z"
          fill="#201A42"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M46.4373 12.7749C46.8753 12.5934 47.3448 12.5 47.819 12.5C48.2931 12.5 48.7626 12.5934 49.2007 12.7749C49.6387 12.9563 50.0367 13.2222 50.3719 13.5575C50.372 13.5575 50.372 13.5576 50.372 13.5576L50.4428 13.6284C51.1193 14.3053 51.4993 15.2232 51.4993 16.1803C51.4993 17.1373 51.1193 18.0552 50.4428 18.7322L49.3369 19.8381C48.5315 20.6435 47.2256 20.6435 46.4201 19.8381L44.1607 17.5787C43.7738 17.1918 43.5565 16.667 43.5566 16.1198C43.5568 15.5727 43.7743 15.048 44.1614 14.6613L45.266 13.5576C45.2661 13.5574 45.2663 13.5573 45.2664 13.5571C45.2665 13.5571 45.2666 13.557 45.2667 13.5569C45.6018 13.2219 45.9995 12.9562 46.4373 12.7749ZM48.9838 17.2743L49.3369 16.9212L48.2429 15.8272L48.9838 15.0862L48.2426 15.8269L48.1725 15.7569L47.0775 14.6619"
          fill="#201A42"
        />
      </svg>

      <div style="margin-bottom: 12px" variant="navigation-bold">
        Módosítja a
      </div>
      <div
        style="margin-bottom: 12px; color: var(--color-blue-04-1)"
        variant="page-title"
      >
        {{ booking.attributes.NID }}
      </div>
      <div variant="list-item">
        foglalásszámú résztvevő állapotát az eseményen.
      </div>
      <div class="flex" style="margin-top: 24px">
        <button
          variant="success"
          style="min-width: 120px; margin-right: 16px"
          @click="handleStatusUpdate(booking.id, 'attended')"
          icon-left
        >
          <check-icon />
          Megjelent
        </button>
        <button
          variant="dark"
          style="min-width: 120px"
          @click="handleStatusUpdate(booking.id, 'notattended')"
          icon-left
        >
          <ban-icon />
          Nem jelent meg
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { BanIcon, CheckIcon } from "@/assets/icons";
import Modal from "../../components/ModalComponent.vue";
export default {
  components: {
    Modal,
    CheckIcon,
    BanIcon,
  },
  props: ["booking", "visible", "handleClose", "handleStatusUpdate"],
};
</script>

<style>
.body-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px 24px;
}
</style>