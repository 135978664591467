<template>
  <div class="checkbox" :class="{ reverse: reverse }">
    <input
      ref="inputRef"
      :value="modelValue"
      @input="updateValue"
      type="checkbox"
      hidden="true"
      :disabled="disabled"
    />
    <CheckboxCheckedIcon @click="handleClick()" v-if="modelValue" />
    <CheckboxIcon @click="handleClick()" v-if="!modelValue" />
    <label @click="handleClick" variant="input-label" v-if="label"
      >{{ label }}
      <span class="optional" v-if="optional">(opcionális)</span></label
    >
  </div>
</template>

<script>
import { ref } from "@vue/runtime-core";
import { CheckboxCheckedIcon, CheckboxIcon } from "../assets/icons";
export default {
  components: { CheckboxCheckedIcon, CheckboxIcon },
  props: ["modelValue", "type", "label", "optional", "reverse", "disabled"],
  setup(props, { emit }) {
    const inputRef = ref(null);
    const handleClick = () => {
      inputRef.value.click();
    };
    const updateValue = (event) =>
      emit("update:modelValue", event.target.checked);
    return {
      inputRef,
      updateValue,
      handleClick,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.reverse {
  flex-direction: row-reverse;
}
.reverse label {
  margin-left: 0px;
  margin-right: 8px;
}
label {
  margin-bottom: 8px;
}
.optional {
  font-family: Signika;
  font-size: 14px;
  color: var(--color-grey-03);
  font-weight: 400;
}
.cancel-icon {
  width: 16px;
  height: 16px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
.cancel-icon:hover {
  width: 18px;
  height: 18px;
}
.left-wrapper {
  margin-right: 6px;
}
</style>