<template>
  <ion-page>
    <ion-content>
      <div class="login-background">
        <LoginHeader />
        <FPForm />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import FPForm from "./FPForm.vue";
import LoginHeader from "../../components/LoginHeader.vue";
export default {
  name: "ForgottenPasswordPage",
  components: {
    IonPage,
    IonContent,
    FPForm,
    LoginHeader,
  },
};
</script>

<style>
</style>