import client, { requestConfig } from "./client";

const endpoint = `/api/wineries`;

const get = () => client.get(endpoint, {}, requestConfig());

const getById = (wineryId) =>
  client.get(
    endpoint + "/" + wineryId,
    {
      'populate[0]': "Addresses",
    },
    requestConfig()
  );

export default {
  get,
  getById,
};
