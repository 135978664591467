import { ref } from "vue";
import { useCookies } from "vue3-cookies";
import { useStore } from "vuex";
import authApi from "../api/auth";
import useError from "./useError";

function useAuth() {
  const store = useStore();
  const loading = ref(false);
  const { cookies } = useCookies();
  const error = ref(null);
  

  const login = async ({ identifier, password }, cb) => {
    loading.value = true;
    const res = await authApi.login({ identifier, password });
    if (res.ok) {
      cookies.set("jwt", res.data.jwt);
      await store.dispatch('me/refresh')
      setTimeout(() => {
        // WAIT FOR THE COOKIE SETUP
        loading.value = false;
        cb && cb(res);
      }, 500);
    } else {
      error.value = useError(res, "Az e-mail cím vagy a jelszó hibás!");
      loading.value = false;
    }
  };

  const logout = async (cb) => {
    store.commit('me/setMe', null);
    cookies.remove("jwt");
    cb && cb();
  };

  const resetPassword = async ({ code, password, passwordConfirmation, AcceptWineryAdminTermsAndPrivacy }) => {
    // TODO
    loading.value = true;
    const res = await authApi.resetPassword({
      code,
      password: password,
      passwordConfirmation: passwordConfirmation,
      AcceptWineryAdminTermsAndPrivacy: AcceptWineryAdminTermsAndPrivacy
    });
    if (!res.ok) {
      error.value = useError(res, "Hiba történt a jelszó beállítása során!");
    }
    loading.value = false;
    return res;
  };

  return {
    login,
    logout,
    resetPassword,
    loading,
  };
}

export default useAuth;
