<template>
  <svg
    width="144"
    height="144"
    viewBox="0 0 144 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="144" height="144" rx="72" fill="#D6244A" />
    <path
      d="M54 54L56.5 94C56.6187 96.3112 58.3 98 60.5 98H83.5C85.7088 98 87.3588 96.3112 87.5 94L90 54"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M50 54H94"
      stroke="white"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M64 54V49C63.9989 48.6057 64.0757 48.2151 64.226 47.8506C64.3764 47.4861 64.5973 47.1549 64.8761 46.8761C65.1549 46.5973 65.4861 46.3764 65.8506 46.226C66.2151 46.0757 66.6057 45.9989 67 46H77C77.3943 45.9989 77.785 46.0757 78.1495 46.226C78.514 46.3764 78.8451 46.5973 79.1239 46.8761C79.4028 47.1549 79.6237 47.4861 79.774 47.8506C79.9244 48.2151 80.0012 48.6057 80.0001 49V54"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M72 62V90"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M63 62L64 90"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M81 62L80 90"
      stroke="white"
      stroke-width="2.66667"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>