import { onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

function useConfig() {
  const store = useStore();
  const loading = ref(true);
  const config = ref(store.getters['config/getConfig']);
  const router = useRouter();

  const getConfig = async () => {
    loading.value = true;
    const config0 = await store.dispatch('config/getConfig');
    if (config0) {
      config.value = config0;
    } else {
      return router.replace({name: 'LoginPage'})
    }
    loading.value = false;
  };

  watch(
    ()=>store.getters['config/getConfig'],
    (oldValue, newValue)=>{
      config.value = newValue;
    }
  )

  onMounted(getConfig);

  return {
    loading,
    config,
    getConfig,
  };
}

export default useConfig;
