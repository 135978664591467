<template>
  <div :class="`booking-count booking-count-${meta?.pagination?.total||0}`">
    {{meta?.pagination?.total||0}}
  </div>
</template>

<script>
import useUnderApprovalBookings from '../hooks/useUnderApprovalBookings';
export default {
  name: 'BookingCount',
  props: {
    wineryId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const {meta} = useUnderApprovalBookings(props.wineryId)
    
    return {
      meta,
    }
  },
  methods: {
  },
  computed: {
    // Your component's computed properties go here
  },
  mounted() {
    // Code to run when the component is mounted goes here
  },
};
</script>

<style scoped>
/* Your component's CSS styles go here */
.booking-count {
  background-color: var(--color-pink);
  color: white;
  padding: 8px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  font-size: 14px;
  text-align: center;
}

.booking-count-0 {
  visibility: hidden;
}
</style>