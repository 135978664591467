<template>
  <form class="form-card" @submit.prevent="handleSubmit($e, formData)">
    <div class="title" variant="page-title">Bejelentkezés</div>
    <div class="info" v-if="firstUse">
      <div><InfoIcon /></div>
      <div variant="small-text">
        Amennyiben még nem fogadtad el a partner ÁSZF-et, a bejelentkezést követően fogod tudni ezt megtenni. Ezután lesz elérhető a Boradmin oldal összes funkciója számodra.
      </div>
    </div>
    <Input
      label="E-mail cím"
      v-model="formData.identifier"
      placeholder="Add meg a regisztrált e-mail címed"
      ><template v-slot:left><user-icon /></template
    ></Input>
    <Input
      label="Jelszó"
      type="password"
      v-model="formData.password"
      placeholder="Add meg az e-mailhez tartozó jelszavadat"
      ><template v-slot:left><password-icon /></template
    ></Input>

    <button
      type="submit"
      :disabled="!formData.identifier || !formData.password"
      icon-right
    >
      Bejelentkezem <login-icon />
    </button>
    <router-link class="forgotten-psw" to="/forgotten-password"
      >Elfelejtetted a jelszavad?</router-link
    >
  </form>
</template>

<script>
import { ref } from "@vue/reactivity";
import Input from "../../components/InputComponent.vue";
import { UserIcon, PasswordIcon, LoginIcon } from "../../assets/icons";
import InfoIcon from '../../assets/icons/InfoIcon.vue';
export default {
  name: "LoginForm",
  props: ["handleSubmit", "firstUse"],
  components: { Input, UserIcon, PasswordIcon, LoginIcon, InfoIcon },
  setup() {
    const formData = ref({ identifier: "", password: "" });

    return {
      formData,
    };
  },
};
</script>

<style scoped>
.title {
  margin-bottom: 32px;
}
.forgotten-psw {
  font-family: Signika;
  font-weight: 600;
  font-size: 14px;
  margin-left: 24px;
  color: var(--color-blue-01);
}
</style>