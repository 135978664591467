<template>
  <ion-page>
    <ion-content>
      <SidebarNavigation active="ReportsPage">
        <no-reports-content v-if="reports && !unreadOnly && reports.length == 0"/>
        <div v-else-if="reports">
          <div class="background">
            <div class="header">
              <div variant="page-title">Elszámolási riportok</div>
              <Checkbox
                v-model="unreadOnly"
                label="Csak az olvasatlanok mutatása"
              />
            </div>
            <ReportsTable v-if="!loadingReports" :reports="reports" />
          </div>
        </div>
      </SidebarNavigation>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import SidebarNavigation from "@/components/SidebarNavigation";
import ReportsTable from "./ReportsTable.vue";
import { ref } from "@vue/reactivity";
import NoReportsContent from "./NoReportsContent.vue";
import Checkbox from "@/components/CheckboxComponent.vue";
import useReports from "../../hooks/useReports";
import { useRoute } from "vue-router";
import { watchEffect } from '@vue/runtime-core';
export default {
  components: {
    IonPage,
    IonContent,
    Checkbox,
    SidebarNavigation,
    ReportsTable,
    NoReportsContent,
  },
  setup() {
    const unreadOnly = ref(false);
    const { params } = useRoute();
    const wineryId = params.wineryId;

    const {
      reports,
      loading: loadingReports,
      getReports,
    } = useReports(wineryId);

    const testReports = [
      {
        id: "001",
        attributes: {
          Period: "2022 Február",
          Date: "2022-02-20",
          Amount: 333666,
          Status: "unread",
        },
      },
      {
        id: "002",
        attributes: {
          Period: "2022 Január",
          Date: "2022-01-21",
          Amount: 333666,
          Status: "read",
        },
      },
      {
        id: "003",
        attributes: {
          Period: "2021 December",
          Date: "2021-12-20",
          Amount: 333666,
          Status: "read",
        },
      },
    ];

    watchEffect(async () => {
      await getReports({ status: unreadOnly.value ? "unread" : undefined });
    });

    return {
      testReports,
      reports,
      loadingReports,
      unreadOnly,
    };
  },
};
</script>

<style>
.background {
  background-color: var(--color-white);
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 24px;
}
</style>