<template>
  <form @submit.prevent="" style="display:flex; align-items: flex-end">
    <Input
      style="flex: 1"
      v-model="formData.search"
      type="text"
      :placeholder="
        bookings ? 'Keresés a foglalások között' : 'Keresés az események között'
      "
    >
      <template v-slot:left><SearchIcon /></template>
    </Input>
    <Select
      v-model="formData.status"
      style="margin-left: 8px; margin-right: 8px"
      :options="statusOptions"
    />
    <!-- <select v-model="formData.status">
      <option value="all">Összes státusz</option>
    </select> -->
    <Input :label="bookings ? 'Foglalás dátuma (-tól)':'Kezdődátum (-tól)'" type="date" v-model="formData.dateFrom" style="margin-right: 8px"/>
    <Input :label="bookings ? 'Foglalás dátuma (-ig)':'Kezdődátum (-ig)'" type="date" v-model="formData.dateTo" />
  </form>
</template>

<script>
import { ref } from "@vue/reactivity";
import { watchEffect } from "@vue/runtime-core";
import Input from "@/components/InputComponent.vue";
import Select from "@/components/SelectComponent.vue";
import { SearchIcon } from "@/assets/icons";

export default {
  props: ["handleChange", "bookings"],
  components: {
    Input,
    Select,
    SearchIcon,
  },
  setup(props) {
    const formData = ref({
      search: "",
      status: "all",
      dateFrom: "",
      dateTo: "",
    });

    const statusOptions = props.bookings
      ? [
          { value: "all", label: "Összes státusz" },
          { value: "successful", label: "Aktív" },
          { value: "attended", label: "Megjelent" },
          { value: "notattended", label: "Nem jelent meg" },
        ]
      : [
          { value: "all", label: "Összes státusz" },
          { value: "active", label: "Meghirdetett" },
          { value: "draft", label: "Piszkozat" },
          { value: "ongoing", label: "Éppen zajlik" },
          { value: "ended", label: "Lezárult" },
          { value: "deleted", label: "Törölt" },
        ];

    watchEffect(() => {
      // have to decompose it to register the change
      props.handleChange({
        ...formData.value,
      });
    });

    return {
      formData,
      statusOptions,
    };
  },
};
</script>

<style scoped>
form {
  padding-left: 24px;
  padding-right: 24px;
  width: 100%;
  display: flex;
  align-items: center;
}
form input,
form select {
  margin: 3px;
}
</style>