import { createRouter, createWebHistory } from "@ionic/vue-router";
import SplashScreen from "../views/SplashScreen.vue";
import LoginPage from "../views/login/LoginPage";
import ForgottenPasswordPage from "../views/forgotten-password/ForgottenPasswordPage";
import NewPasswordPage from "../views/new-password/NewPasswordPage";
import WinerySelectorPage from "../views/winery-selector/WinerySelectorPage";
import DashboardPage from "../views/dashboard/DashboardPage";
import EventsPage from "../views/events/EventsPage";
import EventPage from "../views/events/EventPage";
import BookingsPage from "../views/bookings/BookingsPage";
import BookingDetailsPage from "../views/bookings/BookingDetailsPage";
import ComplimentaryTicketDetailsPage from "../views/complimentary-tickets/ComplimentaryTicketDetailsPage";
import NotFoundPage from "../views/NotFoundPage"
import RegisterPage from "../views/register/RegisterPage";
import ReportsPage from "../views/reports/ReportsPage";
import ReportDetailsPage from "../views/reports/ReportDetailsPage";
import TermsPrivacyPage from "../views/terms-privacy/TermsPrivacyPage";
import ActiveEventPage from "../views/active-event/ActiveEventPage";
import bookingApi from "../api/booking"
import complimentaryTicketApi from "../api/complimentary-ticket";

import UIScreen from "../views/UIScreen";

import store from '../store';

import { useCookies } from "vue3-cookies";

const addUserToRoute = async (to) => {
  const me = await store.dispatch('me/getMe');
  if(me){
    return {
      path: `/users/${me.id}`+to.path
    }
  } else {
    return {
      name: "LoginPage",
      query: {
        next: to.path
      }
    }
  }
}

const replaceLongBookingId = async (to) => {
  const bookingId = to.params.bookingId;

  if(bookingId.length === 21){
    const resp = await bookingApi.getById(bookingId);
    if(resp.ok){
      const booking = resp.data;
      return {
        name: "BookingDetailsPage",
        params: {
          ...to.params,
          bookingId: booking.data.id
        }
      }
    } else {
      return {
        name: "LoginPage",
        query: {
          next: to.path
        }
      }
    }
    
  } else {
    return true;
  }
}

const replaceLongComplimentaryTicketId = async (to) => {
  const complimentaryTicketId = to.params.complimentaryTicketId;

  if(complimentaryTicketId.length === 21){
    const resp = await complimentaryTicketApi.getById(complimentaryTicketId);
    
    if(resp.ok){
      const complimentaryTicket = resp.data;
      return {
        name: "ComplimentaryTicketDetailsPage",
        params: {
          ...to.params,
          complimentaryTicketId: complimentaryTicket.data.id
        }
      }
    } else {
      return {
        name: "LoginPage",
        query: {
          next: to.path
        }
      }
    }
    
  } else {
    return true;
  }
}

const privacyAndTermsNotModified = async (to) =>{
  const [me, config] = await Promise.all([
    store.dispatch('me/getMe'),
    store.dispatch('config/getConfig')
  ])
  if(me.AcceptedWineryAdminPrivacyPolicyVersion !== config.attributes.WineryAdminPrivacyPolicyVersion
    || me.AcceptedWineryAdminTermsVersion !== config.attributes.WineryAdminTermsVersion
  ) {
    return {
      name: 'TermsPrivacyPage',
      query: {
        next: to.path,
        firstuse: to.query?.firstuse
      }
    };
  } else {
    return true;
  }
}

const canWineryAdminAccess = async (to)=>{
  const me = await store.dispatch('me/getMe');
  const {cookies} = useCookies();
  if(me){
    //check if boradmin
    console.log(me)
    console.log('me log')
    if(me.CustomRoles?.filter(role=>role.Name === "boradmin").length == 1){
      if(to.params?.userId){
        if(me.id == to.params.userId){
          return true;
        }
      } else {
        return true
      }
    } else {
      cookies.remove("jwt");
      store.commit('me/setMe',null)
    }
  } 
  
  return {
    name: 'LoginPage',
    query: {
      next: to.path
    }
  } 
}

const notLoggedIn = async(to)=>{
  const me = await store.dispatch('me/getMe');
  if(!me){
    return true
  } else {
    return {
      name: 'WinerySelectorPage',
      params: {
        userId: me.id
      },
      query: {
        firstuse: to.query?.firstuse
      }
    }
  }
}

const routes = [
  {
    path: "/",
    redirect: "/splash",
  },
  {
    path: "/ui",
    name: "UIScreen",
    component: UIScreen,
  },
  {
    path: "/splash",
    name: "SplashScreen",
    component: SplashScreen,
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
    beforeEnter: [notLoggedIn]
  },
  {
    path: "/forgotten-password",
    name: "ForgottenPasswordPage",
    component: ForgottenPasswordPage,
    beforeEnter: [notLoggedIn],
  },
  {
    path: "/new-password",
    name: "NewPasswordPage",
    component: NewPasswordPage,
  },
  {
    path: "/terms-privacy",
    name: "TermsPrivacyPage",
    component: TermsPrivacyPage,
    beforeEnter: [canWineryAdminAccess],
  },
  {
    path: "/users/:userId",
    name: "WinerySelectorPage",
    component: WinerySelectorPage,
    beforeEnter: [canWineryAdminAccess,privacyAndTermsNotModified]
  },
  {
    path: "/users/:userId/wineries/:wineryId",
    name: "WineryAdminPage",
    component: DashboardPage,
    beforeEnter: [canWineryAdminAccess,privacyAndTermsNotModified]
  },
  {
    path: "/users/:userId/wineries/:wineryId/dashboard",
    name: "DashboardPage",
    component: DashboardPage,
    beforeEnter: [canWineryAdminAccess,privacyAndTermsNotModified]
  },
  {
    path: "/users/:userId/wineries/:wineryId/events",
    name: "EventsPage",
    component: EventsPage,
    beforeEnter: [canWineryAdminAccess,privacyAndTermsNotModified]
  },
  {
    path: "/users/:userId/wineries/:wineryId/events/:eventId",
    name: "EventPage",
    component: EventPage,
    beforeEnter: [canWineryAdminAccess,privacyAndTermsNotModified]
  },
  {
    path: "/users/:userId/wineries/:wineryId/active-event/:eventId",
    name: "ActiveEventPage",
    component: ActiveEventPage,
    beforeEnter: [canWineryAdminAccess,privacyAndTermsNotModified]
  },
  {
    path: "/users/:userId/wineries/:wineryId/bookings",
    name: "BookingsPage",
    component: BookingsPage,
    beforeEnter: [canWineryAdminAccess,privacyAndTermsNotModified]
  },
  {
    path: "/users/:userId/wineries/:wineryId/bookings/:bookingId",
    name: "BookingDetailsPage",
    component: BookingDetailsPage,
    beforeEnter: [canWineryAdminAccess,replaceLongBookingId,privacyAndTermsNotModified]
  },
  {
    path: '/users/:userId/wineries/:wineryId/complimentary-tickets/:complimentaryTicketId',
    name: "ComplimentaryTicketDetailsPage",
    component: ComplimentaryTicketDetailsPage,
    beforeEnter: [canWineryAdminAccess, replaceLongComplimentaryTicketId, privacyAndTermsNotModified]
  },
  {
    path: "/users/:userId/wineries/:wineryId/reports",
    name: "ReportsPage",
    component: ReportsPage,
    beforeEnter: [canWineryAdminAccess,privacyAndTermsNotModified]
  },
  {
    path: "/users/:userId/wineries/:wineryId/reports/:reportId",
    name: "ReportDetailsPage",
    component: ReportDetailsPage,
    beforeEnter: [canWineryAdminAccess,privacyAndTermsNotModified]
  },
  {
    path: "/wineries/:wineryId/bookings/:bookingId",
    beforeEnter: [addUserToRoute, replaceLongBookingId]
  },
  {
    path: '/wineries/:wineryId/complimentary-tickets/:complimentaryTicketId',
    beforeEnter: [addUserToRoute, replaceLongComplimentaryTicketId]
  },
  {
    path: "/wineries/:wineryId/active-event/:eventId",
    beforeEnter: addUserToRoute
  },
  {
    path: "/wineries/:wineryId/reports/:reportId",
    beforeEnter: addUserToRoute
  },
  {
    path: "/wineries/:wineryId",
    beforeEnter: addUserToRoute
  },
  {
    path: "/register/:code",
    name: "RegisterPage",
    component: RegisterPage,
  },
  {
    path: "/not-found",
    name: "NotFoundPage",
    component: NotFoundPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.onError((error,to,from)=>{
  console.log(error, to, from)
})

export default router;
