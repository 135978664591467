import { useToast } from "vue-toastification";

function useError(res, defaultMessage) {
  const toast = useToast();

  if (
    res.data.error?.details?.errors &&
    !Array.isArray(res.data.error.details.errors)
  )
    return res.data.error.details.errors;
  else if (res.data.error?.details?.message) {
    toast.error(res.data.error.details.message);
    return null;
  } else if (res.data.error?.message) {
    toast.error(defaultMessage || `Hiba történt (${res.data.error.message})`);
    return null;
  } else {
    toast.error("Hiba történt!");
    return null;
  }
}

export default useError;
