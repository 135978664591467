<template>
  <ion-page>
    <ion-content>
      <SidebarNavigation active="BookingsPage">
        <div class="background" v-if="!loadingBooking && !loadingConfig">
          <div class="header-container">
            <div class="flex">
              <button
                v-if="!isMobile()"
                style="margin-right: 16px"
                icon
                variant="white"
                @click="handleBack"
                data-tooltip="Vissza"
              >
                <arrow-back-icon />
              </button>
              <div variant="page-title">{{ booking.attributes.NID }}</div>
              <Chip :variant="booking.attributes.Status" />
            </div>
            <div class="flex">
              <button
                @click="() => (showEditStatusModal = true)"
                v-if="isEditVisible(booking)"
                icon-left
                variant="white"
              >
                <edit-icon />
                Módosít
              </button>
              <button
                @click="() => (showAttendedModal = true)"
                :disabled="!verificationEnabled(booking)"
                variant="success"
                style="margin-right: 8px; margin-left: 8px"
                icon-left
                :data-tooltip="verificationEnabled(booking)|| booking.attributes.Status !== 'successful'?null:'A verifikáció még nem aktív'"
              >
                <check-icon />
                Megjelent
              </button>
              <button
                @click="() => (showNotAttendedModal = true)"
                :disabled="!verificationEnabled(booking)"
                variant="dark"
                icon-left
                :data-tooltip="verificationEnabled(booking) || booking.attributes.Status !== 'successful' ?null:'A verifikáció még nem aktív'"
                data-tooltip-position="left"
              >
                <ban-icon />
                Nem jelent meg
              </button>
            </div>
          </div>
        </div>

        <div :class="`booking-details-container ${booking?.attributes.Package?.data && 'package'}`" v-if="!loadingBooking">
          <!-- BOOKING DETAILS -->
          <div class="column-1">
            <div class="details-item">
              <h3 variant="table-head-button-label">Foglalás adatai</h3>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Foglalás időpontja</h3>
              <p variant="list-item">
                {{ booking?.attributes.BookingDate ? formatDateAndTime(booking?.attributes.BookingDate) : 'Nincs adat' }}
              </p>
            </div>
            <div class="details-item" v-if="booking?.attributes.Package?.data">
              <h3 variant="table-head-button-label">Lefoglalt időpont</h3>
              <p variant="list-item">
                {{
                  formatDates(
                    booking?.attributes.Event.data?.attributes.StartAt,booking?.attributes.Event.data?.attributes.EndAt
                  )
                }}
              </p>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Foglaló neve</h3>
              <p variant="list-item">
                {{ booking?.attributes.WineAdventurer?.data?.attributes.LastName}} {{ booking?.attributes.WineAdventurer?.data?.attributes.FirstName}}
              </p>
            </div>
            <div class="details-item comment" v-if="booking?.attributes.PackageComment">
              <h3 variant="table-head-button-label">Megjegyzés</h3>
              <p variant="list-item" style="margin: 0px; padding: 0px;">
                {{
                  booking?.attributes.PackageComment
                }}
              </p>
            </div>
            <div class="details-item" v-if="!booking?.attributes.Package?.data">
              <h3 variant="table-head-button-label">Résztvevők</h3>
              <p variant="list-item">
                {{ booking?.attributes.Participants }}
              </p>
            </div>
            <div class="detauls-item" v-else>
              <h3 variant="table-head-button-label">Foglalás részletei:</h3>
              <table>
                <tr>
                  <td variant="list-item">Résztvevők</td>
                  <td variant="list-item" class="row-value">{{booking?.attributes.Participants}} fő</td>
                  <!-- <td variant="list-item" class="row-value">{{booking?.attributes.PurchasePrice*booking?.attributes.Participants}} Ft</td> -->
                </tr>
                <tr v-for="extra in booking?.attributes.PackageExtras.data" :key="extra.id">
                  <td variant="list-item">{{extra.attributes.Name}}</td>
                  <td variant="list-item" class="row-value">{{extra.attributes.Quantity}} db</td>
                  <!-- <td variant="list-item" class="row-value">{{extra.attributes.Quantity*extra.attributes.PurchasePrice}} Ft</td> -->
                </tr>
                <!-- <tr v-if="hasDiscount()">
                  <td variant="list-item">Kedvezmény</td>
                  <td variant="list-item" class="row-value" colspan="2">-{{booking?.attributes.DiscountTotal}} Ft</td>
                </tr>
                <tr>
                  <td style="padding-top: 12px;" variant="navigation-bold">Összesen</td>
                  <td style="padding-top: 12px;" variant="navigation-bold" class="row-value" colspan="2">{{booking?.attributes.PayedTotal}} Ft</td>
                </tr> -->
              </table>

            </div>
          </div>

          <!-- EVENT DETAILS -->
          <div class="column-2" v-if="!booking?.attributes.Package?.data">
            <div class="details-item">
              <h3 variant="table-head-button-label">Esemény ID</h3>
              <p variant="list-item">
                {{ booking?.attributes.Event.data.attributes.NID }}
              </p>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Esemény neve</h3>
              <p variant="list-item">
                {{ booking?.attributes.Event.data.attributes.Name }}
              </p>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Esemény időpontja</h3>
              <p variant="list-item">
                {{
                  formatDateAndTime(
                    booking?.attributes.Event.data?.attributes.StartAt
                  )
                }}-{{
                  formatDateAndTime(
                    booking?.attributes.Event.data?.attributes.EndAt
                  )
                }}
              </p>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Helyszín</h3>
              <p variant="list-item">
                {{ booking?.attributes.Event.data.attributes.LocationName }}
                <span style="color: var(--color-grey-03); margin-left: 8px">{{
                  booking?.attributes.Event.data.attributes.LocationAddress
                }}</span>
              </p>
            </div>
            <button
              @click="handleGoToEvent(booking)"
              icon-right
              variant="white"
              v-if="!isMobile()"
            >
              Ugrás az eseményhez <link-icon />
            </button>
          </div>
          <!-- PACKAGE DETAILS -->
          <div class="column-2" v-if="booking?.attributes.Package?.data">
            <div class="details-item">
              <h3 variant="table-head-button-label">Csomag adatai</h3>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Csomag neve</h3>
              <p variant="list-item">
                {{ booking?.attributes.Package.data.attributes.Name }}
              </p>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">A kóstolót vezeti</h3>
              <p variant="list-item">
                {{ booking?.attributes.PackageProgramGuide || booking?.attributes.Package.data.attributes.ProgramGuide }}
              </p>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Időtartam</h3>
              <p variant="list-item">
                {{booking?.attributes.Package.data.attributes.Duration}} óra
              </p>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Helyszín</h3>
              <p variant="list-item">
                {{ booking?.attributes.Package.data.attributes.LocationName }}
                <span style="color: var(--color-grey-03); margin-left: 8px">{{
                  booking?.attributes.Package.data.attributes.LocationAddress
                }}</span>
              </p>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Árak</h3>
              <table :key="type.value" v-for="type in [
                  {value: 'NetPriceProgramMin',label: 'Program', vat: config.attributes.VATProgram},
                  {value: 'NetPriceFoodMin',label: 'Ételfogyasztás', vat: config.attributes.VATFood},
                  {value: 'NetPriceAccommodationMin',label: 'Szálláshely', vat: config.attributes.VATAccommodation}].filter(type=>booking?.attributes.Package?.data.attributes[type.value])">
                  <tr>
                    <td colspan="2" variant="list-item" style="font-style: italic">{{type.label}}</td>
                  </tr>
                  <tr>
                    <td style="padding-left: 16px" variant="list-item">
                      Nettó alapár/fő:
                    </td>
                    <td variant="list-item" class="row-value">
                      {{ booking?.attributes.Package?.data.attributes[type.value] }} Ft + {{ type.vat}}% ÁFA
                    </td>
                  </tr>
                  
              </table>
              <hr>
              <table>
                <tr>
                  <td variant="list-item">
                    Bruttó alapár/fő:
                  </td>
                  <td variant="list-item" class="row-value">
                    {{ booking?.attributes.Package.data.attributes.BestDealPrice }} Ft/fő-től
                  </td>
                </tr>
              </table>
            </div>
            <div class="details-item" v-if="booking?.attributes.Package.data.attributes.Extras.length > 0">
              <h3 variant="table-head-button-label">Elérhető extrák</h3>
              <table>
                <tr v-for="extra in booking?.attributes.Package.data.attributes.Extras" :key="extra.id">
                  <td variant="list-item">
                    {{extra.Name}}
                  </td>
                  <td variant="list-item" class="row-value">
                    {{ Math.round(extra.DisplayedPrice / (100 + getVAT(extra.PriceType)) *100) }} Ft{{ extra.Type === "per-participant" ? '/fő' : ''}} + {{ getVAT(extra.PriceType)}}% ÁFA
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="column-3" v-if="booking?.attributes.Package?.data">
            <div class="details-item">
              <h3 variant="table-head-button-label">Információk a programról</h3>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">A kóstoló borai</h3>
              <p variant="list-item" class="wine" v-for="(wine, idx) in booking?.attributes.Package.data.attributes.WineList" :key="idx">
                {{ wine.value }}
              </p>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Program indulási pontja</h3>
              <p variant="list-item">
                {{ booking?.attributes.Package.data.attributes.StartLocation }}
              </p>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Program végpontja</h3>
              <p variant="list-item">
                {{ booking?.attributes.Package.data.attributes.EndLocation }}
              </p>
            </div>
            <div class="details-item">
              <h3 variant="table-head-button-label">Program nyelve</h3>
              <p variant="list-item">
                {{ language(booking?.attributes.Package.data.attributes.Language) }}
              </p>
            </div>
          </div>
        </div>
      </SidebarNavigation>

      <!-- MODALS -->
      <div v-if="!loadingBooking">
        <AttendedModal
          :visible="showAttendedModal"
          :handleStatusUpdate="handleUpdateModal"
          :handleClose="() => (showAttendedModal = false)"
          :booking="booking"
        />
        <NotAttendedModal
          :visible="showNotAttendedModal"
          :handleStatusUpdate="handleUpdateModal"
          :handleClose="() => (showNotAttendedModal = false)"
          :booking="booking"
        />
        <EditStatusModal
          :visible="showEditStatusModal"
          :handleStatusUpdate="handleUpdateModal"
          :handleClose="() => (showEditStatusModal = false)"
          :booking="booking"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import SidebarNavigation from "@/components/SidebarNavigation";
import { useRoute, useRouter } from "vue-router";
import {
  ArrowBackIcon,
  CheckIcon,
  BanIcon,
  LinkIcon,
  EditIcon,
} from "../../assets/icons";
import useBooking from "../../hooks/useBooking";
import useFormatDate from "../../hooks/useFormatDate";
import useScreen from "../../hooks/useScreen";
import Chip from "../../components/ChipComponent.vue";
import dayjs from "dayjs";
import AttendedModal from "./AttendedModal.vue";
import NotAttendedModal from "./NotAttendedModal.vue";
import EditStatusModal from "./EditStatusModal.vue";
import { ref } from "@vue/reactivity";
import useConfig from '../../hooks/useConfig';
import usePackageLanguage from '../../hooks/usePackageLanguage';

export default {
  name: "BookingDetailsPage",
  components: {
    ArrowBackIcon,
    IonPage,
    Chip,
    IonContent,
    SidebarNavigation,
    LinkIcon,
    BanIcon,
    CheckIcon,
    EditIcon,
    AttendedModal,
    NotAttendedModal,
    EditStatusModal,
  },
  setup() {
    const router = useRouter();
    const { params } = useRoute();
    const bookingId = params.bookingId;
    const { formatDateAndTime, formatDates } = useFormatDate();
    const { language } = usePackageLanguage();

    const {isMobile} = useScreen();

    const {
      booking,
      loading: loadingBooking,
      update: updateBooking,
      getBooking,
    } = useBooking(bookingId);

    const {config, loading: loadingConfig} = useConfig()

    const isEditVisible = (booking) => {
      const eventStartAt = dayjs(booking.attributes.Event.data?.attributes.StartAt);
      const eventEndAt = dayjs(booking.attributes.Event.data?.attributes.EndAt);
      const now = dayjs();
      if (
        now < eventEndAt.add(24,"hours") && now > eventStartAt.add(-1, "hours") &&
        booking.attributes.Status !== "successful" &&
        booking.attributes.Status !== "deleted" && 
        booking.attributes.Status !== "cancelled"
      ) {
        return true;
      } else {
        return false;
      }
    };

    const verificationEnabled = (booking) => {
      if(!booking) return;
      const eventStartAt = dayjs(booking.attributes.Event.data?.attributes.StartAt);
      const eventEndAt = dayjs(booking.attributes.Event.data?.attributes.EndAt);
      const now = dayjs();

      if(booking.attributes.Status === "successful" && now < eventEndAt.add(24,"hours") && now > eventStartAt.add(-1, "hours")){
        return true;
      } else {
        return false;
      }
    }

    // MODAL
    const showAttendedModal = ref(false);
    const showNotAttendedModal = ref(false);
    const showEditStatusModal = ref(false);

    // HANDLERS
    const handleBack = () => router.back();
    const handleGoToEvent = (booking) =>
      router.push({
        name: "ActiveEventPage",
        params: { ...params, eventId: booking.attributes.Event.data.id },
      });
    const handleUpdateModal = async (bookingId, status) => {
      const res = await updateBooking({ data: { Status: status } });
      if (res.ok) {
        showAttendedModal.value = false;
        showNotAttendedModal.value = false;
        showEditStatusModal.value = false;
        await getBooking();
      }
    };

    return {
      handleBack,
      handleGoToEvent,
      booking,
      isEditVisible,
      loadingBooking,
      updateBooking,
      formatDateAndTime,
      formatDates,
      handleUpdateModal,
      showAttendedModal,
      showNotAttendedModal,
      showEditStatusModal,
      verificationEnabled,
      isMobile,
      loadingConfig,
      config,
      language
    };
  },
  methods: {
    hasDiscount(){
      return this.booking?.attributes.DiscountType === 'code';
    },
    getVAT(type){
      switch(type){
        case 'program':
          return this.config.attributes.VATProgram
        case 'food':
          return this.config.attributes.VATFood
        case 'accommodation':
          return this.config.attributes.VATAccommodation
      }
    },
  }
};
</script>

<style scoped>
.header-container {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.background {
  background-color: var(--color-white);
}
.booking-details-container {
  display: grid;
  margin: 8px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 0px;
}

.booking-details-container {
  grid-template-columns: repeat(3, 1fr);
}

.booking-details-container > div {
  background-color: var(--color-white);
  padding: 32px 24px;
}
.details-item {
  margin-bottom: 24px;
}

.wine {
  margin-left:8px; 
}

.comment {
  padding: 12px 8px 12px 8px;
  border-radius: 8px;
  border: 1px solid #FED0B7;
  background: var(--color-red-07);
}

td.row-value {
  text-align: right;
}

p,
h3 {
  margin: 0;
  margin-bottom: 8px;
}

@media only screen and (max-width: 768px) {
  .header-container {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .header-container div {
    margin-bottom: 12px;
  }

}
</style>